import React from 'react'
import { useNavigate } from 'react-router-dom';
import * as Constants from '../../constants/stringConstants';
import { ReactComponent as FolderIcon } from '../../assets/images/Folder.svg'


export const BackBtn = () => {
    const navigate = useNavigate();
  return (
    <button className='backBtn' onClick={() => navigate(-1)}>{Constants.backBtnText}</button>
  )
}


export const ExportBtn = () => {
  return (
    <button className='exportBtn'> {Constants.exportBtnText} <FolderIcon /> </button>
  )
}

