import "./logout.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import * as Constants from "../../constants/stringConstants";
import { logOutUser } from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";

const Logout = () => {

  
  /* The code `const [sessionKey, setSessionKey] = useState("")` is declaring a state variable
`sessionKey` and a function `setSessionKey` to update its value. The initial value of `sessionKey`
is an empty string. */

  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [modalShow, setModalShow] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);
    };
    checkAuth();
  }, []);

  /**
   * closeLogoutModal :: Hide Logout Overlay
   */
  const closeLogoutModal = () => {
    setModalShow(false);
  };

  /**
   * The function `logOutUserDetails` logs out the user by removing session key, user ID, and branch
   * code from local storage, setting isAuthenticated to false, displaying a success notification,
   * navigating to the home page, and reloading the window.
   */

  const logOutUserDetails = () => {
    logOutUser(sessionKey, userId, branchCode)
      .then((response) => {
        if (response) {
          localStorage.removeItem("session_key");
          localStorage.removeItem("user_Id");
          localStorage.removeItem("branch_code");
          localStorage.setItem("isAuthenticated", false);

          NotificationManager.success(
            response?.data && response?.data?.KCSRPT?.details[0].ErrorMsg
          );
          navigate("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={modalShow}
      className="LogoutModal"
    >
      <Modal.Body className="logout-modal">
        <p>{Constants.logoutText}</p>
        <button className="logoutBtn" onClick={logOutUserDetails}>
          {Constants.logoutBtnTxt}
        </button>
        <Link to="/dashboard">
          <button onClick={closeLogoutModal} className="cancelBtn">
            {Constants.cancelBtnText}
          </button>
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default Logout;
