import "./modal.css";
import React, { useState, useEffect, useRef } from "react";
import * as Constants from "../../../constants/stringConstants";
import {
    Modal,
    FloatingLabel,
    Form
} from "react-bootstrap";
import { ReactComponent as ScanIcon } from "../../../assets/images/Scan.svg";
import { ReactComponent as DateIcon } from "../../../assets/images/date.svg";
import { getDashboardExceptionCount,verifyOnData, verifyonValueValidate } from "../../../Api";
import { NotificationManager } from "react-notifications";
import Calendar from "react-calendar";
import { formatMonthDate } from "../../../utils";


const VerifyOverlay = (props) => {
    const [calendar, setCalendar] = useState(false);
    const [getSelectedDate, setGetSelectedDate] = useState("")
    const [sessionKey, setSessionKey] = useState("");
    const [userId, setUserId] = useState("");
    const [reportDate, setReporteDate] = useState("");
    const [verify, setVerify] = useState("");
    const [visible, setVisible] = useState(false);
    const [verifyvalue, setVerifyvalue] = useState("");
    const [tab, setTab] = useState("");
    const [reason, setReason] = useState("")
    const [date] = useState()
    const [isDateIconClickable, setIsDateIconClickable] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let userDate = await localStorage.getItem("date_key");
            let getdate = await localStorage.getItem("reporting_date");

            if (userDate) {
                setReporteDate(userDate);
            } else {
                setReporteDate(getdate);
            }
        };
        checkAuth();
    }, [tab]);

    /**
     * showCalendar :: View Calendar
     */
    const showCalendar = () => {
        setCalendar(!calendar);
    };

    const onDateChange = (date) => {
        setGetSelectedDate(formatMonthDate(date))
        setVerifyvalue(formatMonthDate(date))
        setCalendar(false)
      };

    const enterDate = (e) => {
        setGetSelectedDate(e.target.value)
        setVerifyvalue(e.target.value)
        setCalendar(false)
    }



    /**
     * onChangeDate :: Function for change date and maintain its state
     * @param {*} date
     */
    const onChangeDate = (e) => {
        setReason(e.target.value);
    };


    const getSelectedVerifyon = (index) => {
        const selectedVerifyOn = props?.verifyOn?.details[index].verifyon;
        setVerify(props?.verifyOn?.details[index].verifyon);
        console.log(verify)
        setVisible(true);
        setIsDateIconClickable(selectedVerifyOn === "DATE");
    };

    const getVerifyvalue = (event) => {
        setVerifyvalue(event.target.value);
    };


    const modalRef = useRef(null);

    const handleCloseModal = () => {
        if (modalRef.current) {
            modalRef.current.style.display = "none";
            setVerify("")
            setVerifyvalue("")
        }

    };

    const closeModal = () => {
        if (modalRef.current) {
            modalRef.current.style.display = "none";
        }
    }


    const handleKeyDown = (event) => {
        if (event.key === "Tab" && verifyvalue.trim() !== "") {
            event.preventDefault(); // Prevent the default tab behavior

            if (modalRef.current) {
                modalRef.current.style.display = "block";

                verifyonValueValidate(
                    "Verify On",
                    sessionKey,
                    userId,
                    reportDate,
                    reportDate,
                    props?.type,
                    props?.getImptype,
                    verify,
                    verifyvalue
                ).then((response) => {
                    if (response) {
                        setTab(response?.data?.KCSRPT?.details[0]?.ErrorMsg
                        )

                    }
                });
            }
        }
    };


    const updateException = (onhide) => {
        const formattedValues = props?.getvalues.reverse().join("|");
        console.log(formattedValues)
        verifyOnData(
            "Exception Update",
            sessionKey,
            userId,
            reportDate,
            reportDate,
            props?.type,
            props?.getImptype,
            "",
            reason,
            formattedValues,
            verify,
            verifyvalue,
            "Yes"
        ).then(response => {
            if (response) {
                NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg)
                props.onHide();
                getDashboardExceptionCount(
                    "Exception Show",
                    sessionKey,
                    userId,
                    reportDate,
                    reportDate,
                    props?.type,
                    props?.getImptype,
                    "",
                    ""
                    // props.getignore === "true" ? "ignore" : ""
                ).then(response => {
                    if (response) {
                        props.ignoredException(response.data.KCSRPT)
                    }
                })
            }
        })
    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="ignoreException-overlay"
        >
            <Modal.Body>
                <h2 style={{ textAlign: "left" }}>{Constants.getVerifyText}</h2>
                <p className="pb-2">{Constants.reasonBtnText}</p>

                <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div className="input-group ">
                        <button
                            className="btn btn-outline-secondary dropdown-toggle "
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {verify || "VerifyOn"}
                        </button>
                        <ul className="dropdown-menu ">
                            {props?.verifyOn?.details &&
                                props?.verifyOn?.details?.map((ele, index) => {
                                    return (
                                        <li
                                            key={ele[index]}
                                            onClick={() => getSelectedVerifyon(index)}
                                            className=" btn btn-secondary"
                                        >
                                            {ele.verifyon}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>

                <div className="options-row pb-2 position-relative ">
                    {
                        isDateIconClickable ? <DateIcon onClick={showCalendar} /> : <DateIcon />
                    }
                    {/* <DateIcon onClick={showCalendar} /> */}
                    
                    {/* <h3 className="ps-2">Date </h3> */}
                    {visible ? (
                        isDateIconClickable ?
                            <input placeholder="DD/MM/YYYY" onChange={enterDate} value={getSelectedDate}/>
                        :
                            <input onKeyDown={handleKeyDown} onChange={getVerifyvalue} />
                    ) : (
                        ""
                    )}
                    {
                        calendar && (
                            <div className="reporting-calendar mt-5">
                                <Calendar onChange={onDateChange} value={date} />
                            </div>
                        )
                    }
                </div>

                <div ref={modalRef} className="modal">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalToggleLabel">verify</h5>

                            </div>
                            <div class="modal-body">
                                {tab}
                            </div>
                            <div class="btn-row verify-btn d-flex">
                                <button className="cancelBtn verify-cancel modalBtn" onClick={handleCloseModal}>
                                    NO
                                </button>
                                <button className="ignoreBtn verify-cancel modalBtn" onClick={closeModal}>
                                    YES
                                </button>
                                {/* <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">Open second modal</button> */}
                            </div>
                        </div>
                    </div>

                </div>
                <FloatingLabel
                    controlId="floatingTextarea1"
                    label="Type your reason here..."
                >
                    <Form.Control
                        onChange={onChangeDate}
                        as="textarea"
                        placeholder="Type your reason here..."
                        style={{ height: "125px", marginBottom: 0 }}
                    />
                </FloatingLabel>

                <div className="btn-row">
                    <button className="cancelBtn modalBtn" onClick={props.onHide}>
                        {Constants.cancelBtnText}
                    </button>
                    <button className="ignoreBtn modalBtn" onClick={() => updateException(props.onHide)}>
                        {Constants.updateButtonText}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default VerifyOverlay;
