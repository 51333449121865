import React from 'react'
import * as Constants from '../../constants/stringConstants'


const UserDetailsFooter = () => {
  return (
    <div className="content-footer" style={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "5%"
      }}>
        <p>{Constants.userText} <span style={{ fontWeight: "bold" }}>{localStorage.getItem("user_Id")}</span></p>
        <p>{Constants.branchCode} <span style={{ fontWeight: "bold" }}>{localStorage.getItem("branch_code")}</span></p>
        <p>{Constants.branchName} <span style={{ fontWeight: "bold" }}>{localStorage.getItem("branch_name")}</span></p>
      </div>
  )
}

export default UserDetailsFooter