import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as FolderIcon } from "../../assets/images/Folder.svg";
import { getSebiLevel1params } from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const SebiReportDetails = () => {
    const navigate = useNavigate();
    const [, setSessionKey] = useState("");
    const [, setUserId] = useState("");
    const [, setBranchCode] = useState("");
    const [getSebiReports, setGetSebiReports] = useState([]);
    const [heading, setHeading] = useState();
    const [reportDate, setReporteDate] = useState("");
    const [getCount, setGetCount] = useState("");
    const location = useLocation();
    const [get_date, setGet_date] = useState("");
    const [loader, setLoader] = useState(true)
    const selectedReportData =
        location?.state && location.state ? location.state : "";


    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getBranch = await localStorage.getItem("branch_code");
            setBranchCode(getBranch);

            let userDate = await localStorage.getItem("date_key");
            let getdate = await localStorage.getItem("reporting_date");


            if (userDate) {
                setReporteDate(userDate);
                await getLevel1paramaters(getSessionId, getUserId, userDate);
            } else {
                setReporteDate(getdate);
                await getLevel1paramaters(getSessionId, getUserId, getdate);
            }

        };
        checkAuth();
    }, [get_date]);

    // Get Total Count

    useEffect(() => {
        let count =
            (getSebiReports?.details &&
                getSebiReports?.details?.map((ele) => Number(ele.TotalAmt))) ||
            [];
        // This will create an array with the TotalAmt property of each object converted to a number

        let Total_Amount = count.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        setGetCount(Total_Amount);
    }, [getSebiReports]);

    /**
     * The function `getLevel1paramaters` retrieves level 1 parameters using a session key, user ID, and
     * selected report data, and then sets the retrieved reports.
     * @param sessionKey - The sessionKey parameter is a unique identifier that is used to authenticate and
     * authorize a user's session. It is typically generated when a user logs in or authenticates with a
     * system or application.
     * @param userId - The `userId` parameter is the unique identifier of the user for whom the level 1
     * parameters are being fetched.
     */

    const getLevel1paramaters = (sessionKey, userId, reportDate) => {
        if (selectedReportData) {
            setLoader(true)
            getSebiLevel1params(
                "SEBIrptlevel1",
                sessionKey,
                userId,
                reportDate,
                reportDate,
                "SEBI",
                "SEBI MAIN REPORT",
                selectedReportData["Nature of Transaction"]
                    ? selectedReportData["Nature of Transaction"]
                    : "",
                selectedReportData["Transaction Label"]
                    ? selectedReportData["Transaction Label"]
                    : "",
                selectedReportData["Related Party Name"]
                    ? selectedReportData["Related Party Name"]
                    : "",
                selectedReportData.Total
            )
                .then((res) => {
                    if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
                        setLoader(false)
                        NotificationManager.error(res?.data?.KCSRPT?.details[0].ErrorMsg);
                    } else if (res?.data?.KCSRPT?.details[0].REM) {
                        setLoader(false)
                        NotificationManager.error(res?.data?.KCSRPT?.details[0].REM);
                    } else {
                        setLoader(false)
                        setGetSebiReports(res.data && res?.data?.KCSRPT);
                        let tableHeading = Object.keys(res?.data?.KCSRPT?.details[0]);
                        setHeading(tableHeading);
                    }
                })
                .catch((err) => {
                    handleError(err);
                });
        }
    };

    /**
     * The function `navigateToReportDetails` is used to navigate to a report details page based on the
     * clicked row index and column name, and it also handles different conditions for navigation.
     * @param rowIndex - The rowIndex parameter represents the index of the row in the SEBI reports array
     * that was clicked.
     * @param columnName - The `columnName` parameter represents the name of the column in the report.
     */

    const navigateToReportDetails = (rowIndex, columnName) => {
        const clickedRow = getSebiReports.details[rowIndex];
        const clickedValue = clickedRow[columnName];
        if (
            columnName === "Variance" || clickedRow[columnName] === ""
        ) {
            // No action needed for these conditions
        } else if (clickedValue === "0.00000") {
            // No action needed for these conditions
        } else if (!isNaN(+clickedValue) === true) {
            navigate("/dashboard/SEBI/level2-report", {
                state: {
                    reportingHead: clickedRow,
                    reportType: columnName,
                    reportingAmt: clickedValue,
                },
            });
        }
    };

    return (
        <MainLayout>
            <div className="layoutWrapper">
                <div className="header-wrapper">
                    <h1 className="header"> {Constants.sebilevelreport} </h1>
                    <Header setGet_date={setGet_date} get_date={get_date} />
                </div>
                <div className="content-body">
                    <div className="content-header">
                        <div className="content-header-left">
                            <h1>
                                {Constants.reportingDateTime}
                                {reportDate}
                            </h1>
                        </div>
                        <div className="content-header-right ">
                            <h3 className="total-amt">
                                {Constants.totalAmt}
                                {getCount.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </h3>
                            <GernerateCSVFile
                                generateCSV={getSebiReports}
                                reportName={"SEBILevel1Report"}
                            />
                            <button className="back-btn" onClick={() => navigate(-1)}>
                                {Constants.backBtnText}
                            </button>
                        </div>
                    </div>
                    {
                        loader === false ?
                            <div className="content-table main-report-table">
                                <ResolvedApi
                                    get_response={getSebiReports}
                                    heading={heading}
                                    navigateToReportDetails={navigateToReportDetails}
                                />
                            </div>
                            : <div className="spinner-loader">
                                <div class="spinner-border d-flex m-auto" role="status"></div>
                            </div>
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default SebiReportDetails;
