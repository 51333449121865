import React from "react";
import { formatDateToDDMMMYYYY } from "../../utils";

const ResolvedApi = ({ get_response, heading, navigateToReportDetails }) => {

  return (
    <div className="table-container">
      <table className="table">
        <thead style={{ position: "sticky", top: 0, background: "white", zIndex: 1 }}>
          <tr>
            {heading
              ? heading.map((title) => {
                // Determine if the column contains numeric values
                {/* const isNumericColumn = get_response?.details?.every(
                  (row) => !isNaN(row[title])
                );
                const cellClassNames = [
                  isNumericColumn ? "right-align-heading" : "",
                  location.pathname === "/dashboard/AS/main-report" ? "main_report" : "heading",
                ].join(" "); */}

                return (
                  <th key={title} className="heading_font">
                    {title}
                  </th>
                );
              })
              : null}
          </tr>
        </thead>
        <tbody>
          {get_response?.details &&
            get_response.details.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.keys(row).map((columnName) => {
                  const value = row[columnName];
                  let formattedValue = value; // Initialize formattedValue with the original value

                  // Check if the value is a valid date and not a number
                  const dateValue = new Date(value);
                  if (!isNaN(dateValue.getDate()) && isNaN(Number(value))) {
                    // Format the date as you desire
                    const options = {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    };

                    formattedValue = formatDateToDDMMMYYYY(dateValue.toLocaleDateString("en-US", options));
                  } else if (
                    columnName === "Financial Year" ||
                    columnName === "FinYear" ||
                    columnName === "Financial Year" ||
                    columnName === "Imported On"
                  ) {
                    // If not a date and matches specific column names, format as needed
                    formattedValue = value || "-";
                  } else {
                    // Handle other columns, e.g., numeric formatting
                    const numericValue = parseFloat(value);
                    if (!isNaN(numericValue)) {
                      formattedValue = numericValue.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    }
                  }

                  // Conditionally apply a class for left alignment if it's a date
                  const isDate = !isNaN(dateValue.getDate()) && isNaN(Number(value));
                  const tdClassName = ` ${isDate ? "left_align" : ""}`;

                  return (
                    <td
                      className={`${!isNaN(row[columnName]) ? "report_alignment main_report" : "dashed"
                        }${tdClassName}`}
                      key={columnName}
                      // onClick={
                      //   navigateToReportDetails
                      //     ? () => navigateToReportDetails(rowIndex, columnName)
                      //     : null
                      // }
                    >
                      <b onClick={
                        navigateToReportDetails
                          ? () => navigateToReportDetails(rowIndex, columnName)
                          : null
                      }>{formattedValue ? formattedValue : "-"}</b>
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>



      </table>
    </div>

  );
};

export default ResolvedApi;
