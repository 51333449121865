import "./dashboard.css";
import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import CorrectIcon from "../../assets/images/correct.png";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/More.svg";
import { ReactComponent as SortIcon } from "../../assets/images/sort.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/Search.svg";
import { ReactComponent as Filtercon } from "../../assets/images/filter.svg";
import { ReactComponent as DropDownIcon } from "../../assets/images/dropdown.svg";
import { ReactComponent as TypeIcon } from "../../assets/images/Category-fill.svg";
import { ReactComponent as ProcessIcon } from "../../assets/images/Star.svg";
import { ReactComponent as StandardIcon } from "../../assets/images/Bookmark-fill.svg";
import ActionOverlay from "../../components/Overlay/ServiceActionModal/ActionOverlay";
import CountInformation from "../../components/Overlay/CountInformation/CountInformation";
import { BackBtn } from "../../components/Buttons/CustomButton";
import {
  getAuthorizedUploadFiles,
  getDashboard,
  getDashboardDate,
  getDashboardExceptionCount,
  getDashboardTotalClick,
  getInfoDisplay,
  getUploadFiles,
} from "../../Api";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import { handleError } from "../../Services/ErrorService";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const Dashboard = () => {
  const [countModalShow, setCountModalShow] = useState(false);
  const [actionOverlay, setActionOverlay] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [showsorting, setShowSorting] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [showfilter, setShowFilter] = useState(false);
  const [serviceMenu, setServiceMenu] = useState(false);
  const navigate = useNavigate();
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [dashboard, setDashboard] = useState([]);
  const [heading, setHeading] = useState();
  const [reportDate, setReporteDate] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [financialYear, setFinancialYear] = useState("");
  const [, setGetprocessName] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [, setLogFiles] = useState("");
  const [logPath, setLogPath] = useState("");
  const [process_name, setProcess_name] = useState("");
  const [infoDetails, setInfoDetails] = useState([]);
  const [, setExceptionCount] = useState("");
  const [, setGetAction] = useState("");
  const [get_date, setGet_date] = useState(null);
  const [loader, setLoader] = useState(true);

  // const getselectedYear =
  //   location?.state &&
  //   JSON.parse(location?.state).KCSRPT

  /**
   * Info - Service Details Count Modal
   * The function `handleCountModal` is used to handle the opening of a count modal and retrieve
   * information to display in the modal.
   * @param rowIndex - The rowIndex parameter represents the index of the row in the dashboard.details
   * array that is being clicked or selected.
   */

  const handleCountModal = (rowIndex) => {
    setInfoDetails("");
    setHeading("");
    setCountModalShow(true);
    setProcess_name(dashboard.details[rowIndex]);
    getInfoDisplay(
      "infodisplay",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      dashboard.details[rowIndex].IMPType,
      ""
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
        NotificationManager.error(response?.data?.KCSRPT?.details[0]?.ErrorMsg);
        setInfoDetails("");
        setHeading("");
      } else {
        setInfoDetails(response?.data && response?.data?.KCSRPT);
        let tableHeading = response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : [];
        setHeading(tableHeading);
      }
    });
  };

  /**
   * navigateToTotalCount :: Redirect to total count page
   */
  const navigateToTotalCount = (count, index) => {
    if (count === "0") {
      NotificationManager.error("No records available in Total Count")
    } else {
      getDashboardTotalClick(
        "Pending Data",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        "MASTER",
        dashboard?.details[index]?.IMPType,
        ""
      ).then((response) => {
        if (response) {
          navigate("/dashboard/total-count", {
            state: {
              response: response?.data,
              columnname: dashboard?.details[index]?.ImpTypeDesc,
              Imptype: dashboard?.details[index]?.IMPType,
              type: "MASTER",
            },
          });
        }
      });
    }
  };

  /**
   * navigateToExceptionCount :: Redirect to exception count page
   */
  const navigateToExceptionCount = (count, index) => {
    if (count === "0") {
      NotificationManager.error("No records available in exception")
    } else {
      getDashboardExceptionCount(
        "Exception Show",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        "MASTER",
        dashboard?.details[index]?.IMPType,
        "",
        ""
      ).then((response) => {
        if (response) {
          navigate("/dashboard/exception-count", {
            state: {
              response: response?.data,
              columnname: dashboard?.details[index]?.ImpTypeDesc,
              Imptype: dashboard?.details[index]?.IMPType,
              type: "MASTER",
            },
          });
        }
      });
    }
  };

  /**
   * handleViewLog :: Redirect to View log page
   */
  const handleViewLog = (index) => {
    navigate("/dashboard/viewlog", {
      state: {
        logFiles: dashboard,
        getprocessName: index,
        getPath: logPath,
      },
    });
  };

  /**
   * showActionOverlay ::  Show Action overlay popup
   * @param {*} id
   */
  const showActionOverlay = (id) => {
    setActionOverlay(true);
    setActionId(id);
  };

  /**
   * hideActionOverlay :: Hide Acction Overlay popup
   */
  const hideActionOverlay = () => {
    setActionOverlay(false);
    setActionId(null);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        actionOverlay &&
        actionId !== null &&
        !e.target.closest('.view.position-relative')
      ) {
        hideActionOverlay();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [actionOverlay, actionId]);

  /**
   * navigateToProcess :: Redirect to Process page
   */
  const navigateToProcess = () => {
    navigate("/dashboard/process");
  };

  /**
   * navigateToStandard :: Redirect to Standard Page
   */
  const navigateToStandard = () => {
    navigate("/dashboard/standard");
  };

  /**
   * toggleServiceMenu :: Toggle Service Menu
   */
  const toggleServiceMenu = () => {
    setServiceMenu(!serviceMenu);
  };

  /**
   * After 5 seconds hide action popup
   */
  // setTimeout(hideActionOverlay, 5000);

  const popupRef = useRef(null);
  const filterRef = useRef(null);

  const options = [
    { label: "Authorised Successfully", value: "success" },
    { label: "Import Failed with Error", value: "failed" },
    { label: "Import Sucess, Exp Pending", value: "Exp_Pending" },
    { label: "Import Sucess, Aut Pending", value: "Auth_Pending" },
  ];

  const checkboxes = options.map((option) => (
    <Form.Check
      key={option.value}
      type="checkbox"
      label={option.label}
      value={option.value}
      checked={checkboxValues.includes(option.value)}
      onChange={(e) => {
        const isChecked = e.target.checked;
        const value = e.target.value;
        setCheckboxValues((prevValues) =>
          isChecked
            ? [...prevValues, value]
            : prevValues.filter((v) => v !== value)
        );
      }}
    />
  ));

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowSorting(false);
      } else if (
        filterRef.current &&
        !filterRef.current.contains(event.target)
      ) {
        setShowFilter(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let getFinancialYear = localStorage.getItem("Financial_year");
      setFinancialYear(getFinancialYear);

      if (getSessionId) {
        const response = await getDashboardDate(
          "filetyperptdate",
          getSessionId,
          getUserId,
          "17/04/2023",
          ""
        );
        localStorage.setItem(
          "reporting_date",
          response?.data?.KCSRPT?.details[0]?.dtRpt
        );
      }

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        await getDashboardparamaters(
          getSessionId,
          getUserId,
          userDate,
          getFinancialYear
        );
      } else if (getdate) {
        setReporteDate(getdate);
        await getDashboardparamaters(
          getSessionId,
          getUserId,
          getdate,
          getFinancialYear
        );
      }
    };
    checkAuth();
  }, [get_date]);

  /**
   * Dashboard - Render Master Dashboard with IMPTYPE = ""
   * The function `getDashboardparamaters` retrieves dashboard parameters and sets the dashboard data,
   * table heading, and process name based on the response.
   * @param sessionKey - The sessionKey is a unique identifier that is used to authenticate and authorize
   * a user's session in the system. It is typically generated when a user logs in and is used to
   * validate subsequent requests made by the user.
   * @param userId - The `userId` parameter is used to identify the user for whom the dashboard data is
   * being retrieved.
   * @param reportDate - The reportDate parameter is the date for which the dashboard data is requested.
   * @param financialYear - The financial year parameter is used to specify the financial year for which
   * the dashboard data is requested.
   */

  const getDashboardparamaters = (
    sessionKey,
    userId,
    reportDate,
    financialYear
  ) => {
    setLoader(true);
    getDashboard(
      "processlist",
      sessionKey,
      userId,
      reportDate,
      financialYear,
      reportDate,
      "Master",
      ""
    )
      .then((res) => {
        if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
          NotificationManager.error(res?.data?.KCSRPT?.details[0]?.ErrorMsg);
          setLoader(false);
        } else {
          setDashboard(res.data && res?.data?.KCSRPT);
          let tableHeading = Object.keys(res?.data?.KCSRPT?.details[0]);
          setHeading(tableHeading);
          setGetprocessName(res?.data?.KCSRPT?.details[0]?.IMPType);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        handleError(err);
      });
  };

  /**
   * The function `handleFileChange` sets the selected file to the first file in the event target's
   * files array.
   * @param event - The event parameter is an object that represents the event that triggered the file
   * change. In this case, it is an event object that is generated when a file is selected in the file
   * input field.
   */

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  /**
   * Import File - while Importing file - open modal to upload xls file
   * The openModal function sets the state variables modalShow, process_name, exceptionCount, and
   * getAction based on the provided rowIndex, count, and action parameters.
   * @param rowIndex - The rowIndex parameter represents the index of the row in the dashboard.details
   * array that is being referenced.
   * @param count - The count parameter represents the number of exceptions for a specific row in the
   * dashboard.
   * @param action - The "action" parameter is a variable that represents the action to be performed when
   * the modal is opened. It could be a string or any other data type that determines what functionality
   * or behavior should be triggered when the modal is displayed.
   */

  const openModal = (rowIndex, count, action) => {
    setModalShow(!modalShow);
    setProcess_name(dashboard.details[rowIndex]);
    setExceptionCount(count);
    setGetAction(action);
  };

  /**
   * Authorization - once file uploaded to Authorize file
   * The `authorization` function checks if the countExp is "0" and countAct is "File Imported
   * Successfully", and if so, it makes API calls to get authorized upload files and dashboard data.
   * @param countExp - The countExp parameter is used to check if the count of expected files is equal to
   * "0".
   * @param countAct - The countAct parameter is a string that represents the actual count of files
   * imported.
   */

  const authorization = (rowIndex, countExp, countAct) => {
    if (
      countExp === "0" &&
      countAct === "Import Successfully and Pending for authorisation"
    ) {
      getAuthorizedUploadFiles(
        "masterimportauth",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        "MASTER",
        dashboard.details[rowIndex].IMPType,
        "AUTH",
        "LIST"
      ).then((response) => {
        NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg || "An Unexpected Error Occurred");
        setLoader(true);
        getDashboard(
          "processlist",
          sessionKey,
          userId,
          reportDate,
          financialYear,
          reportDate,
          "MASTER",
          dashboard.details[rowIndex].IMPType
        )
          .then((res) => {
            if (res) {
              getDashboardparamaters(
                sessionKey,
                userId,
                reportDate,
                financialYear
              );
              setLoader(false);
            }
          })
          .catch((err) => {
            handleError(err);
          });
      });
    }
  };

  /**
   * Upload file - upload file and update its state according to IMPTYPE
   * The function `getImportFile` is used to upload a selected file and retrieve the log filename and
   * path for further processing.
   */

  const handleUploadResponse = (logfilename) => {
    let data = JSON.parse(logfilename);
    setLogFiles(data.KCSRPT.details[0].logfilename);
    setLogPath(data.KCSRPT.details[0].logfilepath);
  };

  const handleDashboardResponse = () => {
    setLoader(false);
    getDashboardparamaters(sessionKey, userId, reportDate, financialYear);
  };

  /**
   * * * Upload file - upload file and update its state according to IMPTYPE
   * The function `getImportFile` is an asynchronous function that handles the process of uploading a
   * file, retrieving a log filename, and handling the response.
   * @returns The function `getImportFile` returns nothing.
   */

  const getImportFile = async () => {
    setModalShow(false);
    // setLoader(true);
    if (!selectedFile) {
      return;
    }

    try {
      const response = await getUploadFiles(
        sessionKey,
        userId,
        reportDate,
        branchCode,
        process_name?.IMPType ? process_name?.IMPType : "",
        "MASTER",
        reportDate,
        "DURGA",
        "119.162.10.01",
        selectedFile
      );

      if (response.data) {
        const logfilename = response.data;
        // setLoader(true);
        await getDashboard(
          "processlist",
          sessionKey,
          userId,
          reportDate,
          financialYear,
          reportDate,
          "Master",
          process_name?.IMPType ? process_name?.IMPType : ""
        );

        if (response) {
          // setLoader(false);
          handleDashboardResponse();
          handleUploadResponse(logfilename);
          let parseData = JSON.parse(response.data);
          NotificationManager.error(parseData.KCSRPT.details[0].ErrorMsg);
        }
      } else {
        console.log("No log filename found in response.");
      }
    } catch (err) {
      setLoader(false);
      handleError(err);
    }
  };

  const modalBackBtn =() => {
    setModalShow(false)
  }

  /**
   * The function `getRemarkClassName` returns a CSS class name based on the input remark.
   * @param remark - The `remark` parameter is a string that represents a remark or status message.
   * @returns The function `getRemarkClassName` returns a string representing the class name for a given
   * remark.
   */

  const getRemarkClassName = (remark) => {
    if (remark === "Authorised Successfully") {
      return "successRemark remark";
    } else if (remark === "Import Successfully and Pending for authorisation") {
      return "pendingRemark remark";
    } else if (remark === "Import failed with Error. Please check Log file") {
      return "failedRemark remark";
    } else if (remark === "") {
      return "";
    } else {
      return "remark";
    }
  };

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header">{Constants.welcomeText}</h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left">
              <h1>
                {Constants.reportingDateTime} {reportDate}
                {/* <span> {Constants.quarterText}</span>{" "} */}
              </h1>

              <div className="service-menu_row">
                <div className="dashbaord-menu" onClick={toggleServiceMenu}>
                  <TypeIcon />
                  <p>{Constants.masterText}</p>
                  <DropDownIcon />
                </div>

                {serviceMenu && (
                  <>
                    <div className="dashbaord-menu" onClick={navigateToProcess}>
                      <ProcessIcon />
                      <p>{Constants.processTitle}</p>
                      <DropDownIcon />
                    </div>
                    <div
                      className="dashbaord-menu"
                      onClick={navigateToStandard}
                    >
                      <StandardIcon />
                      <p>{Constants.standardText}</p>
                      <DropDownIcon />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="content-header-right">
              <h4 className="textDecoration">{Constants.importText}</h4>
              <h4 className="iconRow">
                {" "}
                <SearchIcon /> {Constants.searchText}{" "}
              </h4>
              <h4 className="iconRow position-relative">
                {" "}
                <>
                  {" "}
                  <SortIcon onClick={() => setShowSorting(true)} />{" "}
                  {Constants.sortText}{" "}
                </>{" "}
                {showsorting && (
                  <div className="sort-box" ref={popupRef}>
                    <p> {Constants.sortByText} </p>
                    <div className="radio-group">
                      <label>
                        {" "}
                        <input
                          type="radio"
                          value="total_count"
                          name="count"
                        />{" "}
                        {Constants.totalCountText}{" "}
                      </label>
                      <label>
                        {" "}
                        <input
                          type="radio"
                          value="exception_count"
                          name="count"
                        />{" "}
                        {Constants.exceptionCountText}{" "}
                      </label>
                    </div>
                    <button
                      className="apply-btn"
                      onClick={() => setShowSorting(false)}
                    >
                      {Constants.applyBtnText}
                    </button>
                  </div>
                )}{" "}
              </h4>
              <h4 className="iconRow position-relative">
                {" "}
                <Filtercon onClick={() => setShowFilter(true)} />{" "}
                {showfilter && (
                  <div className="sort-box filter-box" ref={filterRef}>
                    <p> {Constants.filterText} </p>
                    <Form.Group controlId="formCheckboxGroup">
                      {checkboxes}
                    </Form.Group>
                    <button
                      className="apply-btn"
                      onClick={() => setShowFilter(false)}
                    >
                      {Constants.applyBtnText}
                    </button>
                  </div>
                )}{" "}
              </h4>
              <div className="content-header-right" style={{pointerEvents: "none"}}>
                <BackBtn />
              </div>
            </div>
          </div>
          {loader === false ? (
            <div className="content-table main-report-table ">
              <div className="table-container">
                <table className="table">
                  <thead className="dashboard_content">
                    <tr>
                      <th className="dashboard_remark">{Constants.infoText}</th>
                      <th
                        className="dashboard_remark"
                      //  className="textLeft"
                      >
                        {Constants.processText}
                      </th>
                      <th className="dashboard_remark">
                        {Constants.actionText}
                      </th>
                      <th className="dashboard_remark">
                        {Constants.totalCountText}
                      </th>
                      <th className="dashboard_remark">
                        {Constants.exceptionCountText}
                      </th>
                      <th className="dashboard_remark">{Constants.remark}</th>
                      <th className="dashboard_remark">
                        {Constants.dataPrepared}
                      </th>
                      <th className="dashboard_remark">
                        {Constants.successText}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboard.details &&
                      dashboard?.details?.map((title, rowIndex) => (
                        <tr key={title[rowIndex]}>
                          <td className="view">
                            <span onClick={() => handleCountModal(rowIndex)}>
                              <InfoIcon />
                            </span>
                          </td>
                          <td className="textLeft"> {title?.ImpTypeDesc}</td>
                          {title["Exception Count"] === "0" &&
                            title.Remark ===
                            "Import Successfully and Pending for authorisation" ? (
                            <td>
                              <button
                                className="importBtn"
                                onClick={() =>
                                  authorization(
                                    rowIndex,
                                    title["Exception Count"],
                                    title.Remark
                                  )
                                }
                              >
                                {" "}
                                {title["Next Action Required"]}
                              </button>
                            </td>
                          ) : (
                            <td>
                              <button
                                className="importBtn"
                                onClick={() =>
                                  openModal(
                                    rowIndex,
                                    title["Exception Count"],
                                    title.Remark
                                  )
                                }
                              >
                                {" "}
                                {title["Next Action Required"]}
                              </button>
                            </td>
                          )}
                          <td className="log-link exception_count">
                            {title["Total Count"] ? (
                              <span
                                onClick={() =>
                                  navigateToTotalCount(
                                    title["Total Count"],
                                    rowIndex
                                  )
                                }
                              >
                                {title["Total Count"]}
                              </span>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td className="log-link exception_count">
                            {title["Exception Count"] ? (
                              <span
                                onClick={() =>
                                  navigateToExceptionCount(
                                    title["Exception Count"],
                                    rowIndex
                                  )
                                }
                              >
                                {title["Exception Count"]}
                              </span>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td className="remarks">
                            {" "}
                            <p className={getRemarkClassName(title.Remark)}>
                              {title.Remark}
                            </p>
                          </td>
                          <td className="isDataPrepared">
                            {" "}
                            {/* {title.Remark === "Authorised Successfully" ? (
                              <img
                                src={CorrectIcon}
                                width="24px"
                                height="24px"
                                alt="success"
                              />
                            ) : (
                              "--"
                            )} */}
                            --
                          </td>
                          <td className="log-link">
                            <span onClick={() => handleViewLog(rowIndex)}>
                              {title["Last Success Log File"]}
                            </span>
                          </td>
                          <td className="view position-relative">
                            <MoreIcon
                              onClick={() => showActionOverlay(rowIndex)}
                            />{" "}
                            {actionId === rowIndex && actionOverlay && (
                              <ActionOverlay
                                logFiles={dashboard}
                                getprocessName={rowIndex}
                                getPath={logPath}
                              />
                            )}{" "}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="main-overlay"
                show={modalShow}
              >
                <Modal.Body className="modal_body">
                  <div className="overlay-content ">
                    {/* <p>{Constants.logoutText}</p> */}
                    <h4 className="modal-main-report">
                      {" "}
                      {Constants.uploadfile} :
                    </h4>
                    <input
                      id="fileInput"
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={handleFileChange}
                      // style={{ display: "none" }}
                      ref={fileInputRef} // Make sure this line is present
                    />
                  </div>

                  <button onClick={getImportFile} className="closeBtn">
                    {Constants.modaltext}
                  </button>
                  <button onClick={modalBackBtn} style={{marginRight: '10px'}} className="closeBtn">
                    {Constants.modalBackText}
                  </button>
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            <div className="spinner-loader">
              <div class="spinner-border d-flex m-auto" role="status"></div>
            </div>
          )}
        </div>
        <UserDetailsFooter/>
        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
        <CountInformation
          infoDetails={infoDetails}
          heading={heading}
          show={countModalShow}
          onHide={() => setCountModalShow(false)}
        />
      </div>
    </MainLayout>
  );
};

export default Dashboard;
