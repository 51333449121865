import "./dashboard.css";
import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import CorrectIcon from "../../assets/images/correct.png";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/More.svg";
import { ReactComponent as SortIcon } from "../../assets/images/sort.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/Search.svg";
import { ReactComponent as Filtercon } from "../../assets/images/filter.svg";
import { ReactComponent as DropDownIcon } from "../../assets/images/dropdown.svg";
import { ReactComponent as TypeIcon } from "../../assets/images/Category-fill.svg";
import { ReactComponent as ProcessIcon } from "../../assets/images/Star.svg";
import { ReactComponent as StandardIcon } from "../../assets/images/Bookmark-fill.svg";
import ActionOverlay from "../../components/Overlay/ServiceActionModal/ActionOverlay";
import CountInformation from "../../components/Overlay/CountInformation/CountInformation";
import { BackBtn } from "../../components/Buttons/CustomButton";
import {
  getDashboard,
  getDashboardTotalClick,
  getInfoDisplay,
  getUploadFiles,
  getprocessAuthorizedUploadFiles,
} from "../../Api";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import { handleError } from "../../Services/ErrorService";
import ReaseonOverlay from "../../components/Overlay/FreezeModal/ReaseonOverlay";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const Process = (props) => {
  const [countModalShow, setCountModalShow] = useState(false);
  const [actionOverlay, setActionOverlay] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [showsorting, setShowSorting] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [showfilter, setShowFilter] = useState(false);
  const [serviceMenu, setServiceMenu] = useState(false);
  const navigate = useNavigate();
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [processDashboard, setProcessDashboard] = useState([]);
  const [heading, setHeading] = useState();
  const [reportDate, setReporteDate] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [financialYear, setFinancialYear] = useState("");
  const [, setGetprocessName] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [, setLogFiles] = useState([]);
  const [process_name, setProcess_name] = useState("");
  const [logPath, setLogPath] = useState([]);
  const [infoDetails, setInfoDetails] = useState([]);
  const [, setExceptionCount] = useState("");
  const [getImptype, setGetImptype] = useState("");
  const [getAction, setGetAction] = useState("");
  const [showReasonOverlay, setShowReasonOverlay] = useState(false);
  const [get_date, setGet_date] = useState("");
  const [loader, setLoader] = useState(true);

  /**
   * open modal ----- to cancle process Authorized file based on action
   * The function `showReasonOverlayModal` sets the values of `getImptype`, `getAction`, and
   * `showReasonOverlay` based on the `rowIndex` and `countAct` parameters.
   * @param rowIndex - The rowIndex parameter is the index of the row in the processDashboard.details
   * array that you want to access.
   * @param countAct - The countAct parameter is a variable that represents the count of actions.
   */

  const showReasonOverlayModal = (rowIndex, countAct) => {
    setGetImptype(processDashboard.details[rowIndex].IMPType);
    setGetAction(countAct);
    setShowReasonOverlay(!showReasonOverlay);
  };

  /**
   * * Info - Service Details Count Modal
   * The function `handleCountModal` sets the state variables `countModalShow`, `process_name`,
   * `infoDetails`, and `heading` based on the response from the `getInfoDisplay` function.
   * @param rowIndex - The rowIndex parameter represents the index of the row in the
   * processDashboard.details array that is being clicked or selected.
   */

  const handleCountModal = (rowIndex) => {
    setInfoDetails("");
    setHeading("");
    setCountModalShow(true);
    setProcess_name(processDashboard.details[rowIndex]);
    getInfoDisplay(
      "infodisplay",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      processDashboard?.details[rowIndex]?.IMPType,
      ""
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
        NotificationManager.error(response?.data?.KCSRPT?.details[0]?.ErrorMsg);
        setInfoDetails("");
        setHeading("");
      } else {
        setInfoDetails(response?.data && response?.data?.KCSRPT);
        let tableHeading = response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : [];
        setHeading(tableHeading);
      }
    });
  };

  /**
   * navigateToTotalCount :: Redirect to total count page
   */
  const navigateToTotalCount = (count, index) => {
    if (count === "0") {
      NotificationManager.error("No records available in Total Count")
    } else {
      getDashboardTotalClick(
        "Pending Data",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        "PROCESS",
        processDashboard?.details[index]?.IMPType,
        ""
      ).then((response) => {
        if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0]?.ErrorMsg
          );
        } else {
          navigate("/dashboard/total-count", {
            state: {
              response: response?.data,
              columnname: processDashboard?.details[index]?.ImpTypeDesc,
              Imptype: processDashboard?.details[index]?.IMPType,
              type: "PROCESS",
              action: processDashboard?.details[index]["Next Action Required"],
              exceptionCount : processDashboard?.details[index]["Exception Count"],
            },
          });
        }
      });
    }
  };

  /**
   * navigateToExceptionCount :: Redirect to exception count page
   */
  const navigateToExceptionCount = (count, index) => {
    if (count === "0") {
      NotificationManager.error("No records available in exception")
    } else {
      navigate("/dashboard/exception-count", {
        state: {
          Imptype: processDashboard?.details[index]?.IMPType,
          columnname: processDashboard?.details[index]?.ImpTypeDesc,
          type: "PROCESS",
        },
      });
    }
  };

  /**
   * handleViewLog :: Redirect to View log page
   */
  const handleViewLog = (index) => {
    navigate("/dashboard/viewlog", {
      state: {
        logFiles: processDashboard,
        getprocessName: index,
        getPath: logPath,
      },
    });
  };

  /**
   * showActionOverlay ::  Show Action overlay popup
   * @param {*} id
   */
  const showActionOverlay = (id) => {
    setActionOverlay(true);
    setActionId(id);
  };

  /**
   * hideActionOverlay :: Hide Acction Overlay popup
   */
  const hideActionOverlay = () => {
    setActionOverlay(false);
    setActionId(null);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        actionOverlay &&
        actionId !== null &&
        !e.target.closest('.view.position-relative')
      ) {
        hideActionOverlay();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [actionOverlay, actionId]);

  /**
   * navigateToProcess :: Redirect to Process page
   */
  const navigateToProcess = () => {
    navigate("/dashboard/process");
  };

  /**
   * navigateToStandard :: Redirect to Standard Page
   */
  const navigateToStandard = () => {
    navigate("/dashboard/standard");
  };

  /**
   * toggleServiceMenu :: Toggle Service Menu
   */
  const toggleServiceMenu = () => {
    setServiceMenu(!serviceMenu);
  };

  /**
   * After 5 seconds hide action popup
   */
  // setTimeout(hideActionOverlay, 5000);

  const popupRef = useRef(null);
  const filterRef = useRef(null);

  const options = [
    { label: "Authorised Successfully", value: "success" },
    { label: "Import Failed with Error", value: "failed" },
    { label: "Import Sucess, Exp Pending", value: "Exp_Pending" },
    { label: "Import Sucess, Aut Pending", value: "Auth_Pending" },
  ];

  const checkboxes = options.map((option) => (
    <Form.Check
      key={option.value}
      type="checkbox"
      label={option.label}
      value={option.value}
      checked={checkboxValues.includes(option.value)}
      onChange={(e) => {
        const isChecked = e.target.checked;
        const value = e.target.value;
        setCheckboxValues((prevValues) =>
          isChecked
            ? [...prevValues, value]
            : prevValues.filter((v) => v !== value)
        );
      }}
    />
  ));

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowSorting(false);
      } else if (
        filterRef.current &&
        !filterRef.current.contains(event.target)
      ) {
        setShowFilter(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let getFinancialYear = localStorage.getItem("Financial_year");
      setFinancialYear(getFinancialYear);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        await getDashboardparamaters(
          getSessionId,
          getUserId,
          userDate,
          getFinancialYear
        );
      } else if (getdate) {
        setReporteDate(getdate);
        await getDashboardparamaters(
          getSessionId,
          getUserId,
          getdate,
          getFinancialYear
        );
      }
    };
    checkAuth();
  }, [get_date]);

  /**
   * * Dashboard - Render Master Dashboard with IMPTYPE = ""
   * The function `getDashboardparamaters` retrieves dashboard parameters and sets the process dashboard,
   * table heading, and process name based on the response.
   * @param sessionKey - The sessionKey is a unique identifier that represents the current user's
   * session. It is used to authenticate and authorize the user's access to the dashboard.
   * @param userId - The userId parameter is used to identify the user for whom the dashboard data is
   * being retrieved.
   * @param reportDate - The reportDate parameter is the date for which the dashboard data is requested.
   * @param financialYear - The financial year parameter is used to specify the financial year for which
   * the dashboard data is requested.
   */

  const getDashboardparamaters = (
    sessionKey,
    userId,
    reportDate,
    financialYear
  ) => {
    setLoader(true);
    getDashboard(
      "processlist",
      sessionKey,
      userId,
      reportDate,
      financialYear,
      reportDate,
      "Process",
      ""
    )
      .then((res) => {
        if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
          NotificationManager.error(res?.data?.KCSRPT?.details[0].ErrorMsg);
          setLoader(false);
        } else {
          setProcessDashboard(res.data && res?.data?.KCSRPT);
          let tableHeading = Object.keys(res?.data?.KCSRPT?.details[0]);
          setHeading(tableHeading);
          setGetprocessName(res?.data?.KCSRPT?.details[0].IMPType);
          setLoader(false);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  /**
   * * Import File - while Importing file - open modal to upload xls file
   * The openModal function is used to toggle the visibility of a modal and update the exception count
   * and process name.
   * @param rowIndex - The rowIndex parameter represents the index of the row in the
   * processDashboard.details array that is being clicked or selected.
   * @param count - The count parameter is the number of exceptions for a specific process.
   */

  const openModal = (rowIndex, count) => {
    setModalShow(!modalShow);
    setExceptionCount(count);
    setProcess_name(processDashboard.details[rowIndex]);
  };

  /**
   * * Authorization - once file uploaded to Authorize file
   * The function `authorization` checks if the count of expected and actual values meet certain
   * conditions, and if so, it calls other functions to perform authorization and handle the response.
   * @param rowIndex - The `rowIndex` parameter represents the index of a row in a table or array. It
   * is used to identify a specific row in the `processDashboard.details` array.
   * @param countExp - The `countExp` parameter represents the expected count of a certain action or
   * event.
   * @param countAct - The value of countAct is a string that represents the current status of the file
   * import process. It can have one of the following values:
   */

  const authorization = (rowIndex, countExp, countAct) => {
    if (
      countExp === "0" &&
      countAct === "Import Successfully and Pending for authorisation"
    ) {
      getprocessAuthorizedUploadFiles(
        "basefileauth",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        processDashboard.details[rowIndex].IMPType,
        ""
      ).then((response) => {
        NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg);
        setLoader(true);
        getDashboard(
          "processlist",
          sessionKey,
          userId,
          reportDate,
          financialYear,
          reportDate,
          "process",
          processDashboard.details[rowIndex].IMPType
        )
          .then((res) => {
            if (res) {
              getDashboardparamaters(
                sessionKey,
                userId,
                reportDate,
                financialYear
              );
              setLoader(false);
            }
          })
          .catch((err) => {
            handleError(err);
          });
      });
    }
  };

  /**
   * * Upload file - upload file and update its state according to IMPTYPE
   * The function `getImportFile` is used to handle the import of a selected file, perform some API
   * calls, and update the state with the log file details.
   */

  const getImportFile = async () => {
    setModalShow(false);

    if (!selectedFile) {
      return;
    }

    try {
      const response = await getUploadFiles(
        sessionKey,
        userId,
        reportDate,
        branchCode,
        process_name?.IMPType ?? "", // Use nullish coalescing operator
        "PROCESS",
        reportDate,
        "DURGA",
        "119.162.10.01",
        selectedFile
      );

      if (!response.data) {
        console.log("No log filename found in response.");
        return;
      }

      const logfilename = response.data;
      const res = await getDashboard(
        "processlist",
        sessionKey,
        userId,
        reportDate,
        financialYear,
        reportDate,
        "PROCESS",
        process_name?.IMPType ?? "" // Use nullish coalescing operator
      );

      if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
        NotificationManager.error(res?.data?.KCSRPT?.details[0].ErrorMsg);
      } else {
        await getDashboardparamaters(
          sessionKey,
          userId,
          reportDate,
          financialYear
        );
        setLoader(false);
      }

      const data = JSON.parse(logfilename);
      setLogFiles(data.KCSRPT.details[0].logfilename);
      setLogPath(data.KCSRPT.details[0].logfilepath);
    } catch (err) {
      handleError(err);
    }
  };

  const modalBackBtn = () => {
    setModalShow(false)
  }

  /**
   * The function `getActionClickHandler` returns different click handlers based on the conditions of the
   * `title` object.
   * @param title - The `title` parameter is an object that contains information about a specific item.
   * It likely has properties such as "Exception Count" and "Remark" that are used to determine the
   * appropriate action click handler.
   * @param rowIndex - The rowIndex parameter represents the index of the row in a table or list. It is
   * used to identify the specific row that the click action is associated with.
   * @returns The function `getActionClickHandler` returns a function based on the conditions specified
   * in the if-else statements. The returned function depends on the values of the `title["Exception
   * Count"]` and `title.Remark` properties.
   */

  const getActionClickHandler = (title, rowIndex) => {
    if (
      title["Exception Count"] === "0" &&
      title.Remark === "Import Successfully and Pending for authorisation"
    ) {
      return () =>
        authorization(rowIndex, title["Exception Count"], title.Remark);
    } else if (
      title.Remark === "Authorised Successfully" ||
      title.Remark === "Cancel Successfully and Pending for authorisation"
    ) {
      return () => showReasonOverlayModal(rowIndex, title.Remark);
    } else {
      return () => openModal(rowIndex, title["Exception Count"]);
    }
  };

  /**
   * The function `getRemarkClassName` returns a CSS class name based on the input remark.
   * @param remark - The `remark` parameter is a string that represents a remark or status message.
   * @returns The function `getRemarkClassName` returns a string representing the class name for a given
   * remark.
   */

  const getRemarkClassName = (remark) => {
    if (remark === "Authorised Successfully") {
      return "successRemark remark";
    } else if (remark === "Import Successfully and Pending for authorisation") {
      return "pendingRemark remark";
    } else if (remark === "Import failed with Error. Please check Log file") {
      return "failedRemark remark";
    } else if (remark === "") {
      return "";
    } else {
      return "remark";
    }
  };

  return (
    <MainLayout>
      <div className="layoutWrapper ">
        <div className="header-wrapper">
          <h1 className="header">{Constants.welcomeText}</h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left">
              <h1>
                {Constants.reportingDateTime} {reportDate}
                <span> {Constants.quarterText}</span>{" "}
              </h1>
              <div className="service-menu_row">
                <div className="dashbaord-menu" onClick={toggleServiceMenu}>
                  <TypeIcon />
                  <p>{Constants.masterText}</p>
                  <DropDownIcon />
                </div>
                {serviceMenu && (
                  <>
                    <div className="dashbaord-menu" onClick={navigateToProcess}>
                      <ProcessIcon />
                      <p>{Constants.processTitle}</p>
                      <DropDownIcon />
                    </div>
                    <div
                      className="dashbaord-menu"
                      onClick={navigateToStandard}
                    >
                      <StandardIcon />
                      <p>{Constants.standardText}</p>
                      <DropDownIcon />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="content-header-right">
              <h4 className="textDecoration">{Constants.importText}</h4>
              <h4 className="iconRow">
                {" "}
                <SearchIcon /> {Constants.searchText}{" "}
              </h4>
              <h4 className="iconRow position-relative">
                {" "}
                <>
                  {" "}
                  <SortIcon onClick={() => setShowSorting(true)} />{" "}
                  {Constants.sortText}{" "}
                </>{" "}
                {showsorting && (
                  <div className="sort-box" ref={popupRef}>
                    <p> {Constants.sortByText} </p>
                    <div className="radio-group">
                      <label>
                        {" "}
                        <input
                          type="radio"
                          value="total_count"
                          name="count"
                        />{" "}
                        {Constants.totalCountText}{" "}
                      </label>
                      <label>
                        {" "}
                        <input
                          type="radio"
                          value="exception_count"
                          name="count"
                        />{" "}
                        {Constants.exceptionCountText}{" "}
                      </label>
                    </div>
                    <button
                      className="apply-btn"
                      onClick={() => setShowSorting(false)}
                    >
                      {Constants.applyBtnText}
                    </button>
                  </div>
                )}{" "}
              </h4>
              <h4 className="iconRow position-relative">
                {" "}
                <Filtercon onClick={() => setShowFilter(true)} />{" "}
                {showfilter && (
                  <div className="sort-box filter-box" ref={filterRef}>
                    <p> {Constants.filterText} </p>
                    <Form.Group controlId="formCheckboxGroup">
                      {checkboxes}
                    </Form.Group>
                    <button
                      className="apply-btn"
                      onClick={() => setShowFilter(false)}
                    >
                      {Constants.applyBtnText}
                    </button>
                  </div>
                )}{" "}
              </h4>
              <div className="content-header-right">
                <BackBtn />
              </div>
            </div>
          </div>
          {loader === false ? (
            <div className="content-table main-report-table ">
              <div className="table-container">
                <table className="table">
                  <thead className="dashboard_content">
                    <tr>
                      <th className="dashboard_remark">{Constants.infoText}</th>
                      <th className="dashboard_remark">
                        {Constants.processText}
                      </th>
                      <th className="dashboard_remark">
                        {Constants.actionText}
                      </th>
                      <th className="dashboard_remark">
                        {Constants.totalCountText}
                      </th>
                      <th className="dashboard_remark">
                        {Constants.exceptionCountText}
                      </th>
                      <th className="dashboard_remark">{Constants.remark}</th>
                      <th className="dashboard_remark">
                        {Constants.dataPrepared}
                      </th>
                      <th className="dashboard_remark">
                        {Constants.successText}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {processDashboard.details &&
                      processDashboard?.details?.map((title, rowIndex) => (
                        <tr key={title[rowIndex]}>
                          <td className="view">
                            <span onClick={() => handleCountModal(rowIndex)}>
                              <InfoIcon />
                            </span>
                          </td>
                          <td className="textLeft"> {title.ImpTypeDesc}</td>

                          <td>
                            <button
                              className="importBtn"
                              onClick={getActionClickHandler(title, rowIndex)}
                            >
                              {title["Next Action Required"]}
                            </button>
                          </td>

                          <td className="log-link exception_count">
                            {title["Total Count"] ? (
                              <span
                                onClick={() =>
                                  navigateToTotalCount(
                                    title["Total Count"],
                                    rowIndex
                                  )
                                }
                              >
                                {title["Total Count"]}
                              </span>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td className="log-link exception_count">
                            {title["Exception Count"] ? (
                              <span
                                onClick={() =>
                                  navigateToExceptionCount(
                                    title["Exception Count"],
                                    rowIndex
                                  )
                                }
                              >
                                {title["Exception Count"]}
                              </span>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td>
                            {" "}
                            <p className={getRemarkClassName(title.Remark)}>
                              {title.Remark}
                            </p>
                          </td>
                          <td className="isDataPrepared">
                            {" "}
                            {title.Remark === "Authorised Successfully" ? (
                              <img
                                src={CorrectIcon}
                                width="24px"
                                height="24px"
                                alt="success"
                              />
                            ) : (
                              "--"
                            )}
                          </td>
                          <td className="log-link">
                            <span onClick={() => handleViewLog(rowIndex)}>
                              {title["Last Success Log File"]}
                            </span>
                          </td>
                          <td className="view position-relative">
                            <MoreIcon
                              onClick={() => showActionOverlay(rowIndex)}
                            />{" "}
                            {actionId === rowIndex && actionOverlay && (
                              <ActionOverlay
                                logFiles={processDashboard}
                                getprocessName={rowIndex}
                                getPath={logPath}
                              />
                            )}{" "}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="main-overlay"
                show={modalShow}
              >
                <Modal.Body className="modal_body">
                  <div className="overlay-content ">
                    {/* <p>{Constants.logoutText}</p> */}
                    <h4 className="modal-main-report">
                      {" "}
                      {Constants.uploadfile} :
                    </h4>
                    <input
                      id="fileInput"
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={handleFileChange}
                      // style={{ display: "none" }}
                      ref={fileInputRef} // Make sure this line is present
                    />
                  </div>

                  <button onClick={getImportFile} className="closeBtn">
                    {Constants.modaltext}
                  </button>
                  <button onClick={modalBackBtn} style={{marginRight: '10px'}} className="closeBtn">
                    {Constants.modalBackText}
                  </button>
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            <div className="spinner-loader">
              <div class="spinner-border d-flex m-auto" role="status"></div>
            </div>
          )}
        </div>
        <ReaseonOverlay
          getImptype={getImptype}
          getAction={getAction}
          setProcessDashboard={setProcessDashboard}
          heading={heading}
          show={showReasonOverlay}
          onHide={() => setShowReasonOverlay(false)}
          title={props.title}
        />

        <UserDetailsFooter/>
        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
        <CountInformation
          infoDetails={infoDetails}
          heading={heading}
          show={countModalShow}
          onHide={() => setCountModalShow(false)}
        />
      </div>
    </MainLayout>
  );
};

export default Process;
