import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { NotificationManager } from "react-notifications";
import {
  getASFileSubtype,
  getFiletyperptdate,
  getQoQReport,
  getReportingDateList,
} from "../../Api";
import { ReactComponent as TypeIcon } from "../../assets/images/Category.svg";
import { ReactComponent as ScanIcon } from "../../assets/images/Scan.svg";
import { BackBtn } from "../../components/Buttons/CustomButton";
import { formatDateToDDMMMYYYY } from "../../utils";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const QaQComparison = () => {
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [qoqInfo, setQoQInfo] = useState([]);
  const [heading, setHeading] = useState("");
  const [reportingDate, setReportingDate] = useState([]);
  const [getFileType, setGetFileType] = useState([]);
  const [file, setFile] = useState("");
  const [get_repotingDate, setGet_reportingDate] = useState("");
  const [get_date, setGet_date] = useState("");
  const [, setIsProcess] = useState("false");
  const [loader, setLoader] = useState(false);
  const [selectedSubfile, setSelectedSubfile] = useState("");
  const [file_type, setFile_type] = useState("false");
  const [sub_type, setSub_type] = useState("false");
  const [getFileSubType, setGetFileSubType] = useState([]);

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        // await get_QoQReportDetails(getSessionId, getUserId, userDate);

        await getReportingList(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        // await get_QoQReportDetails(getSessionId, getUserId, getdate);

        await getReportingList(getSessionId, getUserId, getdate);
      }
    };
    checkAuth();
  }, [get_date]);

  const getReportingList = (sessionKey, userId, reportDate) => {
    getReportingDateList(
      "rptdatemst",
      sessionKey,
      userId,
      reportDate,
      "LIST",
      "",
      "",
      ""
    ).then((response) => {
      if (response) {
        setReportingDate(response.data.KCSRPT);
      }
    });
  };

  const getReportingDateDeatils = (index) => {
    setGet_reportingDate(reportingDate.details[index]["Reporting Date"]);
    getFiletyperptdate(
      "filetyperptdate",
      sessionKey,
      userId,
      reportDate,
      reportingDate.details[index]["Reporting Date"]
    ).then((response) => {
      if (response) {
        setGetFileType(response.data.KCSRPT);
      }
    });
  };

  const getfileDeatils = (index) => {
    setFile_type(true);
    setSub_type(false);
    setFile(getFileType?.details[index]?.ImpType);
    getReportingFiletype(getFileType?.details[index]?.ImpType);
  };

  const getReportingFiletype = (file) => {
    getASFileSubtype(
      "reportingsubtype",
      sessionKey,
      userId,
      reportDate,
      file
    ).then((response) => {
      if (response) {
        setGetFileSubType(response.data.KCSRPT);
      }
    });
  };

  const getSelectedSubFile = (index) => {
    setSub_type(true);
    setFile_type(false);
    setSelectedSubfile(getFileSubType.details[index].pcode1);
  };

  /**
   * The function `getQoQReportDetails` retrieves a quarterly report using a session key, user ID, and report
   * date, and then sets the report information and table heading.
   * @param sessionKey - The sessionKey parameter is a unique identifier that is used to authenticate
   * and authorize a user's session in the system. It is typically generated when a user logs in or
   * authenticates their session.
   * @param userId - The `userId` parameter is used to identify the user for whom the report is being
   * generated. It is typically a unique identifier assigned to each user in the system.
   * @param reportDate - The `reportDate` parameter is a date that is used to fetch the QoQ (Quarter
   * over Quarter) report. It is passed as an argument to the `getQoQReportDetails` function.
   */

  const getQoQReportDetails = () => {
    setIsProcess(true);
    setLoader(true);
    getQoQReport(
      "qtronqtrrpt",
      sessionKey,
      userId,
      reportDate,
      get_repotingDate || reportDate,
      file || "DP",
      selectedSubfile || "MIS Report"
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
        setLoader(false);
        NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg);
      } else {
        setLoader(false);
        setQoQInfo(response.data && response?.data?.KCSRPT);
        let tableHeading = response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : []
        setHeading(tableHeading);
      }
    });
  };

  const clearQoQReportDetails = () => {
    setGet_reportingDate("");
    setFile("");
    setSub_type(false)
    setQoQInfo([])
    setHeading("")
  };

  const get_QoQReportDetails = (sessionKey, userId, reportDate) => {
    setIsProcess(false);
    setLoader(true);
    getQoQReport(
      "qtronqtrrpt",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      "DP",
      ""
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
        setLoader(false);
        NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg);
      } else {
        setLoader(false);
        setQoQInfo(response.data && response?.data?.KCSRPT);
        let tableHeading = response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : []
        setHeading(tableHeading);
      }
    });
  };

  // let buttonText = "MIS Report"; // Default value
  let buttonText = "Sub File Type"; // Default value

  if (file_type === true && sub_type === false) {
    buttonText = "Sub File Type";
  } else if (sub_type === true && file_type === false) {
    buttonText = selectedSubfile;
  }

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header"> {Constants.misQoqreport} </h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header align-items-baseline">
            <div className="content-header-left flex-column align-items-start">
              <h1>
                {Constants.reporting_date} : {reportDate}
              </h1>
              <div className="custom-row gx-5 custom-body">
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <TypeIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {get_repotingDate || reportDate}
                      </button>
                      <ul className="dropdown-menu ">
                        {reportingDate?.details &&
                          reportingDate?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => getReportingDateDeatils(index)}
                              >
                                {ele["Reporting Date"]}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {file || "File Type"}
                      </button>
                      <ul className="dropdown-menu ">
                        {getFileType?.details &&
                          getFileType?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => getfileDeatils(index)}
                              >
                                {ele?.ImpType}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div className="input-group">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {buttonText}
                      </button>
                      <ul className="dropdown-menu">
                        {getFileSubType?.details &&
                          getFileSubType?.details?.map((ele, index) => (
                            <li
                              key={ele[index]}
                              className="btn btn-secondary"
                              onClick={() => getSelectedSubFile(index)}
                            >
                              {ele.pcode1}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-header-right process_btn">
              <div className="flex-row custom-row">
                <GernerateCSVFile
                  generateCSV={qoqInfo}
                  reportName={"QoQReport"}
                />
                <BackBtn />
              </div>
              <div className="d-flex gap-2">
                <button className="backBtn" onClick={getQoQReportDetails}>
                  {Constants.processTest}
                </button>
                <button className="backBtn" onClick={clearQoQReportDetails}>
                  {Constants.clearText}
                </button>
              </div>
            </div>
          </div>
          {loader === false ? (
            <div className="content-table reporting-table">
              <div className="table-container">
                <table className="table">
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "white",
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      {heading
                        ? heading.map((title) => {
                          return (
                            <th
                              key={title}
                              className="heading_font qoq-heading"
                            >
                              {title}
                            </th>
                          );
                        })
                        : null}
                    </tr>
                  </thead>
                  <tbody>
                    {qoqInfo?.details &&
                      qoqInfo.details.map((row, rowIndex) => (
                        <tr key={row[rowIndex]}>
                          {Object.keys(row).map((columnName) => {
                            const value = row[columnName];
                            let formattedValue = value;

                            // Check if the value is a valid date and not a number
                            const dateValue = new Date(value);
                            if (
                              !isNaN(dateValue.getDate()) &&
                              isNaN(Number(value))
                            ) {
                              // Format the date as you desire
                              const options = {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              };

                              formattedValue = formatDateToDDMMMYYYY(
                                dateValue.toLocaleDateString("en-US", options)
                              );
                            } else if (
                              columnName === "Financial Year" ||
                              columnName === "FinYear" ||
                              columnName === "Financial Year" ||
                              columnName === "Imported On"
                            ) {
                              // If not a date and matches specific column names, format as needed
                              formattedValue = value || "-";
                            } else {
                              // Handle other columns, e.g., numeric formatting
                              const numericValue = parseFloat(value);
                              if (!isNaN(numericValue)) {
                                formattedValue = numericValue.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                );
                              }
                            }

                            // Conditionally apply a class for left alignment if it's a date
                            const isDate =
                              !isNaN(dateValue.getDate()) &&
                              isNaN(Number(value));
                            const tdClassName = ` ${isDate ? "left_align" : ""
                              }`;

                            return (
                              <td
                                className={`${!isNaN(row[columnName])
                                  ? "report_alignment main_report qoq-content"
                                  : "dashed qoq-content-report"
                                  }${tdClassName}`}
                                key={columnName}
                              >
                                <b>{formattedValue}</b>
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* <ResolvedApi
              get_response={qoqInfo}
              heading={heading}
              navigateToReportDetails={""}
            /> */}
            </div>
          ) : (
            <div className="spinner-loader">
              <div class="spinner-border d-flex m-auto" role="status"></div>
            </div>
          )}
        </div>

        <UserDetailsFooter/>

        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default QaQComparison;
