import "./As.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import {
    getIntermidiateReport
} from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const IntermidiateLevelReport = () => {
    const navigate = useNavigate();
    const [, setSessionKey] = useState("");
    const [, setUserId] = useState("");
    const [, setBranchCode] = useState("");
    const location = useLocation();
    const [heading, setHeading] = useState();
    const [getReportingDetails, setGetReportingDetails] = useState([]);
    const [reportDate, setReporteDate] = useState("");
    const getLevel2Deatils =
        location?.state && location.state ? location.state : "";
    const [getCount, setGetCount] = useState("");
    const [, setFinancialYear] = useState("")
    const [get_date, setGet_date] = useState("");
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getBranch = await localStorage.getItem("branch_code");
            setBranchCode(getBranch);

            let userDate = await localStorage.getItem("date_key");
            let getdate = await localStorage.getItem("reporting_date");

            let getfinyear = await localStorage.getItem("finyear")
            setFinancialYear(getfinyear)

            if (userDate) {
                setReporteDate(userDate);
                await getSingleReports(getSessionId, getUserId, userDate, getfinyear);
            } else {
                setReporteDate(getdate);
                await getSingleReports(getSessionId, getUserId, getdate, getfinyear);
            }

        };
        checkAuth();
    }, [get_date]);


    useEffect(() => {
        let count =
            (getReportingDetails?.details &&
                getReportingDetails?.details?.map((ele) => Number(ele.TotalAmt))) ||
            [];
        // This will create an array with the TotalAmt property of each object converted to a number

        let Total_Amount = count.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        setGetCount(Total_Amount);
    }, [getReportingDetails]);

    /**
     * The function `getSingleReports` retrieves single reports using session key and user ID, and handles
     * the response by displaying an error message if there is one, or setting the reporting details and
     * table heading if successful.
     * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current
     * session or user session in the application. It is used to authenticate and authorize the user's
     * access to certain resources or data.
     * @param userId - The `userId` parameter is used to identify the user for whom the single reports are
     * being fetched.
     */

    const getSingleReports = (sessionKey, userId, reportDate, financialYear) => {
        if (getLevel2Deatils) {
            setLoader(true)
            getIntermidiateReport(
                "as18 Intermediate Report rptlevel 1",
                sessionKey,
                userId,
                reportDate,
                reportDate,
                "AS",
                "AS Intermediate Report",
                getLevel2Deatils?.reportDetails &&
                getLevel2Deatils?.reportDetails.AS18MainHead,
                getLevel2Deatils?.reportDetails &&
                getLevel2Deatils?.reportDetails.RPTName,
                getLevel2Deatils?.rpttype && getLevel2Deatils.rpttype,
                getLevel2Deatils?.colvalue && getLevel2Deatils.colvalue,
                financialYear
            )
                .then((response) => {
                    if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
                        setLoader(false)
                        NotificationManager.error(
                            response?.data?.KCSRPT?.details[0].ErrorMsg
                        );
                    } else {
                        setLoader(false)
                        setGetReportingDetails(response.data && response?.data?.KCSRPT);
                        let tableHeading = Object.keys(
                            response?.data && response?.data?.KCSRPT?.details[0]
                        );
                        setHeading(tableHeading);
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    handleError(err);
                });
        }
    };

    return (
        <MainLayout>
            <div className="layoutWrapper">
                <div className="header-wrapper">
                    <h1 className="header">{Constants.intermidiatereportlevel}</h1>
                    <Header setGet_date={setGet_date} get_date={get_date} />
                </div>
                <div className="content-body">
                    <div className="content-header">
                        <div className="content-header-left">
                            <h1>
                                {Constants.reportingDateTime}
                                {reportDate}
                            </h1>
                        </div>
                        <div className="content-header-right">
                            <h3 className='total-amt'>{Constants.totalAmt}{getCount.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</h3>
                            <GernerateCSVFile
                                generateCSV={getReportingDetails}
                                reportName={"AS18IntermidiateLevel1"}
                            />
                            <button className="back-btn" onClick={() => navigate(-1)}>
                                {Constants.backBtnText}
                            </button>
                        </div>
                    </div>
                    {
                        loader === false ?
                            <div className="content-table main-report-table">
                                <ResolvedApi
                                    get_response={getReportingDetails}
                                    heading={heading}
                                    navigateToReportDetails={""}
                                />
                            </div>
                            : <div className="spinner-loader">
                                <div class="spinner-border d-flex m-auto" role="status"></div>
                            </div>
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default IntermidiateLevelReport;
