import React from 'react'
import Header from '../../components/Header/Header'
import MainLayout from '../../components/Layout/MainLayout'
import * as Constants from '../../constants/stringConstants'
import LayoutBtn from '../../components/LayoutBtn/LayoutBtn'
import { ReactComponent as ImportIcon } from '../../assets/images/Plus.svg'
import { ReactComponent as InfoIcon } from '../../assets/images/Activity_one.svg'
import { ReactComponent as ReportIcon } from '../../assets/images/Document.svg'

const Others = () => {
    return (
        <MainLayout>
            <div className='layoutWrapper'>
                <div className='header-wrapper'>
                    <h1 className='header'> {Constants.othersTitle} </h1>
                    <Header />
                </div>
                <div>
                    <LayoutBtn Icon={<InfoIcon />} title="Data Preparation" path="" />
                    <LayoutBtn Icon={<ImportIcon />} title="Reimport Report" path=""  />
                    <LayoutBtn Icon={<ReportIcon />} title="Intermediate Report" path="intermediate-report"  />
                </div>
            </div>
        </MainLayout>

    )
}

export default Others