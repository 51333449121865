import "./freeze.css";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as Constants from "../../../constants/stringConstants";
import { ReactComponent as FreezeIcon } from "../../../assets/images/unfreese.svg";
import ReaseonOverlay from "./ReaseonOverlay";
import ReaseonForUnfreeze from "./ReasonForUnfreeze";

const FreezeOverlay = (props) => {
  const [showReasonOverlay, setShowReasonOverlay] = useState(false);

  const showReasonOverlayModal = () => {
    props.onHide();
    setShowReasonOverlay(true);
  };



  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="freeze-overlay"
      >
        <Modal.Body>
          <div className="text-center text-icon">
            <FreezeIcon />
          </div>
          <h2>
            {Constants.freezeQuarterText} {props.title}
          </h2>
          <div className="btn-row">
            <button className="cancelBtn  modalBtn" onClick={props.onHide}>
              {Constants.no}
            </button>
            <button
              className="ignoreBtn modalBtn"
              onClick={showReasonOverlayModal}
            >
              {Constants.yes}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ReaseonForUnfreeze
        isFrozen={props.isFrozen}
        setIsFrozen={props.setIsFrozen}
        show={showReasonOverlay}
        onHide={() => setShowReasonOverlay(false)}
        title={props.title}
        date={props?.date}
      />
    </>
  );
};

export default FreezeOverlay;
