import React from 'react'
import './layoutBtn.css'
import { Link } from "react-router-dom";


const LayoutBtn = ({ Icon, title , path }) => {
    return (
        <div className='layout-btn'>
            <Link to={path} > 
            {Icon}
            <p> {title} </p></Link>
            
        </div>
    )
}

export default LayoutBtn