import './download.css'
import React from 'react'
import SuccessIcon from '../../../assets/images/correct.png'
import { Modal } from 'react-bootstrap'
import * as Constants from '../../../constants/stringConstants'

const DownloadOverlay = (props) => {
  return (
    <>
      {
        props.isDownloaded ? <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className='download-popup'>

          <Modal.Body>
            <div className='text-center' >
              <img src={SuccessIcon} alt="download_successfully" width="60" height="60" />
            </div>

            <h2>{Constants.downloadSuccessText}</h2>
          </Modal.Body>
          {/* <h1>{props.downLoadPath}</h1> */}

        </Modal> : ""
      }
    </>

  )
}

export default DownloadOverlay