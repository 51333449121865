import "./login.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Constants from "../../constants/stringConstants";
import { Col, Row, Button, Form, Container } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { ReactComponent as KCSLogo } from "../../assets/images/kcs-logo.svg";
import { ReactComponent as ToggleIcon } from "../../assets/images/hide.svg";
import { ReactComponent as LoginSvg } from "../../assets/images/login.svg";
import { NotificationManager } from "react-notifications";
import { loginUser } from "../../Api";
import { handleError } from "../../Services/ErrorService";

const Login = () => {
  const [showIcon, setShowIcon] = useState(false);
  const [showLoginIcon, setShowLoginIcon] = useState(false);
  const [showPswIcon, setShowPswIcon] = useState(false);
  const [branchCode, setBranchCode] = useState("");
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [branchCodeError, setBranchCodeError] = useState(false);
  const [loginIdError, setLoginIdError] = useState(false);
  const [pswError, setPswError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [error, setError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [showErrorNotification, setShowErrorNotification] = useState(true);
  const [hasErrorOccurred, setHasErrorOccurred] = useState(false);

  const navigate = useNavigate();

  useEffect(() => { }, []);

  /**
   * handleBranchCode :: Set Branch Code and its error state
   * @param {*} e
   */
  const handleBranchCode = (e) => {
    setBranchCode(e.target.value);
    setShowIcon(true);
    setError(false);
    setBranchCodeError(false);
  };

  /**
   * handleLoginId :: Set Login Id and its error states
   * @param {*} e
   */
  const handleLoginId = (e) => {
    setLoginId(e.target.value);
    setShowLoginIcon(true);
    setError(false);
    setLoginIdError(false);
  };

  /**
   * handlePassword :: Set Password and its error states
   * @param {*} e
   */
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setError(false);
    setPswError(false);
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
    setTermsError(false);
  };

  /**
   * goToDashboard :: Validattion for form feilds and on success redirect to dashbaord
   */

  const goToDashboard = async () => {
    setError(false);
    setBranchCodeError(false);
    setLoginIdError(false);
    setPswError(false);
    setTermsError(false);

    if (branchCode.trim() === "") {
      setBranchCodeError(true);
      return;
    }

    if (loginId.trim() === "") {
      setLoginIdError(true);
      return;
    }

    if (password.trim() === "") {
      setPswError(true);
      return;
    }

    if (!isChecked) {
      setTermsError(true);
      return;
    }

    const currentDate = new Date();

    // Format the current date and time in the desired format
    const formattedDate =
      ("0" + currentDate.getDate()).slice(-2) + "/" +
      ("0" + (currentDate.getMonth() + 1)).slice(-2) + "/" +
      currentDate.getFullYear() + " " +
      ("0" + currentDate.getHours()).slice(-2) + ":" +
      ("0" + currentDate.getMinutes()).slice(-2) + ":" +
      ("0" + currentDate.getSeconds()).slice(-2);

    console.log(formattedDate);

    try {
      const response = await loginUser(loginId, password, branchCode);

      if (response) {
        const data =
          response.data && response?.data && JSON.parse(response?.data);
          console.log(data)

        if (data) {
          localStorage.setItem(
            "session_key",
            data?.KCSRPT && data?.KCSRPT?.details[0]?.sessionkey
          );
          localStorage.setItem(
            "user_Id",
            data?.KCSRPT && data?.KCSRPT?.details[0]?.login.toLowerCase()
          );
          localStorage.setItem(
            "branch_code",
            data?.KCSRPT && data?.KCSRPT?.details[0]?.logbrchno
          );
          localStorage.setItem(
            "branch_name",
            data?.KCSRPT && data?.KCSRPT?.details[0]?.logbrchnm.toLowerCase()
          );
          localStorage.setItem(
            "login_time",
            formattedDate
          );
          NotificationManager.success("User Logged in Successfully");
          const isAuthenticated = true;
          localStorage.setItem("isAuthenticated", isAuthenticated);
          setShowErrorNotification(false);
          setHasErrorOccurred(false);
          navigate("/dashboard");
        }
      }
    } catch (err) {
      if (!hasErrorOccurred) {
        handleError(err);
        setShowErrorNotification(true);
        setHasErrorOccurred(true);
        setTimeout(() => {
          setHasErrorOccurred(false);
        }, 5000);
      }
    }
  };

  return (
    <Container fluid className="sectionWrapper">
      <Row>
        <KCSLogo className="navbar-Logo" />
      </Row>
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        <Col>
          <LoginSvg className="transcation-logo" />
        </Col>
        <Col className="loginForm">
          <div className="login-card mt-4">
            <h1 className="login-Title">{Constants.loginTitle}</h1>
            <Form>
              <Form.Group>
                <Form.Label>{Constants.branchLabelText}</Form.Label>
                <div className="input-row">
                  <Form.Control
                    type="text"
                    value={branchCode}
                    onChange={(e) => handleBranchCode(e)}
                    maxLength={6}
                  />
                  {showIcon && (
                    <CloseIcon
                      onClick={() => {
                        setShowIcon(false);
                        setBranchCode("");
                      }}
                    />
                  )}
                  {branchCodeError && (
                    <p className="error-text">{Constants.branchError}</p>
                  )}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>{Constants.loginId}</Form.Label>
                <div className="input-row">
                  <Form.Control
                    type="text"
                    value={loginId}
                    // pattern="/^[A-Za-z0-9 ]+$/"
                    // title=""
                    onChange={(e) => handleLoginId(e)}
                  />
                  {showLoginIcon && (
                    <CloseIcon
                      onClick={() => {
                        setShowLoginIcon(false);
                        setLoginId("");
                      }}
                    />
                  )}

                  {loginIdError && (
                    <p className="error-text">{Constants.loginIdError}</p>
                  )}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>{Constants.passText}</Form.Label>
                <div className="input-row">
                  <Form.Control
                    type={showPswIcon ? "text" : "password"}
                    value={password}
                    // pattern="^\d{5}$"
                    // title=""
                    onChange={(e) => handlePassword(e)}
                  />

                  <ToggleIcon onClick={() => setShowPswIcon(!showPswIcon)} />
                  {pswError && (
                    <p className="error-text">{Constants.pswError}</p>
                  )}
                </div>
              </Form.Group>

              <Form.Group className="check-row">
                <Form.Check
                  type="checkbox"
                  value={isChecked}
                  onChange={handleChange}
                />
                <p>
                  {" "}
                  {Constants.acceptText}{" "}
                  <Link>{Constants.confidentialityText}</Link>{" "}
                </p>
              </Form.Group>
              {termsError && (
                <p className="error-text">{Constants.termsErrorText}</p>
              )}
              {error && (
                <p className="error-text">{Constants.missingFieldError}</p>
              )}
              <Button
                variant="primary"
                className="logInBtn"
                onClick={goToDashboard}
              >
                {Constants.loginBtnText}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>

      <footer className="app-footer">
        <p className="footer-title">{Constants.footerText}</p>
      </footer>
    </Container>
  );
};

export default Login;
