import axios from "axios";

/**
 * The LoginUser function sends a PUT request to a login endpoint with the provided loginId, password,
 * and branchCode, and returns the response if the status is 200.
 * @param loginId - The loginId parameter is the user's login ID or username. It is used to identify
 * the user who is trying to log in.
 * @param passward - The parameter "passward" is likely a typo and should be corrected to "password".
 * It represents the user's password for authentication.
 * @param branchCode - The `branchCode` parameter is the code that represents the branch of the user
 * logging in. It is used to identify the specific branch within the system.
 * @returns a promise that resolves to the response object from the axios PUT request.
 */

export const loginUser = (loginId, passward, branchCode) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/login/`,
      {
        KCSRPT: {
          header: {
            apitype: "login",
          },
          details: {
            userid: loginId,
            userpass: passward,
            branch: branchCode,
            hostname: "medha",
            hostip: "192.168.1.1",
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

/**
 * The `LogOutUser` function sends a PUT request to a logout endpoint with session key, user ID, and
 * branch information, and returns the response if the status is 200.
 * @param sessionkey - The sessionkey parameter is a unique identifier that represents the user's
 * session. It is used to authenticate and authorize the user during the logout process.
 * @param userid - The `userid` parameter is the unique identifier of the user who is logging out. It
 * is used to identify the user in the logout request.
 * @param branch - The "branch" parameter refers to the branch of the user who is logging out. It is
 * used to specify the specific branch from which the user is logging out.
 * @returns a promise that resolves to the response object from the axios PUT request.
 */

export const logOutUser = (sessionkey, userid, branch) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/logout/`,
      {
        KCSRPT: {
          header: {
            apitype: "logout",
            sessionkey: sessionkey,
          },
          details: {
            userid: userid,
            branch: branch,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`, // Use sessionkey as a bearer token here if needed
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response.status === 200) {
        return response;
      }
    });
};

export const getASparameters = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  multipleof
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rpt/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: multipleof,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


/**
 * The function `get_AS_level1_all_parameters` makes an API call to retrieve data based on various
 * parameters and returns the response.
 * @param apitype - The `apitype` parameter is used to specify the type of API being called. It could
 * be a string value like "AS18RPT" or "AS19RPT" depending on the API you are using.
 * @param sessionkey - The sessionkey parameter is used to authenticate the user session. It is a
 * unique identifier that is generated when the user logs in or authenticates with the system. This
 * sessionkey is then used to authorize the user's requests and ensure that only authenticated users
 * can access the API.
 * @param userid - The `userid` parameter is used to identify the user for whom the report is being
 * generated. It is typically a unique identifier assigned to each user in the system.
 * @param date - The "date" parameter represents the business date for which the report is being
 * generated.
 * @param rptdate - The `rptdate` parameter is used to specify the report date for which you want to
 * retrieve data. It should be a valid date value.
 * @param reporttype - The "reporttype" parameter is used to specify the type of report you want to
 * generate. It could be a financial report, sales report, inventory report, etc. The specific values
 * for this parameter would depend on the available report types in your system.
 * @param reportsubtype - The parameter "reportsubtype" is used to specify the subtype of the report.
 * It is a value that helps to further categorize the report type.
 * @param income - The "income" parameter is used to specify the income type for the report. It is
 * passed as the value for the "as18head" property in the request payload.
 * @param rpttype - The `rpttype` parameter is used to specify the type of report. It is a string value
 * that determines the specific report to be generated.
 * @param amount - The `amount` parameter represents the value of the column in the report.
 * @param year - The "year" parameter represents the financial year for which the report is being
 * generated. It is used to specify the specific year for which the report data should be fetched.
 * @returns a promise that resolves to the response object from the axios GET request.
 */

export const get_AS_level1_parameters = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  as18head,
  rptname,
  rpttype,
  colvalue,
  finyear
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rptleve1view/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: "1",
            as18head: as18head,
            rptname: rptname,
            rpttype: rpttype,
            colvalue: colvalue,
            finyear: finyear,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

/**
 * The function `get_AS_level2_parameters` makes a POST request to an API endpoint with specific
 * parameters and returns the response if the status is 200.
 * @param apitype - The `apitype` parameter is used to specify the type of API being called. It could
 * be a string value like "AS18RPT" or any other value that is expected by the API.
 * @param sessionkey - The sessionkey parameter is used to authenticate the user's session. It is a
 * unique identifier that is generated when the user logs in and is used to verify the user's identity
 * for subsequent API requests.
 * @param userid - The `userid` parameter is the unique identifier of the user making the API request.
 * @param busdt - The `busdt` parameter represents the business date. It is used to specify the date
 * for which the report is being generated.
 * @param rptdate - The date of the report.
 * @param reporttype - The `reporttype` parameter is used to specify the type of report you want to
 * generate. It could be a financial report, sales report, inventory report, etc. The specific values
 * for `reporttype` would depend on the API you are using and the available options provided by that
 * API.
 * @param reportsubtype - The parameter "reportsubtype" is used to specify the subtype of the report.
 * It is a value that helps to further categorize the report type.
 * @param month - The "month" parameter is used to specify the month for which the report is being
 * generated. It is typically a numerical value representing the month, such as 1 for January, 2 for
 * February, and so on.
 * @param rptname - The `rptname` parameter is used to specify the name of the report. It is a string
 * value that identifies the specific report you want to generate.
 * @param as18mainhead - The parameter `as18mainhead` is used to specify the main heading for the AS18
 * report. It is a string value that represents the main category or section of the report.
 * @param as18subhead - The parameter "as18subhead" is used to specify the subhead for the AS18 report.
 * It is a string value that represents the subhead of the report.
 * @param colname - The `colname` parameter is used to specify the column name for the report. It is a
 * string value that represents the name of the column in the report.
 * @param totalmonth - The parameter `totalmonth` represents the total number of months for which the
 * report is generated.
 * @returns a promise that resolves to the response object from the axios post request.
 */

export const get_AS_level2_parameters = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  month,
  rptname,
  as18mainhead,
  as18subhead,
  colname,
  colvalue,
  totalmonth
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rptLevel2View/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: "1",
            month: month,
            rptname: rptname,
            as18mainhead: as18mainhead,
            as18subhead: as18subhead,
            colname: colname,
            colvalue: colvalue,
            totalmonth: totalmonth,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getBoardNoteLevel2 = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  month,
  as18boardnote,
  rptname,
  colname,
  colvalue,
  totalmonth
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rptlevel2view/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: "1",
            month: month,
            as18boardnote: as18boardnote,
            rptname: rptname,
            colname: colname,
            colvalue: colvalue,
            totalmonth: totalmonth,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};



/**
 * The `get_significant` function sends a POST request to an API endpoint with specific parameters and
 * returns the response if the status is 200.
 * @param apitype - The `apitype` parameter is the type of API you are using. It could be a string
 * value like "REST" or "SOAP" depending on the API you are integrating with.
 * @param sessionkey - The sessionkey parameter is a unique identifier that is used to authenticate the
 * user's session. It is typically generated when the user logs in or authenticates with the system.
 * @param userid - The `userid` parameter is the unique identifier of the user making the request. It
 * is used to authenticate and authorize the user to access the API.
 * @param busdt - The `busdt` parameter represents the business date for which you want to retrieve the
 * significant report.
 * @param rptdate - The `rptdate` parameter is the date for which you want to retrieve the significant
 * report. It should be in a specific format, such as "YYYY-MM-DD".
 * @param reporttype - The `reporttype` parameter is used to specify the type of report you want to
 * retrieve. It is a string value that determines the format or category of the report.
 * @returns a promise that resolves to the response object from the axios post request.
 */

export const get_significant = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  multipleof
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18significantrpt/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: multipleof,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

/**
 * The function `getIntermidiateReport` makes a POST request to an API endpoint with specific
 * parameters and returns the response if the status is 200.
 * @param apitype - The `apitype` parameter is the type of API you are using. It could be a string
 * value like "REST" or "SOAP" depending on the API you are integrating with.
 * @param sessionkey - A unique key that identifies the user session.
 * @param userid - The user ID of the user making the API request.
 * @param busdt - The `busdt` parameter represents the business date. It is a date value that specifies
 * the date for which the report is being generated.
 * @param rptdate - The date of the report.
 * @param reporttype - The `reporttype` parameter is used to specify the type of report you want to
 * generate. It could be a financial report, sales report, inventory report, etc. The specific values
 * for `reporttype` would depend on the API you are using and its documentation.
 * @param reportsubtype - The parameter "reportsubtype" is used to specify the subtype of the report.
 * It is a string value that determines the specific type or category of the report being generated.
 * @param as18head - The `as18head` parameter is used to specify the AS18 head for the report. It is a
 * string value that represents the specific AS18 head for which the report is being generated.
 * @param rptname - The `rptname` parameter is used to specify the name of the report. It is a string
 * value that identifies the specific report you want to generate.
 * @param rpttype - The `rpttype` parameter is used to specify the type of report you want to generate.
 * It could be a financial report, sales report, inventory report, etc. The specific values for
 * `rpttype` would depend on the API you are using and the available report types it supports.
 * @param colvalue - The parameter "colvalue" is used to specify the column value for the report. It is
 * a value that determines which columns to include in the report. The specific format or meaning of
 * this value may depend on the API or backend system you are using.
 * @param finyear - The `finyear` parameter represents the financial year for which the intermediate
 * report is being generated.
 * @returns a promise that resolves to the response object from the axios post request.
 */

export const getIntermidiateReport = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  as18head,
  rptname,
  rpttype,
  colvalue,
  finyear
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rpt/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: "1",
            as18head: as18head,
            rptname: rptname,
            rpttype: rpttype,
            colvalue: colvalue,
            finyear: finyear,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

/**
 * The function `getSebiLevel1params` makes a POST request to an API endpoint with specific parameters
 * and returns the response if the status is 200.
 * @param apitype - The `apitype` parameter is used to specify the type of API being called.
 * @param sessionkey - The session key is a unique identifier that is generated when a user logs in to
 * the system. It is used to authenticate the user and ensure that only authorized users can access the
 * API.
 * @param userid - The `userid` parameter is the unique identifier of the user making the API request.
 * It is used to authenticate and authorize the user's access to the requested data.
 * @param busdt - The `busdt` parameter represents the business date for which the report is being
 * generated.
 * @param rptdate - The date of the report.
 * @param reporttype - The reporttype parameter is used to specify the type of report you want to
 * retrieve. It could be a financial report, a performance report, or any other type of report defined
 * in your system.
 * @param reportsubtype - The parameter "reportsubtype" is used to specify the subtype of the report.
 * It is a string value that helps to categorize the report further.
 * @param sebimainhead - The parameter "sebimainhead" is used to specify the main heading for the SEBI
 * Level 1 report. SEBI stands for Securities and Exchange Board of India, and Level 1 refers to the
 * first level of reporting for SEBI regulations. The main heading typically represents a specific
 * category
 * @param sebisubhead - The parameter "sebisubhead" is used to specify the subhead of the SEBI report.
 * It is a part of the request payload sent to the API endpoint.
 * @param rptname - The `rptname` parameter is used to specify the name of the report you want to
 * retrieve.
 * @returns a promise that resolves to the response object from the axios post request.
 */

export const getSebiLevel1params = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  sebimainhead,
  sebisubhead,
  rptname,
  colvalue
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rptleve1view/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: "1",
            sebimainhead: sebimainhead,
            sebisubhead: sebisubhead,
            rptname: rptname,
            colvalue: colvalue,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getSebiLevel2params = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  month,
  rptname,
  sebimainhead,
  sebisubhead,
  colname,
  colvalue,
  totalmonth
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rptLevel2View/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: "1",
            month: month,
            rptname: rptname,
            sebimainhead: sebimainhead,
            sebisubhead: sebisubhead,
            colname: colname,
            colvalue: colvalue,
            totalmonth: totalmonth,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

/**
 * The function `getSebiLevel1Intermidiate` makes a POST request to an API endpoint with specific
 * parameters and returns the response if the status is 200.
 * @param apitype - The `apitype` parameter is the type of API you want to call. It could be "GET" or
 * "POST" depending on the API endpoint you are using.
 * @param sessionkey - A unique key that identifies the user's session.
 * @param userid - The user ID of the user making the API request.
 * @param busdt - The `busdt` parameter represents the business date. It is a date value that specifies
 * the date for which the report is being generated.
 * @param rptdate - The date of the report.
 * @param reporttype - The reporttype parameter is used to specify the type of report you want to
 * retrieve. It could be a financial report, sales report, or any other type of report that is
 * available in the system.
 * @param reportsubtype - The parameter "reportsubtype" is used to specify the subtype of the report.
 * It is a string value that determines the specific category or type of the report.
 * @param month - The "month" parameter is used to specify the month for which the report is being
 * generated. It can be a string or a number representing the month.
 * @param sebimainhead - The parameter `sebimainhead` is used to specify the main heading for the SEBI
 * Level 1 Intermediate report. It is a string value that represents the main category or section of
 * the report.
 * @param sebisubhead - The parameter `sebisubhead` is used to specify the subhead under the main head
 * in the SEBI Level 1 report. It is a string value that represents the subhead of the report.
 * @param rptname - The `rptname` parameter is used to specify the name of the report. It is a string
 * value that identifies the specific report you want to retrieve.
 * @param colvalue - The `colvalue` parameter is used to specify the column value for the report. It is
 * a value that determines which specific column of data you want to retrieve from the report.
 * @returns a promise that resolves to the response object from the axios post request.
 */

export const getSebiLevel1Intermidiate = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  multipleof,
  month,
  sebimainhead,
  sebisubhead,
  rptname,
  colvalue
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rptleve1view/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: multipleof,
            month: month,
            sebimainhead: sebimainhead,
            sebisubhead: sebisubhead,
            rptname: rptname,
            colvalue: colvalue,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getNotASLevel1Params = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  reporttype,
  reportsubtype,
  multipleof,
  month,
  txndescription,
  rptname,
  colvalue
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/as18rptleve1view/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            reporttype: reporttype,
            reportsubtype: reportsubtype,
            multipleof: multipleof,
            month: month,
            txndescription: txndescription,
            rptname: rptname,
            colvalue: colvalue,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getDashboard = (
  apitype,
  sessionkey,
  userid,
  busdt,
  finyear,
  rptdate,
  prctype,
  imptype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/processlist/`,
      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            finyear: finyear,
            rptdate: rptdate,
            prctype: prctype,
            imptype: imptype,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

/**
 * The function `getUploadFiles` is a JavaScript function that sends a file upload request to a server
 * using axios and returns a promise that resolves with the response if the status is 200.
 * @param sessionkey - The session key is a unique identifier that is used to authenticate the user's
 * session. It is typically generated when the user logs in and is used to authorize subsequent
 * requests.
 * @param login - The login parameter is the user's login information. It is used to authenticate the
 * user and determine their access privileges.
 * @param busdt - The `busdt` parameter represents the business date. It is used to specify the date
 * for which the file is being uploaded.
 * @param branchcode - The branch code is a unique identifier for a specific branch of an organization.
 * It is used to differentiate between different branches within the same organization.
 * @param ProcessName - The ProcessName parameter is the name of the process for which the file is
 * being uploaded. It is used to identify the specific process in the backend system.
 * @param ProcessType - The ProcessType parameter is used to specify the type of process for which the
 * file is being uploaded. It could be a string value representing the type of process, such as "data
 * processing", "report generation", etc.
 * @param rptdate - The `rptdate` parameter represents the report date. It is used to specify the date
 * of the report being uploaded.
 * @param hostname - The hostname parameter is the name of the host or server where the file is being
 * uploaded from.
 * @param ipaddress - The `ipaddress` parameter is the IP address of the device from which the file is
 * being uploaded.
 * @param file - The file parameter is the file that you want to upload. It should be a File object.
 * @returns a promise that resolves to the response object from the axios post request.
 */

export const getUploadFiles = (
  sessionkey,
  login,
  busdt,
  branchcode,
  ProcessName,
  ProcessType,
  rptdate,
  hostname,
  ipaddress,
  file
) => {
  const formData = new FormData();
  formData.append("login", login);

  if (file) {
    formData.append("busdt", busdt);
    formData.append("branchcode", branchcode);
    formData.append("ProcessName", ProcessName);
    formData.append("ProcessType", ProcessType);
    formData.append("rptdate", rptdate);
    formData.append("hostname", hostname);
    formData.append("ipaddress", ipaddress);
    formData.append("file", file);
  }
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/uploadfile/`,

      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getAuthorizedUploadFiles = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  prctype,
  filetype,
  action,
  type
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/importmasterauth/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            prctype: prctype,
            filetype: filetype,
            action: action,
            type: type,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getprocessAuthorizedUploadFiles = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype,
  filesubtype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/basefileauth/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
            filesubtype: filesubtype
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const cancleprocessAuthorizeFiles = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype,
  filesubtype,
  option,
  cancelrsn
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/basefilecancauth/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
            filesubtype: filesubtype,
            option: option,
            cancelrsn: cancelrsn
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getInfoDisplay = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype,
  filesubtype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/infodisplay/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
            filesubtype: filesubtype,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getLogFiles = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype,
  filesubtype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/downloadlog/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
            filesubtype: filesubtype,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getDownloadFiles = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/downloadfile/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/vnd.ms-excel",
          "Content-Disposition": 'attachment; filename="downloadedFile.xls"',
          Authorization: `Bearer ${sessionkey}`,
        },
        responseType: "blob", // Ensure responseType is set to "blob" for binary data

      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getReportingDateList = (
  apitype,
  sessionkey,
  userid,
  busdt,
  action,
  rptdate,
  deletersn,
  closersn
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/rptdatemst/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            action: action,
            rptdate: rptdate,
            deletersn: deletersn,
            closersn: closersn,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getFiletyperptdate = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/filetyperptdate/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getReportingDate = (
  apitype,
  sessionkey,
  userid,
  busdt,
  action,
  rptdate,
  deletersn,
  closersn
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/rptdatemaster/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            action: action,
            rptdate: rptdate,
            deletersn: deletersn,
            closersn: closersn,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getFinanicalYearReport = (
  apitype,
  sessionkey,
  userid,
  busdt
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/financialyear/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};



export const getFinanicalYeardates = (
  apitype,
  sessionkey,
  userid,
  busdt,
  finyear
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/reportingdate/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            finyear: finyear
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getFile_Type = (
  apitype,
  sessionkey,
  userid,
  busdt,
  importtype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getfiletypedtls/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            importtype: importtype
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getFileTypeDescription = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype,
  description,
  filename,
  sheetname
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/filetypedescchg/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
            description: description,
            filename: filename,
            sheetname: sheetname
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getReimportReport = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/auditrpt/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getReimbursementReport = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/reimbursemis/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getQoQReport = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype,
  filesubtype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/qtronqtrrpt/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
            filesubtype: filesubtype,
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getTrendFrequency = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getFrequency/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getASFiletype = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getreportingtype/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};



export const getASMultipleof = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getmultipleoff/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getASFileSubtype = (
  apitype,
  sessionkey,
  userid,
  busdt,
  reporttype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getreportingsubtype/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            reporttype: reporttype
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getTrendDeviation = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getdeviation/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getRptName = (
  apitype,
  sessionkey,
  userid,
  busdt,
  fromdate,
  todate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getrpname/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            fromdate: fromdate,
            todate: todate
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getTransactionType = (
  apitype,
  sessionkey,
  userid,
  busdt,
  fromdate,
  todate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getTransactionType/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            fromdate: fromdate,
            todate: todate
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getTransactionSubType = (
  apitype,
  sessionkey,
  userid,
  busdt,
  fromdate,
  todate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/getsubtransactiontype/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            fromdate: fromdate,
            todate: todate
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getTrendAnalysis = (
  apitype,
  sessionkey,
  userid,
  busdt,
  fromdate,
  todate,
  frequency,
  rpname,
  txntype,
  subtxntype,
  devtype,
  devoperator,
  devvalue
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/trendanaysisrpt/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            fromdate: fromdate,
            todate: todate,
            frequency: frequency,
            rpname: rpname,
            txntype: txntype,
            subtxntype: subtxntype,
            devtype: devtype,
            devoperator: devoperator,
            devvalue: devvalue
          }
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};




export const getFreezeReport = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  option,
  reason
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/freezeunfreeze/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            option: option,
            reason: reason
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};



export const getDashboardDate = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/dashboardrptdate/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getDashboardTotalClick = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  type,
  filetype,
  filesubtype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/dashboardtotalclick/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            type: type,
            filetype: filetype,
            filesubtype: filesubtype
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const getDashboardExceptionCount = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  type,
  filetype,
  filesubtype,
  excepmark
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/exceptionshow/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            type: type,
            filetype: filetype,
            filesubtype: filesubtype,
            excepmark: excepmark
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getTotalcountContentclick = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype,
  columnname,
  columnvalue
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/rptmstcontentclick/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
            columnname: columnname,
            columnvalue: columnvalue
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getExceptionIgnore = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  type,
  filetype,
  filesubtype,
  reason,
  idcollist,
  excepmark
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/exceptionignore/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            type: type,
            filetype: filetype,
            filesubtype: filesubtype,
            reason: reason,
            idcollist: idcollist,
            excepmark: excepmark
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const getExceptionVerifyOn = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  type,
  filetype
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/verifyon/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            type: type,
            filetype: filetype
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const updateException = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  type,
  filetype,
  filesubtype,
  status,
  tagging,
  optstatus,
  excpupdate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/fileviewgetdata/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            type: type,
            filetype: filetype,
            filesubtype: filesubtype,
            status: status,
            tagging: tagging,
            optstatus: optstatus,
            excpupdate: excpupdate
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const verifyOnData = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  type,
  filetype,
  filesubtype,
  reason,
  idcollist,
  verifyon,
  verifyvalue,
  resultyesno
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/exceptionupdate/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            type: type,
            filetype: filetype,
            filesubtype: filesubtype,
            reason: reason,
            idcollist: idcollist,
            verifyon: verifyon,
            verifyvalue: verifyvalue,
            resultyesno: resultyesno
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};


export const verifyonValueValidate = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  type,
  filetype,
  verifyon,
  verifyvalue
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/verifyonvaluevalidate/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            type: type,
            filetype: filetype,
            verifyon: verifyon,
            verifyvalue: verifyvalue
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

export const exceptionContentClick = (
  apitype,
  sessionkey,
  userid,
  busdt,
  rptdate,
  filetype,
  columnname,
  columnvalue,
  colvalue
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/processcontentclick/`,

      {
        KCSRPT: {
          header: {
            apitype: apitype,
            sessionkey: sessionkey,
            userid: userid,
            busdt: busdt,
          },
          details: {
            rptdate: rptdate,
            filetype: filetype,
            columnname: columnname,
            columnvalue: columnvalue,
            colvalue: colvalue
          },
        },
      },
      {
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionkey}`,
        },
      }
    )
    .then((response) => {
      // Handle the response here
      if (response["status"] === 200) {
        return response;
      }
    });
};

