import "./settings.css";
import Calendar from "react-calendar";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as DeleteIcon } from "../../assets/images/Delete.svg";
import { ReactComponent as ReportIcon } from "../../assets/images/Calendar.svg";
import ActionOverlay from "../../components/Overlay/ActionModal/ActionOverlay";
import { BackBtn } from "../../components/Buttons/CustomButton";
import { getReportingDate, getReportingDateList } from "../../Api";
import { NotificationManager } from "react-notifications";
import { formatMonthDate } from "../../utils";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const ReportingDate = () => {
  const [calendar, setCalendar] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [date] = useState();
  const [, setFinancialYear] = useState(null);
  const [, setFormateDate] = useState("");
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [, setReporteDate] = useState("");
  const [getReportingDetails, setGetReportingDetails] = useState([]);
  const [get_date, setGet_date] = useState("");
  const [getSelectedDate, setGetSelectedDate] = useState("");
  const [selected_date, setSelectedDate] = useState("")
  const [loader, setLoader] = useState(true)

  /**
   * showCalendar :: View Calendar
   */
  const showCalendar = () => {
    setCalendar(!calendar);
  };

  /**
   * The function `openDeleteModal` checks if the `enbdelete` property of an object in the
   * `getReportingDetails.details` array is equal to "Y" and if so, sets the `get_date` state variable
   * to the value of the "Reporting Date" property of that object and shows the delete modal.
   * @param index - The `index` parameter is the index of the item in the `getReportingDetails.details`
   * array that you want to open the delete modal for.
   */

  const openDeleteModal = (index) => {
    if (getReportingDetails.details[index].enbdelete === "Y") {
      setGet_date(getReportingDetails.details[index]["Reporting Date"]);
      setShowDeleteModal(true);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");


      let getFinancialYear = localStorage.getItem("Financial_year");
      setFinancialYear(getFinancialYear);

      if (userDate) {
        setReporteDate(userDate);
        await getReportingDateDetails(getSessionId, getUserId, getdate);
      } else {
        setReporteDate(getdate);
        await getReportingDateDetails(getSessionId, getUserId, getdate);
      }

    };
    checkAuth();
  }, [selected_date]);

  useEffect(() => {
    let getDate = localStorage.getItem("reporting_date");
    setFormateDate(getDate);
    findFinancialYear();
  }, [getSelectedDate]);

  /**
   * The function `findFinancialYear` calculates the financial year based on a selected date and stores
   * the result in local storage.
   */

  const findFinancialYear = () => {
    const inputDetails = new Date(getSelectedDate);
    const financialYearStart = new Date(inputDetails.getFullYear(), -1, 2); // April 1
    const financialYearEnd = new Date(inputDetails.getFullYear() + 1, 2, 31); // March 31
    const financialYearmonth = new Date(inputDetails.getMonth(), 3, 1); // April 1
    if (
      inputDetails >= financialYearStart &&
      inputDetails <= financialYearEnd
    ) {
      setFinancialYear(inputDetails.getFullYear());
      localStorage.setItem(
        "Financial_month",
        "0" + financialYearmonth.getMonth()
      );
      localStorage.setItem("Financial_year", inputDetails.getFullYear());
    } else {
      setFinancialYear(null);
    }
  };

  /**
   * The function `getReportingDateDetails` retrieves reporting information based on the session key, user ID, and
   * report date, and handles any error messages that may occur.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current
   * session or user session in the application. It is used to authenticate and authorize the user's
   * actions and access to resources.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the reporting
   * information is being fetched.
   * @param reportDate - The `reportDate` parameter is the date for which the reporting information is
   * requested. It is used as an input to the `getReportingDate` function to retrieve the reporting
   * details for that specific date.
   */

  const getReportingDateDetails = (sessionKey, userId, reportDate) => {
    setLoader(true)
    getReportingDateList(
      "rptdatemst",
      sessionKey,
      userId,
      reportDate,
      "LIST",
      reportDate,
      "",
      ""
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
        setLoader(false)
        NotificationManager.error(response?.data?.KCSRPT?.details[0]?.ErrorMsg);
      } else {
        setLoader(false)
        setGetReportingDetails(response?.data?.KCSRPT);
      }
    });
  };

  /**
   * The `onChangeDate` function updates the reporting date, displays an error notification if there is a
   * response error, retrieves the reporting date details, sets the selected date, saves the reporting
   * date in local storage, and hides the calendar.
   * @param date - The `date` parameter is the selected date that is passed to the `onChangeDate`
   * function.
   */

  const onChangeDate = (date) => {
    getReportingDate(
      "rptdatemaster",
      sessionKey,
      userId,
      formatMonthDate(date),
      "New",
      formatMonthDate(date),
      "",
      ""
    ).then((response) => {
      if (response) {
        if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0]?.ErrorMsg
          );
        }
        getReportingDateDetails(sessionKey, userId, formatMonthDate(date));
      }
    });

    setGetSelectedDate(date);

    setCalendar(false);
  };

  /**
   * The function `showAuthorizedBy` checks if a certain condition is met and then performs some actions
   * based on that condition.
   * @param index - The `index` parameter is used to specify the index of an item in an array. In this
   * case, it is likely used to access a specific item in the `getReportingDetails.details` array.
   */

  const showAuthorizedBy = (index) => {
    if (getReportingDetails?.details[index].enbauthorise === "Y" || getReportingDetails?.details[index].enbclosauth === "Y") {
      setLoader(true)
      getReportingDate(
        "rptdatemaster",
        sessionKey,
        userId,
        getReportingDetails?.details[index]["Reporting Date"],
        "authorise",
        getReportingDetails?.details[index]["Reporting Date"],
        "",
        ""
      ).then((response) => {
        if (response) {
          setLoader(false)
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0]?.ErrorMsg
          );
          getReportingDateDetails(
            sessionKey,
            userId,
            getReportingDetails?.details[index]["Reporting Date"]
          );
        }
      });
    }
  };

  /**
   * The function `openCloseModal` checks if a specific condition is met and then performs some actions
   * based on that condition.
   * @param index - The `index` parameter is used to specify the index of an item in the
   * `getReportingDetails.details` array. It is used to access specific data within the array.
   */

  const openCloseModal = (index) => {
    if (getReportingDetails.details[index].enbclose === "Y") {
      setLoader(true)
      getReportingDate(
        "rptdatemaster",
        sessionKey,
        userId,
        getReportingDetails?.details[index]["Reporting Date"],
        // "Close-Authorise",
        "close",
        getReportingDetails?.details[index]["Reporting Date"],
        "",
        "test"
      ).then((response) => {
        if (response) {
          setLoader(false)
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0]?.ErrorMsg
          );
          getReportingDateDetails(
            sessionKey,
            userId,
            getReportingDetails?.details[index]["Reporting Date"]
          );
        }
      });
    }
  };

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header">{Constants.reporting_date}</h1>
          <Header setGet_date={setSelectedDate} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left calender-row">
              <h1>{Constants.reporting_date} : </h1>
              <div className="date-row">
                <ReportIcon onClick={showCalendar} />
                <input
                  type="text"
                  placeholder="Select Date"
                  value={getSelectedDate && formatMonthDate(getSelectedDate)}
                />
                {calendar && (
                  <div className="reporting-calendar">
                    <Calendar onChange={onChangeDate} value={date} />
                  </div>
                )}
              </div>
            </div>
            <div className="content-header-right">
              <GernerateCSVFile
                generateCSV={getReportingDetails}
                reportName={"ReportingDate"}
              />
              <BackBtn />
            </div>
          </div>
          {
            loader === false ?
              <div className="content-table main-report-table ">
                <table className="table">
                  <thead className="dashboard_content">
                    <tr>
                      <th>{Constants.reporting_date}</th>
                      <th>{Constants.enteredBy}</th>
                      <th>{Constants.enteredOn}</th>
                      <th>{Constants.status}</th>
                      {/* {showAuthorized && (
                    <>
                      {" "} */}
                      <th>{Constants.authorisedBy}</th>
                      <th>{Constants.authorisedOn}</th>{" "}
                      {/* </>
                  )} */}
                      <th>
                        {Constants.deleteAuth}{" "}
                        <span>
                          <DeleteIcon />
                        </span>{" "}
                      </th>
                      <th>{Constants.authorise}</th>
                      <th>{Constants.cancel}</th>
                    </tr>
                  </thead>
                  <tbody className="reporting_date">
                    {getReportingDetails?.details &&
                      getReportingDetails?.details.map((item, index) => {
                        return (
                          <tr key={item[index]}>
                            <td>
                              <b>{item["Reporting Date"]}</b>
                            </td>
                            <td>
                              <b>{item["Entered By"]}</b>
                            </td>
                            <td>
                              <b>{item["Entered On"]}</b>
                            </td>
                            <td>
                              <b>{item.Status}</b>
                            </td>

                            <td>
                              <b>{item["Authorised By"]}</b>
                            </td>
                            <td>
                              <b>{item["Authorised On"]}</b>
                            </td>

                            <td
                              className={
                                item.enbdelete === "Y"
                                  ? "log-link authorize"
                                  : "log-link delete"
                              }
                              title="Delete"
                              onClick={() => openDeleteModal(index)}
                            >
                              {item.enbdelete}
                            </td>

                            <td
                              className={
                                item.enbauthorise === "Y" ||
                                item.enbclosauth === "Y"
                                  ? "log-link authorize"
                                  : "log-link delete"
                              }
                              title="Auhorization"
                              onClick={() => showAuthorizedBy(index)}
                            >
                              {item.enbclosauth === "Y" ? item.enbclosauth : item.enbauthorise}
                            </td>
                            <td
                              className={
                                item.enbclose === "Y"
                                  ? "log-link authorize"
                                  : "log-link delete"
                              }
                              title="Close"
                              onClick={() => openCloseModal(index)}
                            >
                              {item.enbclose}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              : <div className="spinner-loader">
                <div class="spinner-border d-flex m-auto" role="status"></div>
              </div>
          }
        </div>

        <UserDetailsFooter/>
        
        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
        <ActionOverlay
          show={showDeleteModal}
          get_date={get_date}
          setGetReportingDetails={setGetReportingDetails}
          onHide={() => setShowDeleteModal(false)}
          text="Delete"
        />
        <ActionOverlay
          show={showCloseModal}
          get_date={get_date}
          setGetReportingDetails={setGetReportingDetails}
          onHide={() => setShowCloseModal(false)}
          text="Close"
        />
      </div>
    </MainLayout>
  );
};

export default ReportingDate;
