import React from 'react'
import Header from '../../components/Header/Header'
import MainLayout from '../../components/Layout/MainLayout'
import * as Constants from '../../constants/stringConstants'
import LayoutBtn from '../../components/LayoutBtn/LayoutBtn'
import { ReactComponent as ReportIcon } from '../../assets/images/Calendar.svg'
import { ReactComponent as InputIcon } from '../../assets/images/Bookmark.svg'




const Settings = () => {
    return (
        <MainLayout>
            <div className='layoutWrapper'>
                <div className='header-wrapper'>
                    <h1 className='header'> {Constants.parameterTitle} </h1>
                    <Header />
                </div>
                <div>
                    <LayoutBtn Icon={<ReportIcon />} title="Reporting Date" path="reporting-date" />
                    <LayoutBtn Icon={<InputIcon />} title="Input File Name Change" path="file-change" />
                </div>

            </div>
        </MainLayout>
    )

}
export default Settings
