import "./SEBI.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as FolderIcon } from "../../assets/images/Folder.svg";
import {
  getASFileSubtype,
  getASFiletype,
  getASMultipleof,
  getASparameters,
  getReportingDateList
} from "../../Api";
import { NotificationManager } from "react-notifications";
import { handleError } from "../../Services/ErrorService";
import ResolvedApi from "../../components/api/ResolvedApi";
import { ReactComponent as TypeIcon } from "../../assets/images/Category.svg";
import { ReactComponent as ScanIcon } from "../../assets/images/Scan.svg";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const SEBIInterReports = () => {
  const navigate = useNavigate();
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [sebigetReports, setSebigetReports] = useState([]);
  const [reportDate, setReporteDate] = useState("");
  const [heading, setHeading] = useState();
  const [reportingDate, setReportingDate] = useState([]);
  const [getFileType, setGetFileType] = useState([]);
  const [getFileSubType, setGetFileSubType] = useState([]);
  const [getMultipleof, setGetMultipleof] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedfile, setSelectedfile] = useState("");
  const [selectedSubfile, setSelectedSubfile] = useState("");
  const [selectedMultiples, setSelectedMultiples] = useState("");
  const [summary, setSummary] = useState([])
  const [file_type, setFile_type] = useState("false");
  const [sub_type, setSub_type] = useState("false");
  const [get_date, setGet_date] = useState("");
  const [loader, setLoader] = useState(true)

  /* The `useEffect` hook is used to perform side effects in functional components. In this case, the
      `useEffect` hook is used to retrieve session key, user ID, and branch code from the local storage
      and set them as state variables (`sessionKey`, `userId`, `branchCode`). */

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");


      if (userDate) {
        setReporteDate(userDate);
        await get_sebi_intermidiate_parameters(getSessionId, getUserId, userDate)

        await getReportingList(getSessionId, getUserId, userDate);

        await getReportingMultipleOf(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        await get_sebi_intermidiate_parameters(getSessionId, getUserId, getdate)

        await getReportingList(getSessionId, getUserId, getdate);

        await getReportingMultipleOf(getSessionId, getUserId, getdate);
      }
    };
    checkAuth();
  }, [get_date]);

  const getReportingList = (sessionKey, userId, reportDate) => {
    getReportingDateList(
      "rptdatemst",
      sessionKey,
      userId,
      reportDate,
      "LIST",
      "",
      "",
      ""
    ).then((response) => {
      if (response) {
        setReportingDate(response.data.KCSRPT);
      }
    });
  };

  const getSelectedDate = (index) => {
    setSelectedDate(reportingDate.details[index]["Reporting Date"]);
    getReportingDateDeatils(reportingDate.details[index]["Reporting Date"]);
  };

  const getReportingMultipleOf = (sessionKey, userId, reportDate) => {
    getASMultipleof(
      "multipleoff",
      sessionKey,
      userId,
      reportDate,
      reportDate
    ).then((response) => {
      if (response) {
        setGetMultipleof(response.data.KCSRPT);
      }
    });
  };

  const getSelectedMultipes = (index) => {
    setSelectedMultiples(getMultipleof.details[index].Pcode1);
  };

  const getReportingDateDeatils = (selectedDate) => {
    getASFiletype(
      "reportingtype",
      sessionKey,
      userId,
      reportDate,
      selectedDate
    ).then((response) => {
      if (response) {
        setGetFileType(response.data.KCSRPT);
      }
    });
  };

  const getSelectedFile = (index) => {
    setFile_type(true);
    setSub_type(false);
    setSelectedfile(getFileType?.details[index]?.Pname);
    getReportingFiletype(getFileType?.details[index]?.Pname);
  };

  const getReportingFiletype = (file) => {
    getASFileSubtype(
      "reportingsubtype",
      sessionKey,
      userId,
      reportDate,
      file
    ).then((response) => {
      if (response) {
        setGetFileSubType(response.data.KCSRPT);
      }
    });
  };

  const getSelectedSubFile = (index) => {
    setSub_type(true);
    setFile_type(false);
    setSelectedSubfile(getFileSubType.details[index].pcode1);
  };

  const [, setIsProcess] = useState("false")

  /**
   * The function `get_sebi_parameters` retrieves SEBI intermediary report parameters using the provided
   * session key, user ID, and report date.
   * @param sessionKey - The sessionKey is a unique identifier that represents the current session or
   * user session in the application. It is used to authenticate and authorize the user's access to
   * certain resources or functionalities.
   * @param userId - The `userId` parameter is used to identify the user for whom the SEBI parameters are
   * being fetched. It is likely a unique identifier assigned to each user in the system.
   * @param reportDate - The `reportDate` parameter is a date that is used to specify the date of the
   * report. It is passed as an argument to the `get_sebi_parameters` function.
   */

  const get_sebi_parameters = () => {
    setIsProcess(true)
    setLoader(true)
    getASparameters(
      "auditrpt",
      sessionKey,
      userId,
      reportDate,
      selectedDate || reportDate,
      selectedfile || "SEBI",
      selectedSubfile || "SEBI INTERMEDIARY REPORT",
      selectedMultiples || "1"
    )
      .then((response) => {
        if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
          setLoader(false)
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0].ErrorMsg
          );
        } else {
          setLoader(false)
          setSebigetReports(response.data.KCSRPT);
          setSummary(response?.data?.KCSRPT.summary)
          let tableHeading = Object.keys(response?.data?.KCSRPT?.details[0]);
          setHeading(tableHeading);
        }
      })
      .catch((err) => {
        setLoader(false)
        handleError(err);
      });
  };


  const get_sebi_intermidiate_parameters = (sessionKey, userId, reportDate) => {
    setIsProcess(false)
    setLoader(true)
    getASparameters(
      "auditrpt",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      "SEBI",
      "SEBI INTERMEDIARY REPORT",
      "1"
    )
      .then((response) => {
        if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
          setLoader(false)
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0].ErrorMsg
          );
        } else {
          setLoader(false)
          setSebigetReports(response.data.KCSRPT);
          setSummary(response?.data?.KCSRPT.summary)
          let tableHeading = Object.keys(response?.data?.KCSRPT?.details[0]);
          setHeading(tableHeading);
        }
      })
      .catch((err) => {
        setLoader(false)
        handleError(err);
      });
  };

  /**
   * navigateTosubPage :: Redirect to its Sub Page
   */

  const navigateTosubPage = (rowIndex, columnName) => {
    const clickedRow = sebigetReports.details[rowIndex];
    const clickedValue = clickedRow[columnName];
    if (columnName === "Variance" || clickedRow[columnName] === "") {
      // No action needed for these conditions
    } else if (clickedValue === "0.00000") {
      // No action needed for these conditions
    } else if (!isNaN(+clickedValue) === true) {
      navigate("/dashboard/SEBI/intermediate-subreport", {
        state: {
          colvalue: clickedValue,
          as18head: columnName,
          reportDetails: clickedRow,
        },
      });
    }
    // navigate("/dashboard/SEBI/intermediate-subreport")
  };

  let buttonText = "";

  if (file_type === "false" && sub_type === "false") {
    buttonText = "SEBI INTERMEDIARY REPORT";
  } else if (file_type === true && sub_type === false) {
    buttonText = "File Sub type";
  } else if (sub_type === true && file_type === false) {
    buttonText = selectedSubfile;
  }

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header"> {Constants.sebiIntermidiatereport} </h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header align-items-baseline">
            <div
              className="content-header-left flex-column align-items-start"
              style={{ gap: "23px" }}
            >
              <h1>
                {Constants.reportingDateTime}
                {reportDate}
              </h1>
              <div className="custom-row gx-5 custom-body">
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <TypeIcon />
                    <div class="input-group ">
                      <button
                        class="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedDate || reportDate}
                      </button>
                      <ul class="dropdown-menu ">
                        {reportingDate?.details &&
                          reportingDate?.details?.map((ele, index) => {
                            return (
                              <li key={ele[index]}
                                class=" btn btn-secondary"
                                onClick={() => getSelectedDate(index)}
                              >
                                {ele["Reporting Date"]}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div class="input-group ">
                      <button
                        class="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedfile || "SEBI"}
                      </button>
                      <ul class="dropdown-menu ">
                        {getFileType?.details &&
                          getFileType?.details?.map((ele, index) => {
                            return (
                              <li key={ele[index]}
                                class=" btn btn-secondary"
                                onClick={() => getSelectedFile(index)}
                              >
                                {ele.Pname}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div class="input-group">
                      <button
                        class="btn btn-outline-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {buttonText}
                      </button>
                      <ul class="dropdown-menu">
                        {getFileSubType?.details &&
                          getFileSubType?.details?.map((ele, index) => (
                            <li
                              key={ele[index]}
                              class="btn btn-secondary"
                              onClick={() => getSelectedSubFile(index)}
                            >
                              {ele.pcode1}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div class="input-group ">
                      <button
                        class="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        style={{
                          width:"160px"
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedMultiples || "Multiple Of"}
                      </button>
                      <ul class="dropdown-menu ">
                        {getMultipleof?.details &&
                          getMultipleof?.details?.map((ele, index) => {
                            return (
                              <li key={ele[index]}
                                class=" btn btn-secondary"
                                onClick={() => getSelectedMultipes(index)}
                              >
                                {ele.Pname}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="content-header-right process_btn">
              <div className="flex-row custom-row">
                <GernerateCSVFile
                  generateCSV={sebigetReports}
                  reportName={"SEBIIntermidiateReport"}
                />
                <button className="back-btn" onClick={() => navigate(-1)}>
                  {Constants.backBtnText}
                </button>
              </div>

              <button className="backBtn" onClick={get_sebi_parameters}>
                {Constants.processTest}
              </button>
            </div>
          </div>
          <h4 className="ms_report_msg">
            {Constants.datapreparation}
          </h4>
          {
            loader === false ?
              <div className="content-table sebi-table">
                <ResolvedApi
                  get_response={sebigetReports}
                  heading={heading}
                  navigateToReportDetails={navigateTosubPage}
                />
              </div>
              : <div className="spinner-loader">
                <div class="spinner-border d-flex m-auto" role="status"></div>
              </div>
          }
          <div className={summary === undefined ? "mt-5" : 'd-flex mt-5'}>

            <p className="summary_data">Amount as per Summary Data - </p>
            <div className="summary_text">
              {summary?.map((summaryItem) => (
                <div key={summaryItem.ReportName}>
                  <p className="summary_data">
                    {summaryItem.ReportName}: {summaryItem.ReportAmount}
                  </p>
                </div>
              ))}
            </div>

          </div>
        </div>
        <UserDetailsFooter/>
        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default SEBIInterReports;
