import { NotificationManager } from "react-notifications";
import '../App.css'

export const handleError = (err) => {
  try {

    if (err && err?.response && err?.response?.status === 400) {

      const parsedData = JSON.parse(err?.response?.data || "{}");
      const errorMsg = parsedData?.KCSRPT?.details?.[0]?.errormsg || "";
      
      NotificationManager.error(
        <div className="custom-error-notification">
          <pre class="limited-width-pre">{errorMsg}</pre>
        </div>,
        null,
        5000
      );
    } else if (err && err?.response && err?.response?.status === 500) {
      NotificationManager.error("Internal Server Error")
    }

  } catch (e) {
    // NotificationManager.warning(e);
  }
};
