import React from 'react'
import { Link } from 'react-router-dom'

const FooterLinks = () => {
    return (
        <div className="footer-links">
            <Link>
                <p>Report Issue</p>
            </Link>
            <Link>
                <p>Search</p>
            </Link>
            <Link to="/logout">
                <p>Log Out</p>
            </Link>
        </div>
    )
}

export default FooterLinks