import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { get_AS_level1_parameters } from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const BoardNoteReport = () => {
  const navigate = useNavigate();
  const [, setSessionKey] = useState("");
  const [, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [getBoardNote, setGetBoardNote] = useState([]);
  const [heading, setHeading] = useState();
  const [reportDate, setReporteDate] = useState("");
  const location = useLocation();
  const selectedReportData = location?.state ? location?.state : "";
  const [getCount, setGetCount] = useState("")
  const [get_date, setGet_date] = useState("");
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");


      if (userDate) {
        setReporteDate(userDate);
        await getLevel1paramaters(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        await getLevel1paramaters(getSessionId, getUserId, getdate);
      }
    };
    checkAuth();
  }, [get_date]);


  useEffect(() => {
    let count =
      (getBoardNote?.details &&
        getBoardNote.details.map((ele) => Number(ele.TotalAmt))) ||
      [];
    // This will create an array with the TotalAmt property of each object converted to a number

    let Total_Amount = count.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setGetCount(Total_Amount);
  }, [getBoardNote]);



  /**
   * The function `getLevel1paramaters` retrieves level 1 parameters using a session key, user ID, and
   * selected report data, and then sets the retrieved reports.
   * @param sessionKey - The sessionKey parameter is a unique identifier that is used to authenticate and
   * authorize a user's session. It is typically generated when a user logs in or authenticates with a
   * system or application.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the level 1
   * parameters are being fetched.
   */

  const getLevel1paramaters = (sessionKey, userId, reportDate) => {
    if (selectedReportData) {
      setLoader(true)
      get_AS_level1_parameters(
        "as18 board note rptlevel 1",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        "AS",
        "AS BOARD NOTE",
        selectedReportData?.as18head && selectedReportData.as18head,
        selectedReportData?.reportDetails &&
        selectedReportData?.reportDetails?.RPTName,
        selectedReportData?.reportDetails &&
        selectedReportData?.reportDetails?.RPType,
        selectedReportData?.colvalue && selectedReportData.colvalue,
        selectedReportData?.reportDetails &&
        selectedReportData?.reportDetails.FinYear
      )
        .then((res) => {
          if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
            setLoader(false)
            NotificationManager.error(res?.data?.KCSRPT?.details[0].ErrorMsg);
          } else if (res?.data?.KCSRPT?.details[0].REM) {
            setLoader(false)
            NotificationManager.error(res?.data?.KCSRPT?.details[0].REM);
          } else {
            setLoader(false)
            setGetBoardNote(res.data && res?.data?.KCSRPT);
            let tableHeading = Object.keys(res?.data?.KCSRPT?.details[0]);
            setHeading(tableHeading);
          }
        })
        .catch((err) => {
          setLoader(false)
          handleError(err);
        });
    }
  };

  /**
   * navigateToReportDetails :: Redirect to Report Details Page
   */
  const navigateToReportDetails = (rowIndex, columnName) => {
    const clickedRow = getBoardNote.details[rowIndex];
    const clickedValue = clickedRow[columnName];

    if (
      columnName === "Variance" || clickedRow[columnName] === ""
    ) {
      // No action needed for these conditions
    } else if (clickedRow[columnName] === "0.00000") {
      // No action needed for these conditions
    } else if (!isNaN(+clickedValue) === true) {
      navigate("/dashboard/AS/board-report-level2", {
        state: {
          colvalue: clickedValue,
          as18head: columnName,
          reportDetails: clickedRow,
        }
      });
    }
  };

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header"> {Constants.boardreportlevel} </h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left">
              <h1>{Constants.reportingDateTime}{reportDate}</h1>
            </div>
            <div className="content-header-right ">
              <h3 className='total-amt'>{Constants.totalAmt}{getCount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</h3>
              <GernerateCSVFile
                generateCSV={getBoardNote}
                reportName={"AS18BoardNoteLevel1"}
              />
              <button className="back-btn" onClick={() => navigate(-1)}>
                {Constants.backBtnText}
              </button>
            </div>
          </div>
          {
            loader === false ?
              <div className="content-table main-report-table">
                <ResolvedApi
                  get_response={getBoardNote}
                  heading={heading}
                  navigateToReportDetails={navigateToReportDetails}
                />
              </div>
              : <div className="spinner-loader">
                <div class="spinner-border d-flex m-auto" role="status"></div>
              </div>
          }
        </div>
      </div>
    </MainLayout>
  );
};

export default BoardNoteReport;
