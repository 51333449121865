import "./dashboard.css";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Constants from "../../constants/stringConstants";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import { ReactComponent as ScanIcon } from "../../assets/images/Scan.svg";
import { ReactComponent as VerifyIcon } from "../../assets/images/Tick.svg";
import { ReactComponent as TypeIcon } from "../../assets/images/Category.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/Search.svg";
import VerifyOverlay from "../../components/Overlay/ExceptionModal/VerifyOverlay";
import ExceptionOverlay from "../../components/Overlay/ExceptionModal/ExceptionOverlay";
import { BackBtn } from "../../components/Buttons/CustomButton";
import { getDashboardExceptionCount, getExceptionVerifyOn } from "../../Api";
import { NotificationManager } from "react-notifications";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

function ExpectionCount() {
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [selectPopup, setSelectPopup] = useState(false);
  const location = useLocation();
  const [exceptioncount, setExceptioncount] = useState([]);
  const [heading, setHeading] = useState([]);
  const [getImptype, setGetImptype] = useState("");
  const [, setFromIgnore] = useState(false);
  const navigate = useNavigate();
  const [checkedValues, setCheckedValues] = useState([]);
  const [verifyOn, setVerifyOn] = useState([])



  useEffect(() => {
    console.log(location.state)
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        getProcessContent(getSessionId, getUserId, userDate)
      } else {
        setReporteDate(getdate);
        getProcessContent(getSessionId, getUserId, getdate)
      }

      await setGetImptype(location.state && location?.state?.Imptype);
    };
    checkAuth();
  }, []);



/**
 * The function `getProcessContent` retrieves exception count data from a dashboard and sets the
 * exception count and table heading based on the response.
 * @param sessionKey - The sessionKey parameter is used to identify the current session of the user. It
 * is typically generated when the user logs in or authenticates with the system.
 * @param userId - The `userId` parameter is used to identify the user for whom the process content is
 * being retrieved.
 * @param reportDate - The `reportDate` parameter is a date that is used to specify the date for which
 * the report is generated.
 */

  const getProcessContent = (sessionKey, userId, reportDate) => {
    getDashboardExceptionCount(
      "Exception Show",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      location.state.type,
      location?.state?.Imptype,
      "",
      ""
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
        NotificationManager.error(response?.data?.KCSRPT?.details[0]?.ErrorMsg)
      } else {
        setExceptioncount(response?.data?.KCSRPT || null)
        let tableHeading =
          (response?.data?.KCSRPT?.details ? Object.keys(response?.data?.KCSRPT?.details[0]) : [])

        setHeading(tableHeading);
      }
    });
  }

  /**
   * The function `getSelectedOptions` toggles the selection of a value in an array.
   * @param value - The `value` parameter is the value of the option that is being selected or
   * deselected.
   */

  const getSelectedOptions = (id) => {
    setCheckedValues((prevCheckedValues) => {
      if (prevCheckedValues.includes(id)) {
        // If the id is already in checkedValues, remove it
        return prevCheckedValues.filter((value) => value !== id);
      } else {
        // If the id is not in checkedValues, add it
        return [...prevCheckedValues, id];
      }
    });
  };



  /**
   * The function `getCheckedValues` sets the state variables `showModal`, `checkedValues`, and
   * `fromIgnore` based on the current value of `checkedValues`.
   */

  const getCheckedValues = () => {
    setShowModal(true);
    setFromIgnore(false);

    // Update the checkedValues array based on the checkboxes that are checked
    const updatedCheckedValues = exceptioncount?.details.filter(row => checkedValues.includes(row.idcol))
      .map(row => row.idcol)
      .join("|");

    setCheckedValues(updatedCheckedValues);
  };



  /**
   * The function `navigateToIngoreException` navigates to a specific URL with a state object.
   */

  const navigateToIngoreException = () => {
    navigate("/dashboard/ignore-total-count", {
      state: getImptype,
    });
  };



  /**
   * The function `getVerifyOn` sets the `showVerifyModal` state to true and retrieves the `KCSRPT` value
   * from the response of the `getExceptionVerifyOn` function.
   */

  const getVerifyOn = () => {
    setShowVerifyModal(true)
    getExceptionVerifyOn(
      "Verify On",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      location.state.type,
      getImptype
    ).then((response) => {
      if (response) {
        setVerifyOn(response.data.KCSRPT)
      }
    });
  };


  return (
    <div className="total-count-layoutWrapper">
      <div className="content-body">
        <div className="content-header pb-3">
          <div className="content-header-left">
            <h1>
              {" "}
              {`${location?.state.columnname} EXCEPTION` || Constants.exceptionTitle}{" "}
              <span> ( {Constants.reportingDateTime}{" "}{reportDate} )</span>{" "}
            </h1>
          </div>
          <div className="content-header-right">
            {/* <p className="log-link">{Constants.updateExceptionText}</p> */}
            <button className="exportBtn" onClick={getCheckedValues}>
              {Constants.ignoreText}
            </button>
            <button
              className="exportBtn"
              onClick={() => getVerifyOn()}
            >
              {" "}
              {Constants.verifyText} <VerifyIcon />{" "}
            </button>
            <BackBtn />
          </div>
        </div>
        <div className="content-header pb-4 trend-filter-row">
          <div className="content-header-left ">
            <div className="options-row position-relative">
              <TypeIcon onClick={() => setSelectPopup(!selectPopup)} />
              <p onClick={() => setSelectPopup(!selectPopup)}>Select </p>
              {selectPopup && (
                <div className="select-popup">
                  <p>Select Raw to Display</p>
                  <Form.Check label="Calc Date" />
                  <Form.Check label="GI Date" />
                  <Form.Check label="CRN No" />
                  <Form.Check label="GI Code" />
                  <Form.Check label="GI Description" />
                  <Form.Check label="IT Pan" />
                  <Form.Check label="RP Name" />
                  <Form.Check label="Amount" />
                  <Form.Check label="Vendor Name" />
                  <Form.Check label="Remark" />
                  <Form.Check label="Related Party" />

                  <button
                    className="apply-btn"
                    onClick={() => setSelectPopup(!selectPopup)}
                  >
                    {Constants.applyBtnText}
                  </button>
                </div>
              )}
            </div>
            <div className="options-row ">
              <ScanIcon />
              <CustomDropdown
                title="Find Unique"
                options={["FY22 Q3", "FY22 Q2"]}
              />
            </div>
            <div className="options-row">
              <SearchIcon />
              <CustomDropdown
                title="Search On"
                options={["FY22 Q3", "FY22 Q2"]}
              />
            </div>
          </div>

          <GernerateCSVFile
            generateCSV={exceptioncount}
            reportName={"ExceptionCountReport"}
          />
        </div>
        {
          showModal && <ExceptionOverlay
            show={showModal}
            onHide={() => setShowModal(false)}
            getignore={'false'}
            getvalues={checkedValues}
            gettype={getImptype}
            ignoredException={setExceptioncount}
            dashboardType={location.state.type}
          />
        }
        <div className="content-table main-report-table ">
          <div className="table-container">
            <table className="table">
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 1,
                }}
              >
                <tr>
                  {heading
                    ? heading.map((title, columnIndex) => {

                      if (columnIndex === 0) {
                        return null;
                      }
                      if (columnIndex === 1) {
                        return (
                          <th
                            key={title[columnIndex]}
                            className="heading_font"
                          >
                            select
                          </th>
                        );
                      }
                      return (
                        <th key={title} className="heading_font">
                          {title}
                        </th>
                      );
                    })
                    : null}
                </tr>
              </thead>

              <tbody>
                {exceptioncount?.details &&
                  exceptioncount.details.map((row, rowIndex) => (
                    <tr key={row[rowIndex]}>
                      {Object.keys(row).map((columnName, columnIndex) => {
                        if (columnIndex === 0) {
                          return null;
                        }

                        const value = row[columnName];
                        let formattedValue = value;
                        if (columnIndex === 1) {
                          return (
                            <td key={columnName}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="value1"
                                  onChange={() => {
                                    getSelectedOptions(row.idcol);
                                  }}
                                  checked={checkedValues.includes(row.idcol)}
                                />
                              </div>
                            </td>
                          );
                        }

                        if (columnName === 'Amount') {
                          formattedValue = parseFloat(value).toFixed(2);
                        }

                        return (
                          <>
                            <td
                              className={`${!isNaN(row[columnName])
                                ? "report_alignment main_report"
                                : "dashed"
                                }`}
                              key={columnName}
                            >
                              <b>{formattedValue}</b>
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="summary-row">
          {exceptioncount?.summary &&
            exceptioncount?.summary?.map((summary, i) => {
              return (
                <div className="summary-data" key={summary[i]}>
                  <p style={{ fontWeight: "bold" }}>
                    {summary?.exception ? summary?.exception : "-"}
                  </p>
                  {/* <p>{summary?.summary ? summary?.summary : "-"} </p> */}
                  <div className="count-btns">
                    {summary?.lnkIgnoreExcep === "YES" ? (
                      <button className="backBtn mt-3" onClick={navigateToIngoreException}>
                        {Constants.ignoreException}
                      </button>
                    ) : (
                      ""
                    )}
                    {summary?.exception_view === "YES" ? (
                      <button className="backBtn mt-3">{Constants.exceptionView}</button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="content-footer">
        <p>{Constants.footerText}</p>
      </div>
      {
        showVerifyModal && <VerifyOverlay
          getImptype={getImptype}
          show={showVerifyModal}
          verifyOn={verifyOn}
          type={location.state.type}
          getvalues={checkedValues}
          ignoredException={setExceptioncount}
          onHide={() => setShowVerifyModal(false)}
        />
      }
    </div>
  );
}

export default ExpectionCount;
