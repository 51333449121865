import "./mainLayout.css";
import { Sidebar } from "./Sidebar";
import React, { useState } from "react";
import { Container } from "react-bootstrap";

const MainLayout = (props) => {
  const [isExpanded, setExpendState] = useState(true);

  /**
   * Toggle sidebar layput
   */
  const collapseMenu = () => {
    setExpendState(!isExpanded);
  };

  return (
    <Container fluid className="p-0 layoutRow">
      <Sidebar isExpanded={isExpanded} isCollapseMenu={collapseMenu} />
      {isExpanded ? (
        <div className="content-wrapper">{props.children}</div>
      ) : (
        <div className="content-wrapper toggleMenu">{props.children}</div>
      )}
    </Container>
  );
};

export default MainLayout;
