import './action.css'
import React, { useState, useEffect } from 'react'
import ModalIcon from '../../../assets/images/preview.png'
import { Modal, FloatingLabel, Form } from 'react-bootstrap'
import * as Constants from '../../../constants/stringConstants'
import { getReportingDate, getReportingDateList } from '../../../Api'
import { NotificationManager } from 'react-notifications'

const ActionOverlay = ({ show, get_date, onHide, text, setGetReportingDetails }) => {

    const [sessionKey, setSessionKey] = useState("");
    const [userId, setUserId] = useState("");
    const [reason, setReason] = useState("")

    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

        };
        checkAuth();
    }, [get_date]);

    const handleChnage = (e) => {
        setReason(e.target.value)
    }



    const getDeleteAuthorizedAction = (closeModal) => {
        getReportingDate(
            "rptdatemaster",
            sessionKey,
            userId,
            get_date,
            "delete",
            get_date,
            reason,
            ""
        ).then((response) => {
            if (response) {
                NotificationManager.error(response.data.KCSRPT.details[0].ErrorMsg)
                getReportingDateList(
                    "rptdatemst",
                    sessionKey,
                    userId,
                    get_date,
                    "LIST",
                    get_date,
                    "",
                    ""
                ).then((response) => {
                    if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
                        NotificationManager.error(response?.data?.KCSRPT?.details[0]?.ErrorMsg);
                    } else {
                        setGetReportingDetails(response?.data?.KCSRPT);
                    }
                });
                closeModal()
            }
        });
    }

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='action-overlay-popup'>

            <Modal.Body>
                <div className='text-center' >
                    <img src={ModalIcon} alt="modalIcon" />
                </div>

                <h2>{Constants.reasonText} {text}</h2>
                <FloatingLabel controlId="floatingTextarea2" label="Type your reason here...">
                    <Form.Control
                        as="textarea"
                        placeholder="Type your reason here..."
                        className='modalTextArea'
                        onChange={handleChnage}
                        value={reason}
                    />
                </FloatingLabel>
                <div className='btn-row'>
                    <button className='ignoreBtn modalBtn' onClick={() => getDeleteAuthorizedAction(onHide)}>{Constants.okBtnText}</button>
                    <button className='cancelBtn  modalBtn' onClick={onHide}>{Constants.cancelBtnText}</button>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default ActionOverlay