import "./dashboard.css";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { BackBtn } from "../../components/Buttons/CustomButton";
import { getDashboardExceptionCount } from "../../Api";
import ExceptionOverlay from "../../components/Overlay/ExceptionModal/ExceptionOverlay";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const IgnoredException = () => {
  const location = useLocation();
  const [ignoredException, setIgnoredException] = useState([]);
  const [heading, setHeading] = useState([]);
  const [, setSessionKey] = useState("");
  const [, setUserId] = useState("");
  const [, setReporteDate] = useState("");
  const [imptype, setImptype] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [, setFromIgnore] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [, setType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        await getIgnoredExceptionValues(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        await getIgnoredExceptionValues(getSessionId, getUserId, getdate);
      }

      await setImptype(location.state && location?.state.IMPTYPE);

      await setType(location.state && location?.state?.dashboardtype);
    };
    checkAuth();
  }, []);

  /**
   * The function `getIgnoredExceptionValues` retrieves exception count data for a specific session,
   * user, and report date, and updates the state variables `ignoredException` and `heading` based on
   * the response.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents a user's
   * session or login session. It is used to authenticate and authorize the user's access to certain
   * resources or functionalities within the application.
   * @param userId - The `userId` parameter is used to identify the user for whom the exception values
   * are being retrieved.
   * @param reportDate - The `reportDate` parameter is a date value that represents the date for which
   * the dashboard exception count is being retrieved.
   */

  const getIgnoredExceptionValues = (sessionKey, userId, reportDate) => {
    getDashboardExceptionCount(
      "Exception Show",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      location?.state.dashboardtype,
      location?.state.IMPTYPE,
      "",
      "ignore"
    ).then((response) => {
      setIgnoredException(response?.data?.KCSRPT || null);
      setHeading(
        response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : []
      );
    });
  };

  /**
   * The function `getSelectedOptions` toggles the selection of a value in an array called
   * `checkedValues`.
   * @param value - The `value` parameter is the value of the option that is being selected or
   * deselected.
   */

  const getSelectedOptions = (id) => {
    setCheckedValues((prevCheckedValues) => {
      if (prevCheckedValues.includes(id)) {
        // If the id is already in checkedValues, remove it
        return prevCheckedValues.filter((value) => value !== id);
      } else {
        // If the id is not in checkedValues, add it
        return [...prevCheckedValues, id];
      }
    });
  };

  /**
   * The function `getCheckedValues` sets the `showModal` state to true, converts an array of checked
   * values into a string separated by "|" characters, sets the `checkedValues` state to the converted
   * string, and sets the `fromIgnore` state to true.
   */

  const getCheckedValues = () => {
    setShowModal(true);
    setFromIgnore(true);
    // Update the checkedValues array based on the checkboxes that are checked
    const updatedCheckedValues = ignoredException?.details
      .filter((row) => checkedValues.includes(row.idcol))
      .map((row) => row.idcol)
      .join("|");

    setCheckedValues(updatedCheckedValues);
  };

  /**
   * The function `getUpdateException` navigates to a specific dashboard page with some state parameters.
   */

  const getUpdateException = () => {
    navigate("/dashboard/update-count-exception", {
      state: {
        imptype: location?.state.IMPTYPE,
        type: location?.state?.dashboardtype,
        action: location?.state?.action,
      },
    });
  };

  return (
    <MainLayout>
      <div className="total-count-layoutWrapper">
        <div className="content-body">
          <div className="content-header pb-4">
            <div className="content-header-left">
              <h1>{Constants.totalCountText}</h1>
              <Form>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="">
                    <Form.Check
                      inline
                      label="Pending "
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label="Authorised"
                      name="group1"
                      type={type}
                      id={`inline-${type}-2`}
                    />
                    <Form.Check
                      inline
                      label="Cancelled Pending"
                      name="group1"
                      type={type}
                      id={`inline-${type}-3`}
                    />
                  </div>
                ))}
              </Form>
            </div>
            <div className="content-header-right">
              <p onClick={getUpdateException} className="log-link">
                {Constants.updateExceptionText}
              </p>
              <button className="exportBtn" onClick={() => getCheckedValues()}>
                {Constants.ignoreText}
              </button>
              <BackBtn />
            </div>
          </div>
          <div className="filter-row">
            <div className="content-header-left"></div>

            <GernerateCSVFile
              generateCSV={ignoredException}
              reportName={"IgnoreException"}
            />
          </div>

          <div className="content-table main-report-table ">
            <div className="table-container">
              <table className="table">
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                  <tr>
                    {heading
                      ? heading.map((title, columnIndex) => {
                          if (columnIndex === 0) {
                            return null;
                          }
                          if (columnIndex === 1) {
                            return (
                              <th
                                key={title[columnIndex]}
                                className="heading_font"
                              >
                                select
                              </th>
                            );
                          }
                          return (
                            <th key={title} className="heading_font">
                              {title}
                            </th>
                          );
                        })
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {ignoredException?.details &&
                    ignoredException?.details?.map((row, rowIndex) => (
                      <tr key={row[rowIndex]}>
                        {Object.keys(row).map((columnName, columnIndex) => {
                          if (columnIndex === 0) {
                            return null;
                          }

                          const value = row[columnName];
                          let formattedValue = value;

                          if (columnIndex === 1) {
                            return (
                              <td key={columnName}>
                                <div class="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="value1"
                                    onChange={() => {
                                      getSelectedOptions(row.idcol);
                                    }}
                                    checked={checkedValues.includes(row.idcol)}
                                  />
                                </div>
                              </td>
                            );
                          }

                          return (
                            <>
                              <td
                                className={`${
                                  !isNaN(row[columnName])
                                    ? "report_alignment main_report"
                                    : "dashed"
                                }`}
                                key={rowIndex}
                              >
                                <b>{formattedValue}</b>
                              </td>
                            </>
                          );
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="summary-row">
            {ignoredException?.summary &&
              ignoredException?.summary?.map((summary, i) => {
                return (
                  <div className="summary-data" key={summary[i]}>
                    {}
                    <p style={{ fontWeight: "bold" }}>
                      {summary?.exception ? summary?.exception : "-"}
                    </p>
                    <p>{summary?.summary ? summary?.summary : "-"} </p>
                    <div className="count-btns">
                      {summary?.lnkIgnoreExcep === "YES" ? (
                        <a href="" className="log-link">
                          {Constants.ignoreException}
                        </a>
                      ) : (
                        ""
                      )}
                      {summary?.exception_view === "YES" ? (
                        <button className="backBtn mt-3">
                          {Constants.exceptionView}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <ExceptionOverlay
          show={showModal}
          onHide={() => setShowModal(false)}
          getignore={location.state.fromcount || null}
          getvalues={checkedValues}
          gettype={imptype}
          ignoredException={setIgnoredException}
          dashboardType={location.state.type}
        />
        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default IgnoredException;
