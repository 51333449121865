import "./header.css";
import React, { useState, useEffect } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactComponent as UnlockIcon } from "../../assets/images/Unlock.svg";
import { ReactComponent as LockIcon } from "../../assets/images/lock-icon-free-vector.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/Category-dark.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/new.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/header.svg";
import * as Constants from "../../constants/stringConstants";
import {
  getFinanicalYearReport,
  getFinanicalYeardates,
  getFreezeReport,
} from "../../Api";
import { NotificationManager } from "react-notifications";
import FreezeOverlay from "../Overlay/FreezeModal/FreezeOverlay";
import { useLocation } from "react-router-dom";

const popover = (
  <Popover id="popover-basic">
    <Popover.Body className="freeze">{Constants.freezeText}</Popover.Body>
  </Popover>
);

const unpopover = (
  <Popover id="popover-basic">
    <Popover.Body className="freeze">{Constants.unfreezeText}</Popover.Body>
  </Popover>
);

const Header = ({ setGet_date, get_date }) => {
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [isFrozen, setIsFrozen] = useState(false);
  const [getFinYear, setGetFinYear] = useState([]);
  const [, setYear] = useState("");
  const [getselectedYear, setGetselectedYear] = useState([]);
  const location = useLocation();
  const freezed = location.state && location?.state?.freezed;
  const [, setClicked] = useState("false");
  const [visibleSubMenuIndex, setVisibleSubMenuIndex] = useState(null);
  const [submenuPosition] = useState({});

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
      } else {
        setReporteDate(getdate);
      }


      if (freezed === true) {
        setIsFrozen(true);
      } else {
        setIsFrozen(false);
      }
    };
    checkAuth();
  }, [get_date]);

  const getFinancialYearDates = () => {
    getFinanicalYearReport("financialyear", sessionKey, userId, reportDate).then(
      (response) => {
        if (response) {
          setGetFinYear(response?.data?.KCSRPT);
        } else {
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0]?.ErrorMsg
          );
        }
      }
    );
  };

  const freezeData = () => {
    if (isFrozen) {
      getFreezeReport(
        "freeze",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        "Freeze",
        ""
      ).then((response) => {
        if (response) {
          response?.data?.KCSRPT?.details[0]?.ErrorMsg ===
            `Report Freeze successfully for reporting date: ${reportDate}` ||
            `Data is already freeze  for reporting date : ${reportDate}`
            ? setIsFrozen(false)
            : setIsFrozen(true);

          NotificationManager.success(
            response?.data?.KCSRPT?.details[0]?.ErrorMsg
          );
        }
      });
    }
  };

  const [showReasonOverlay, setShowReasonOverlay] = useState(false);

  const showReasonOverlayModal = () => {
    setShowReasonOverlay(false);
  };

  const getselectedDate = (index) => {
    localStorage.setItem("date_key", getselectedYear?.details[index]?.dtRpt);
    setGet_date(getselectedYear?.details[index]?.dtRpt)
  };


  const unfreezeData = () => {
    if (isFrozen === false) {
      setShowReasonOverlay(true);
    }
  };

  const getClickedYear = (index) => {
    setYear(getFinYear.details[index].FinYear);
    localStorage.setItem("finyear", getFinYear?.details[index]?.FinYear);
    getFinanicalYeardates(
      "reportingdate",
      sessionKey,
      userId,
      reportDate,
      getFinYear?.details[index]?.FinYear
    ).then((response) => {
      if (response) {
        setClicked(true);
        setGetselectedYear(response?.data?.KCSRPT);
      }
    });
  };

  const toggleSubMenu = (index) => {
    if (visibleSubMenuIndex === index) {
      setVisibleSubMenuIndex(null); // Close the submenu if it's already open
    } else {
      setVisibleSubMenuIndex(index); // Open the clicked submenu
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="header-row main-header">
      <div className="flex-row custom-row">
        <nav className="navbar navbar-expand-lg financial_year">
          <div className="container-fluid quarterBtn dropdown">
            <div
              className="collapse navbar-collapse quarterBtn btn btn-primary"
              id="main_nav"
            >
              <ul
                className="navbar-nav contnet-click"
                onClick={getFinancialYearDates}
              >
                <li className="nav-item dropdown" id="myDropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                    onClick={toggleSubMenu.bind(this, null)}
                  >
                    Financial Year
                  </a>
                  <ul className="dropdown-menu" onClick={stopPropagation}>
                    {getFinYear?.details &&
                      getFinYear?.details.map((year, index) => {
                        const isSubMenuOpen = visibleSubMenuIndex === index;
                        const submenuVisibilityClass = isSubMenuOpen
                          ? "show"
                          : "submenu-not";
                        return (
                          <li
                            className="financialYearItem"
                            key={year[index]}
                            id={`financialYearItem-${index}`}
                            onMouseEnter={() => {
                              getClickedYear(index);
                            }}
                          >
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();

                                toggleSubMenu(index);
                              }}
                            >
                              {year["Financial Year"]}
                            </a>

                            <ul
                              onClick={stopPropagation}
                              className={getselectedYear?.details?.map((ele) =>
                                ele.dtRpt === undefined
                                  ? "submenu-hide"
                                  : `submenu dropdown-menu ${submenuVisibilityClass}`
                              )}
                              style={isSubMenuOpen ? submenuPosition : {}}
                            >
                              {getselectedYear?.details &&
                                getselectedYear?.details?.map((ele, index) => {
                                  return (
                                    <li
                                      key={ele[index]}
                                      className={
                                        ele.dtRpt === undefined
                                          ? "financialYear_Item"
                                          : ""
                                      }
                                      onClick={() => getselectedDate(index)}
                                    >
                                      <a className="dropdown-item" href="#">
                                        {ele?.dtRpt}
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                        );
                      })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={isFrozen ? popover : unpopover}
      >
        {isFrozen ? (
          <UnlockIcon onClick={freezeData} />
        ) : (
          <LockIcon onClick={unfreezeData} />
        )}
      </OverlayTrigger>
      <FreezeOverlay
        show={showReasonOverlay}
        onHide={showReasonOverlayModal}
        isFrozen={isFrozen}
        date={reportDate}
        setIsFrozen={setIsFrozen}
      />
      <NotificationIcon />
      <MenuIcon />
      <div className="profile-row">
        <p className="profile-name"> {Constants.profileText} </p>
        <ProfileIcon />
      </div>
    </div>
  );
};

export default Header;
