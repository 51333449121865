
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import { ReactComponent as ScanIcon } from "../../assets/images/Scan.svg";
import { ReactComponent as TypeIcon } from "../../assets/images/Category.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/Search.svg";
import { getNotASLevel1Params } from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";
import { formatDateToYYYYMM } from "../../utils";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const NotASLevel1Report = () => {
    const navigate = useNavigate();
    const [, setSessionKey] = useState("");
    const [, setUserId] = useState("");
    const [, setBranchCode] = useState("");
    const [getNotASReports, setGetNotASReports] = useState([]);
    const [reportDate, setReporteDate] = useState("");
    const [heading, setHeading] = useState();
    const location = useLocation()
    const [getCount, setGetCount] = useState("")
    const [get_date, setGet_date] = useState("");
    const [loader, setLoader] = useState(true)
    const getNot_AS_Reports = location?.state && location.state ? location?.state : ""

    const datePattern = /^\d{4}\d{2}$/;

    const date =
        datePattern.test(formatDateToYYYYMM(getNot_AS_Reports.reportType)) === true
            ? formatDateToYYYYMM(getNot_AS_Reports.reportType)
            : getNot_AS_Reports.reportType;



    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getBranch = await localStorage.getItem("branch_code");
            setBranchCode(getBranch);

            let userDate = await localStorage.getItem("date_key");
            let getdate = await localStorage.getItem("reporting_date");


            if (userDate) {
                setReporteDate(userDate);
                await getNot_AS_parameters(getSessionId, getUserId, userDate);
            } else {
                setReporteDate(getdate);
                await getNot_AS_parameters(getSessionId, getUserId, getdate);
            }
        };
        checkAuth();
    }, [get_date]);



    useEffect(() => {
        let count =
            (getNotASReports?.details &&
                getNotASReports?.details?.map((ele) => Number(ele["Transaction Amount"]))) ||
            [];
        // This will create an array with the TotalAmt property of each object converted to a number

        let Total_Amount = count.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        setGetCount(Total_Amount);
    }, [getNotASReports]);


    const getNot_AS_parameters = (sessionKey, userId, reportDate) => {
        setLoader(true)
        getNotASLevel1Params(
            "Not IN AS",
            sessionKey,
            userId,
            reportDate,
            reportDate,
            "Not IN AS",
            "",
            "1",
            date,
            getNot_AS_Reports.reportDetails.TxnDescription,
            getNot_AS_Reports.reportDetails.RPTName,
            getNot_AS_Reports.reportingAmt
        )
            .then((response) => {
                if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
                    setLoader(false)
                    NotificationManager.error(
                        response?.data?.KCSRPT?.details[0].ErrorMsg
                    );
                } else {
                    setLoader(false)
                    setGetNotASReports(response.data.KCSRPT);
                    let tableHeading = Object.keys(response?.data?.KCSRPT?.details[0]);
                    setHeading(tableHeading);
                }
            })
            .catch((err) => {
                setLoader(false)
                handleError(err);
            });
    };







    return (
        <MainLayout>
            <div className="layoutWrapper">
                <div className="header-wrapper">
                    <h1 className="header">{Constants.notASreportText}</h1>
                    <Header setGet_date={setGet_date} get_date={get_date} />
                </div>
                <div className="content-body">
                    <div className="content-header align-items-baseline">
                        <div className="content-header-left flex-column align-items-start">
                            <h1>
                                {Constants.reportingDateTime}
                                {reportDate}
                            </h1>
                            <div className="custom-row gx-5">
                                <div className="flex-row custom-row">
                                    <TypeIcon />
                                    <CustomDropdown
                                        title="Type"
                                        options={["FY22 Q3", "FY22 Q2", "FY22 Q1"]}
                                    />
                                </div>
                                <div className="flex-row custom-row">
                                    <SearchIcon />
                                    <CustomDropdown
                                        title="Sub File Type"
                                        options={[
                                            "Sub File Type 1",
                                            "Sub File Type 2",
                                            "Sub File Type 3",
                                        ]}
                                    />
                                </div>
                                <div className="flex-row custom-row">
                                    <ScanIcon />
                                    <CustomDropdown
                                        title="Reporting Date"
                                        options={["Reporting Date 1", "Reporting Date 2"]}
                                    />
                                </div>
                                <div className="flex-row custom-row">
                                    <ScanIcon />
                                    <CustomDropdown
                                        title="Multiple of"
                                        options={["File Type 1", "File Type 2"]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="content-header-right ">
                            <h3 className='total-amt '>{Constants.totalAmt}
                                {getCount.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </h3>
                            <GernerateCSVFile
                                generateCSV={getNotASReports}
                                reportName={"NotASLevel1Report"}
                            />
                            <button className="back-btn" onClick={() => navigate(-1)}>
                                {Constants.backBtnText}
                            </button>
                        </div>
                    </div>
                    {
                        loader === false ?
                            <div className="content-table main-report-table">
                                <ResolvedApi
                                    get_response={getNotASReports}
                                    heading={heading}
                                    navigateToReportDetails={""}
                                />
                            </div>
                            : <div className="spinner-loader">
                                <div class="spinner-border d-flex m-auto" role="status"></div>
                            </div>
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default NotASLevel1Report;
