import "./mis.css";
import "./Trend.css";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as TypeIcon } from "../../assets/images/Category.svg";
import { ReactComponent as DocumentIcon } from "../../assets/images/Document_light.svg";
import { ReactComponent as DateIcon } from "../../assets/images/Calendar-fill.svg";
import { BackBtn } from "../../components/Buttons/CustomButton";
import { ReactComponent as ReportIcon } from "../../assets/images/Calendar.svg";
import Calendar from "react-calendar";
import { formatDateToDDMMMYYYY, formatMonthDate } from "../../utils";
import {
  getRptName,
  getTransactionSubType,
  getTransactionType,
  getTrendAnalysis,
  getTrendDeviation,
  getTrendFrequency,
} from "../../Api";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";
import { NotificationManager } from "react-notifications";

const TrendAnalysisReport = () => {
  const [getSelectedFromDate, setGetSelectedFromDate] = useState("");
  const [getSelectedToDate, setGetSelectedToDate] = useState("");
  const [calendarFrom, setCalendarFrom] = useState(false);
  const [calendarTo, setCalendarTo] = useState(false);
  const [date] = useState();
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [getFrequencyType, setGetFrequecyType] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [rptName, setRptName] = useState("");
  const [gettransaction, setGettransaction] = useState("");
  const [getsubtransaction, setGetsubtransaction] = useState("");
  const [getRpt_name, setGetRpt_name] = useState([]);
  const [deviation, setdeviation] = useState([]);
  const [getDeviation, setGetDeviation] = useState("");
  const [gettredAnalysisDetails, setGettredAnalysisDetails] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [subtransaction, setSubtransaction] = useState([]);
  const [heading, setHeading] = useState("");
  const [notation, setNotation] = useState("");
  const [deviator, setDeviator] = useState("");
  const [get_date, setGet_date] = useState("");
  const [loader, setLoader] = useState(true);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        await getFrequencyReport(getSessionId, getUserId, userDate);

        await getDeviationReport(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        await getFrequencyReport(getSessionId, getUserId, getdate);

        await getDeviationReport(getSessionId, getUserId, getdate);
      }
    };
    checkAuth();
  }, [get_date]);

  let Fromdata = formatDateToDDMMMYYYY(getSelectedFromDate);
  let Todata = formatDateToDDMMMYYYY(getSelectedToDate);

  useEffect(() => {
    if (Fromdata && Todata) {
      getRptReport(getSelectedFromDate, getSelectedToDate);
      getTransactionReport(getSelectedFromDate, getSelectedToDate);
      getSubTransactionReport(getSelectedFromDate, getSelectedToDate);
    }
  }, [Fromdata && Todata]);

  /**
   * showCalendar :: View Calendar
   */
  const showFromCalendar = () => {
    setCalendarFrom(!calendarFrom);
    setCalendarTo(false);
  };

  /**
   * showCalendar :: View Calendar
   */
  const showToCalendar = () => {
    setCalendarTo(!calendarTo);
    setCalendarFrom(false);
  };

  const enterFromDate = (e) => {
    setGetSelectedFromDate(e.target.value);
    setCalendarFrom(false);
  };

  const onChangeFromDate = (date) => {
    setGetSelectedFromDate(formatMonthDate(date));
    setCalendarFrom(false);
  };

  const enterToDate = (e) => {
    setGetSelectedToDate(e.target.value);
    setCalendarTo(false);
  };

  const onChangeToDate = (date) => {
    setGetSelectedToDate(formatMonthDate(date));
    setCalendarTo(false);
  };

  /**
   * The function `gettrendAnalysisFrequency` sets the frequency of a trend analysis based on the
   * provided index.
   * @param index - The index parameter is used to specify the position of an element in an array or
   * list. In this case, it is likely being used to access a specific element in the
   * getFrequencyType.details array.
   */

  const gettrendAnalysisFrequency = (index) => {
    setFrequency(getFrequencyType.details[index].Pname);
  };

  /**
   * The function `gettrendAnalysisDeviation` sets the value of `GetDeviation` to the `Pname` property of
   * the `details` object at the specified `index` in the `deviation` array.
   * @param index - The index parameter is used to specify the position of the element in the
   * deviation.details array that you want to retrieve the Pname property from.
   */

  const gettrendAnalysisDeviation = (index) => {
    setGetDeviation(deviation.details[index].Pname);
  };

  /**
   * The function `gettrendAnalysisRptname` sets the value of `RptName` based on the `RPTNAME` property
   * of an object in the `getRpt_name.details` array at the specified index.
   * @param index - The parameter "index" is used to specify the position of the desired element in the
   * "getRpt_name.details" array. It is used to access the "RPTNAME" property of the element at the
   * specified index.
   */

  const gettrendAnalysisRptname = (index) => {
    setRptName(getRpt_name.details[index].RPTNAME);
  };

  /**
   * The function `gettrendAnalysisTransaction` sets the value of `gettransaction` based on the `RPTNAME`
   * property of an object in the `getRpt_name.details` array at the specified index.
   * @param index - The index parameter is used to specify the position of the element in the array that
   * you want to access. It is an integer value that represents the index of the element you want to
   * retrieve from the getRpt_name.details array.
   */

  const gettrendAnalysisTransaction = (index) => {
    setGettransaction(transaction.details[index].AS);
  };

  /**
   * The function `gettrendAnalysisSubTransaction` sets the value of `subtettransaction` based on the
   * `RPTNAME` property of an object in the `getRpt_name.details` array at the specified `index`.
   * @param index - The index parameter is used to specify the position of the element in an array or
   * list. In this case, it is used to access a specific element in the getRpt_name.details array.
   */
  const gettrendAnalysisSubTransaction = (index) => {
    setGetsubtransaction(subtransaction.details[index]["AS Main Head"]);
  };

  /**
   * The function `gettrendAnalysisNotation` sets the notation value to the provided index.
   * @param index - The index parameter is a value that represents the position or order of an item in a
   * list or array. It is used in the gettrendAnalysisNotation function to set the notation value.
   */
  const gettrendAnalysisNotation = (index) => {
    setNotation(index);
  };

  const getDeviatorvalue = (e) => {
    setDeviator(e.target.value);
  };

  /**
   * The function `getFrequencyReport` retrieves a frequency report based on the session key, user ID,
   * business date, and report date.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current
   * session or user session in the application. It is used to authenticate and authorize the user's
   * actions and access to resources.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the frequency
   * report is being generated.
   * @param reportDate - The `reportDate` parameter represents the business date for which the frequency
   * report is being generated. It is used as an input to the `getTrendFrequency` function.
   * @param reportDate - The `reportDate` parameter is a date that specifies the date for which the
   * frequency report is requested.
   */

  const getFrequencyReport = (sessionKey, userId, reportDate) => {
    getTrendFrequency(
      "filetyperptdate",
      sessionKey,
      userId,
      reportDate,
      reportDate
    ).then((response) => {
      if (response) {
        setGetFrequecyType(response?.data?.KCSRPT);
      }
    });
  };

  /**
   * The function `getDeviationReport` retrieves deviation data based on session key, user ID, business
   * date, and report date, and sets the deviation data in the state.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current
   * session of the user. It is used for authentication and authorization purposes.
   * @param userId - The `userId` parameter is used to identify the user for whom the deviation report is
   * being generated. It is typically a unique identifier assigned to each user in the system.
   * @param reportDate - The `reportDate` parameter represents the business date for which the deviation
   * report is being generated. It is used as an input to the `getTrendDeviation` function to fetch the
   * deviation data for that specific date.
   * @param reportDate - The `reportDate` parameter is the date for which the deviation report is
   * requested. It is used as an argument in the `getTrendDeviation` function to retrieve the deviation
   * report for that specific date.
   */

  const getDeviationReport = (sessionKey, userId, reportDate) => {
    getTrendDeviation(
      "getdeviation",
      sessionKey,
      userId,
      reportDate,
      reportDate
    ).then((response) => {
      if (response) {
        setdeviation(response?.data?.KCSRPT);
      }
    });
  };

  /**
   * The function `getRptReport` retrieves a report name based on the session key, user ID, business
   * date, selected from date, and selected to date.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current
   * session of the user. It is used for authentication and authorization purposes.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the report is
   * being generated.
   * @param reportDate - The `reportDate` parameter represents the business date for which the report is
   * being generated.
   */

  const getRptReport = (getSelectedFromDate, getSelectedToDate) => {
    getRptName(
      "getrptype",
      sessionKey,
      userId,
      reportDate,
      getSelectedFromDate,
      getSelectedToDate
    ).then((response) => {
      if (response) {
        setGetRpt_name(response?.data?.KCSRPT);
      }
    });
  };

  /**
   * The function `getTransactionReport` retrieves transaction data based on the session key, user ID,
   * and business date, and sets the transaction data in the state variable.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the user's
   * session. It is used to authenticate and authorize the user's access to certain resources or
   * functionalities within the system.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the transaction
   * report is being generated.
   * @param reportDate - The `reportDate` parameter represents the business date for which the transaction
   * report is being generated.
   */

  const getTransactionReport = (getSelectedFromDate, getSelectedToDate) => {
    getTransactionType(
      "gettransactiontype",
      sessionKey,
      userId,
      reportDate,
      getSelectedFromDate,
      getSelectedToDate
    ).then((response) => {
      if (response) {
        setTransaction(response?.data?.KCSRPT);
      }
    });
  };

  /**
   * The function `getSubTransactionReport` retrieves a subtransaction report based on the session key,
   * user ID, business date, selected from date, and selected to date.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current user
   * session. It is used for authentication and authorization purposes.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the
   * sub-transaction report is being generated.
   * @param reportDate - The `reportDate` parameter represents the business date for which the
   * sub-transaction report is being generated.
   */

  const getSubTransactionReport = (getSelectedFromDate, getSelectedToDate) => {
    getTransactionSubType(
      "gettransactiontype",
      sessionKey,
      userId,
      reportDate,
      getSelectedFromDate,
      getSelectedToDate
    ).then((response) => {
      if (response) {
        setSubtransaction(response?.data?.KCSRPT);
      }
    });
  };

  /**
   * The `trendAnalysis` function retrieves trend analysis data based on various parameters and updates
   * the state with the response.
   */

  const trendAnalysis = () => {
    if(!getSelectedFromDate){
      NotificationManager.error(
        "From Date is Required"
      );
    } else if(!getSelectedToDate){
      NotificationManager.error(
        "To Date is Required"
      );
    } else {
      setClicked(true);
      setLoader(true);
      getTrendAnalysis(
        "trendanaysisrpt",
        sessionKey,
        userId,
        reportDate,
        getSelectedFromDate,
        getSelectedToDate,
        frequency,
        rptName,
        gettransaction,
        getsubtransaction,
        getDeviation,
        notation,
        deviator
      ).then((response) => {
        if (response) {
          setLoader(false);
          setGettredAnalysisDetails(response.data.KCSRPT);
          let tableHeading = response?.data?.KCSRPT?.details[0]
            ? Object.keys(response?.data?.KCSRPT?.details[0])
            : []
          setHeading(tableHeading);
        }
      });
    }
  };

  const cleartrendAnalysis = () => {
    setGetSelectedFromDate("");
    setGetSelectedToDate("");
    setFrequency("");
    setRptName("");
    setGettransaction("");
    setGetsubtransaction("");
    setGetDeviation("");
    setNotation("");
    setDeviator("");
  };

  return (
    <div>
      <MainLayout className="trending-report">
        <div className="layoutWrapper">
          <div className="header-wrapper">
            <h1 className="header">{Constants.trendAnlysisTxt}</h1>
            <Header setGet_date={setGet_date} get_date={get_date} />
          </div>
          <div className="content-body">
            <div className="content-header align-items-baseline">
              <div className="content-header-left flex-column align-items-start">
                <h1>
                  {Constants.reporting_date} : {reportDate}
                </h1>
              </div>

              <div className="content-header-right process_btn">
                <div className="flex-row custom-row">
                  <BackBtn />
                  <GernerateCSVFile
                    generateCSV={gettredAnalysisDetails}
                    reportName={"TrendAnalysisReport"}
                  />
                </div>
              </div>
            </div>
            <div className="main-trend-analysis">
              <div className="container d-flex trend-container">
                <div className="row calender-row">
                  <div className="col date-row">
                    <ReportIcon onClick={showFromCalendar} />
                    <input
                      type="text"
                      placeholder="Select From Date"
                      onChange={enterFromDate}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          return;
                        }
                        if (e.key.match(/[a-zA-Z]/) || !e.key.match(/^[0-9-]$/)) {
                          e.preventDefault();
                        }
                      }}
                      value={getSelectedFromDate}
                    />
                    {calendarFrom && (
                      <div className="reporting-calendar">
                        <Calendar onChange={onChangeFromDate} value={date} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row  calender-row">
                  <div className="col date-row">
                    <ReportIcon onClick={showToCalendar} />
                    <input
                      type="text"
                      placeholder="Select To Date"
                      onChange={enterToDate}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                          return;
                        }
                        if (e.key.match(/[a-zA-Z]/) || !e.key.match(/^[0-9-]$/)) {
                          e.preventDefault();
                        }
                      }}
                      value={getSelectedToDate}
                    />
                    {calendarTo && (
                      <div className="reporting-calendar">
                        <Calendar onChange={onChangeToDate} value={date} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="row custom-row">
                  <div className="custom-row custom-row-wrapper">
                    <DateIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          setCalendarFrom(false);
                          setCalendarTo(false);
                        }}
                      >
                        {frequency || "Frequency"}
                      </button>
                      <ul className="dropdown-menu">
                        {getFrequencyType?.details &&
                          getFrequencyType?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => gettrendAnalysisFrequency(index)}
                              >
                                {ele?.Pname}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container d-flex  trend-container">
                <div className="row custom-row">
                  <div className="custom-row custom-row-wrapper">
                    <TypeIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          setCalendarFrom(false);
                          setCalendarTo(false);
                        }}
                      >
                        {rptName || "RPTName"}
                      </button>
                      <ul className="dropdown-menu ">
                        {getRpt_name?.details &&
                          getRpt_name?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => gettrendAnalysisRptname(index)}
                              >
                                {ele?.RPTNAME}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row custom-row">
                  <div className="custom-row custom-row-wrapper">
                    <TypeIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          setCalendarFrom(false);
                          setCalendarTo(false);
                        }}
                      >
                        {gettransaction || "Transaction Type"}
                      </button>
                      <ul className="dropdown-menu ">
                        {transaction?.details &&
                          transaction?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() =>
                                  gettrendAnalysisTransaction(index)
                                }
                              >
                                {ele?.AS}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row custom-row">
                  <div className="custom-row custom-row-wrapper">
                    <DocumentIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          setCalendarFrom(false);
                          setCalendarTo(false);
                        }}
                      >
                        {getsubtransaction || " Sub Transaction Type"}
                      </button>
                      <ul className="dropdown-menu ">
                        {subtransaction?.details &&
                          subtransaction?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() =>
                                  gettrendAnalysisSubTransaction(index)
                                }
                              >
                                {ele["AS Main Head"]}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container d-flex  trend-container">
                <div className="row custom-row">
                  <div className="custom-row custom-row-wrapper">
                    <DocumentIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          setCalendarFrom(false);
                          setCalendarTo(false);
                        }}
                      >
                        {getDeviation || "Deviation"}
                      </button>
                      <ul className="dropdown-menu ">
                        {deviation?.details &&
                          deviation?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => gettrendAnalysisDeviation(index)}
                              >
                                {ele?.Pname}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row custom-row">
                  <div className="custom-row custom-row-wrapper">
                    <DocumentIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          setCalendarFrom(false);
                          setCalendarTo(false);
                        }}
                      >
                        {notation || " Notation"}
                      </button>
                      <ul className="dropdown-menu ">
                        <li
                          className="btn btn-secondary"
                          onClick={() => gettrendAnalysisNotation("<=")}
                        >
                          {"<="}
                        </li>
                        <li
                          className="btn btn-secondary"
                          onClick={() => gettrendAnalysisNotation(">=")}
                        >
                          {">="}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row custom-row">
                  <div className="custom-row custom-row-wrapper">
                    <input
                      onChange={getDeviatorvalue}
                      value={deviator}
                      placeholder="devvalue"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 proceed-btn">
              <button className="backBtn" onClick={trendAnalysis}>
                {Constants.processTest}
              </button>
              <button className="backBtn" onClick={cleartrendAnalysis}>
                {Constants.clearText}
              </button>
            </div>
            {clicked === true && loader === false ? (
              <div className="content-table main-report-table mt-5">
                <ResolvedApi
                  get_response={gettredAnalysisDetails}
                  heading={heading}
                  navigateToReportDetails={""}
                />
              </div>
            ) : (
              clicked === true && (
                <div className="spinner-loader">
                  <div class="spinner-border d-flex m-auto" role="status"></div>
                </div>
              )
            )}
          </div>

          <UserDetailsFooter/>

          <div className="content-footer">
            <p>{Constants.footerText}</p>
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default TrendAnalysisReport;
