import React, { useEffect } from "react";
import "./countOverlay.css";
import * as Constants from "../../../constants/stringConstants";
import { Modal, Table } from "react-bootstrap";


const CountInformation = (props) => {

  useEffect(()=>{

  },[props.infoDetails])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="count-overlay"
    >
      <Modal.Body>
        <div className="overlay-header">
          <h1>{Constants.countOverlayText}</h1>
          <button className="close-btn" onClick={props.onHide}>
            {Constants.closeBtnTxt}
          </button>
        </div>
        {props?.infoDetails?.details?.length
          === 0 ? (
          <div className="overlay-header-error">
            <h1>{Constants.countOverlayinfoText}</h1>
          </div>
        ) : (
          ""
        )}
        <div className="">
          <Table responsive>
            <thead>
              <tr>
                {props.heading
                  ? props.heading.map((title) => {
                    // Determine if the column contains numeric values
                    const isNumericColumn = props.infoDetails?.details?.every(
                      (row) => !isNaN(row[title])
                    );

                    return <th key={title}>{title}</th>;
                  })
                  : ""}
              </tr>
            </thead>
            <tbody>
              {props.infoDetails?.details &&
                props.infoDetails.details.map((row, rowIndex) => (
                  <tr key={row[rowIndex]}>
                    {Object.keys(row).map((columnName) => {
                      const value = row[columnName];
                      let formattedValue;

                      // If not a date, format as needed
                      if (columnName === "Financial Year") {
                        // Format Financial Year
                        formattedValue = `${value || "-"}`;
                      } else if (
                        columnName === "FinYear"
                          ? "FinYear"
                          : columnName === "Financial Year"
                            ? "Financial Year"
                            : columnName === "Imported On"
                              ? "Imported On	"
                              : ""
                      ) {
                        formattedValue = value || "-";
                      }  else {
                          formattedValue = value || "-";
                        }
                      

                      return (
                        <td key={columnName} className={`${!isNaN(row[columnName]) ? "count-info" : "dashed"
                        }`}>
                          <b>{formattedValue}</b>
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </tbody>
          </Table>

        </div>

      </Modal.Body>
    </Modal>
  );
};

export default CountInformation;
