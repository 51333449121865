import React, { useEffect, useState } from "react";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as FolderIcon } from "../../assets/images/Folder.svg";
import { getCurrentDateTime } from "../../utils";

const GernerateCSVFile = ({ generateCSV, reportName }) => {
  const [formattedDateTime, setFormattedDateTime] = useState("");

  useEffect(() => {
    setFormattedDateTime(getCurrentDateTime);
  }, [formattedDateTime]);


/**
 * The function `generateCsvContent` generates a CSV content string from an array of objects.
 * @returns The function `generateCsvContent` returns a string that represents the content of a CSV
 * file. The string includes a header row followed by multiple data rows, with each value separated by
 * a tilde (~) character and each row separated by a newline character (\n).
 */

  const generateCsvContent = () => {
    const headers = Object.keys(generateCSV.details[0]); // Assuming the first item has all the headers
    const headerRow = headers.join("~");

    const dataRows = generateCSV.details.map((item) => {
      const values = Object.values(item).join("~");
      return values;
    });

    return [headerRow, ...dataRows].join("\n");
  };


/**
 * The function `generateCSVfile` generates a CSV file, creates a download link for it, triggers a
 * click on the link to initiate the download, and removes the link from the DOM.
 */

  const generateCSVfile = () => {
    const csvContent = generateCsvContent();

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");

   
    link.href = window.URL.createObjectURL(blob);

    // Specify the filename for the download
    link.download = `${reportName}_${formattedDateTime}.csv`;

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger a click on the link to initiate the download
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  };

  return (
    <div>
      <button className="exportBtn" onClick={generateCSVfile}>
        {Constants.exportBtnText}
        <FolderIcon />{" "}
      </button>
    </div>
  );
};

export default GernerateCSVFile;
