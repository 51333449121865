import "./dashboard.css";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { BackBtn } from "../../components/Buttons/CustomButton";
import { formatDateToDDMMMYYYY } from "../../utils";
import { exceptionContentClick, getTotalcountContentclick } from "../../Api";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";
import { NotificationManager } from "react-notifications";

const TotalCount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [totalcount, setTotalcount] = useState([]);
  const [heading, setHeading] = useState([]);
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [getImptype, setGetImptype] = useState("");
  const [getType, setGetType] = useState("");
  const [processName, setProcessName] = useState("")

  useEffect(() => {
    console.log(location.state)
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
      } else {
        setReporteDate(getdate);
      }

      await setTotalcount(
        (location?.state?.response && location?.state?.response?.KCSRPT) || null
      );
      console.log(totalcount)
      await setHeading(
        location?.state?.response?.KCSRPT?.details[0]
          ? Object.keys(location?.state?.response?.KCSRPT?.details[0])
          : []
      );

      await setGetImptype(location?.state?.Imptype);

      await setGetType(location?.state?.type);

      await setProcessName(location?.state?.columnname);
    };
    checkAuth();
  }, []);

  /**
   * The function `getClickedValues` takes in a row index and column name, retrieves the clicked value
   * from the `totalcount` object, and performs different actions based on the `location.state.type`
   * value.
   * @param rowIndex - The rowIndex parameter represents the index of the row in the totalcount.details
   * array that was clicked.
   * @param colname - The `colname` parameter is a string that represents the name of a column in a
   * table.
   */

  const getClickedValues = (rowIndex, colname) => {
    const clickedRow = totalcount?.details[rowIndex];
    const clickedValue = clickedRow[colname];
    if (clickedValue !== "0" && location?.state?.type === "MASTER") {
      if (location?.state?.columnname === "RELATED PARTY MASTER") {
        getTotalcountContentclick(
          "basefilecanc",
          sessionKey,
          userId,
          reportDate,
          reportDate,
          location?.state?.Imptype,
          colname,
          totalcount?.details[rowIndex]["Related party name"]
        ).then((response) => {
          if (response) {
            navigate("/dashboard/total-count-content", {
              state: response?.data?.KCSRPT,
            });
          }
        });
      }
    } else if (location.state.type === "PROCESS") {
      exceptionContentClick(
        "basefilecanc",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        location?.state?.Imptype,
        colname,
        totalcount?.details[rowIndex]?.RPTName,
        clickedValue
      ).then((response) => {
        if (response) {
          navigate("/dashboard/total-count-content", {
            state: response?.data?.KCSRPT,
          });
        }
      });
    }
  };

  /**
   * The function `navigateToIngoreException` navigates to a specific dashboard page with some state
   * parameters.
   */

  const navigateToIngoreException = () => {
    navigate("/dashboard/ignore-total-count", {
      state: {
        IMPTYPE: getImptype,
        dashboardtype: getType,
        fromcount: "true",
        type: location?.state?.type,
        action: location?.state?.action,
      },
    });
  };

  /**
   * The function `getAccountReceivableReport` navigates to a specific page with some state parameters.
   */

  const getAccountReceivableReport = () => {
    navigate("/dashboard/payment-receivable", {
      state: {
        IMPTYPE: getImptype,
        dashboardtype: getType,
        fromcount: "true",
        type: location?.state?.type,
        action: location?.state?.action,
      },
    });
  };

  const navigateToExceptionCount = () => {
    if (location?.state?.exceptionCount === "0") {
      NotificationManager.error("No records available in exception")
    } else {
      navigate("/dashboard/exception-count", {
        state: {
          Imptype: getImptype,
          columnname: location?.state?.columnname,
          type: "PROCESS",
        },
      });
    }
  };

  return (
    <MainLayout>
      <div className="total-count-layoutWrapper">
        <div className="content-body">
          <div className="content-header pb-4">
            <div className="content-header-left">
              <h1>{processName || ""}</h1>
              {/* <h1>{(location && location.state && location.state.columnname) || Constants.totalCountText}</h1> */}
              <Form>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="">
                    <Form.Check
                      inline
                      label="Pending "
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label="Authorised"
                      name="group1"
                      type={type}
                      id={`inline-${type}-2`}
                    />
                    <Form.Check
                      inline
                      label="Cancelled Pending"
                      name="group1"
                      type={type}
                      id={`inline-${type}-3`}
                    />
                  </div>
                ))}
              </Form>
            </div>
            <div className="content-header-right">
              <BackBtn />
            </div>
          </div>
          <div className="filter-row">
            <div className="content-header-left"></div>
            <div className="content-header-right">
              <GernerateCSVFile
                generateCSV={totalcount}
                reportName={"TotalCountReport"}
              />
            </div>
          </div>

          <div className="content-table main-report-table ">
            <div className="table-container">
              <table className="table">
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                  <tr>
                    {heading
                      ? heading.map((title) => {
                        return (
                          <th key={title} className="heading_font">
                            {title}
                          </th>
                        );
                      })
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {totalcount?.details &&
                    totalcount.details.map((row, rowIndex) => (
                      <tr key={row[rowIndex]}>
                        {Object.keys(row).map((columnName) => {
                          const value = row[columnName];
                          let formattedValue = value; // Initialize formattedValue with the original value

                          // Check if the value is a valid date and not a number
                          const dateValue = new Date(value);
                          if (
                            !isNaN(dateValue.getDate()) &&
                            isNaN(Number(value))
                          ) {
                            // Format the date as you desire
                            const options = {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            };

                            formattedValue = formatDateToDDMMMYYYY(
                              dateValue.toLocaleDateString("en-US", options)
                            );
                          } else if (
                            columnName === "Financial Year" ||
                            columnName === "FinYear" ||
                            columnName === "Financial Year" ||
                            columnName === "Imported On"
                          ) {
                            // If not a date and matches specific column names, format as needed
                            formattedValue = value || "-";
                          } else if (
                            (columnName === "GL CODE" || columnName === "GLCode") &&
                            !isNaN(parseFloat(value))
                          ) {
                            // Remove .00 if it's a whole number
                            formattedValue =
                              parseFloat(value) % 1 === 0
                                ? parseFloat(value).toFixed(0)
                                : value;
                          } 
                          else if (
                            columnName === "Keyword" &&
                            !isNaN(parseFloat(value))
                          ) {
                            // Remove .00 if it's a whole number
                            formattedValue =
                              parseFloat(value) % 1 === 0
                                ? parseFloat(value).toFixed(0)
                                : value;
                          } 
                          else if (
                            columnName === "Amount" &&
                            !isNaN(parseFloat(value))
                          ) {
                            // Remove .00 if it's a whole number
                            // formattedValue =
                            //   parseFloat(value) % 1 === 0
                            //     ? parseFloat(value).toFixed(0)
                            //     : value;
                            const numericValue = parseFloat(value);
                            if (!isNaN(numericValue)) {
                              formattedValue = numericValue.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              );
                            }
                          } 
                          else {
                            // Handle other columns, e.g., numeric formatting
                            const numericValue = parseFloat(value);
                            if (!isNaN(numericValue)) {
                              formattedValue = numericValue.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }
                              );
                            }
                          }

                          // Conditionally apply a class for left alignment if it's a date
                          const isDate =
                            !isNaN(dateValue.getDate()) && isNaN(Number(value));
                          const tdClassName = ` ${isDate ||
                          columnName === "Keyword" ||
                          columnName === "Total count of Account number" ||
                          columnName === "Total count of Customer id" || 
                          columnName === "Name Count"
                           ? "left_align" : columnName === "Amount" ? "right_align": ""}`;

                          return (
                            <td
                            style={{
                              whiteSpace: "normal"
                            }}
                              className={`${!isNaN(row[columnName])
                                  ? "main_report"
                                  : "dashed"
                                }${tdClassName}`}
                              key={columnName}
                            >
                              <b onClick={() =>
                                getClickedValues(rowIndex, columnName)
                              }>{formattedValue}</b>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="summary-row">
            {totalcount?.summary &&
              totalcount?.summary?.map((summary, i) => {
                return (
                  <div className="summary-data" key={summary[i]}>
                    { }
                    <p style={{ fontWeight: "bold" }}>
                      {summary?.exception ? summary?.exception : "-"}
                    </p>
                    <p>{summary?.summary ? summary?.summary : "-"} </p>
                    <div className="count-btns">
                      {summary?.lnkIgnoreExcep === "YES" ? (
                        <button
                          className="backBtn mt-3"
                          onClick={navigateToIngoreException}
                        >
                          {Constants.ignoreException}
                        </button>
                      ) : (
                        ""
                      )}
                      {summary?.exception_view === "YES" ? (
                        <button
                          className={
                            summary?.exception_view == ""
                              ? "exceprtion-hide"
                              : "backBtn mt-3"
                          }
                          onClick={navigateToExceptionCount}
                        >
                          {Constants.exceptionView}
                        </button>
                      ) : (
                        ""
                      )}
                      {summary?.lnkFinalSummary !== "" ? (
                        <button
                          onClick={getAccountReceivableReport}
                          className={
                            summary?.lnkFinalSummary == undefined
                              ? "exceprtion-hide"
                              : "backBtn mt-3"
                          }
                        >
                          {summary?.lnkFinalSummary}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default TotalCount;
