import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { BackBtn } from "../../components/Buttons/CustomButton";
import {
  getFiletyperptdate,
  getReimportReport,
  getReportingDateList,
} from "../../Api";
import ResolvedApi from "../../components/api/ResolvedApi";
import { NotificationManager } from "react-notifications";
import { ReactComponent as TypeIcon } from "../../assets/images/Category.svg";
import { ReactComponent as ScanIcon } from "../../assets/images/Scan.svg";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const ReimportReport = () => {
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [reimportInfo, setReimportInfo] = useState([]);
  const [heading, setHeading] = useState("");
  const [count, setCount] = useState("");
  const [reportingDate, setReportingDate] = useState([]);
  const [getFileType, setGetFileType] = useState([]);
  const [file, setFile] = useState("");
  const [get_repotingDate, setGet_reportingDate] = useState("");
  const [get_date, setGet_date] = useState("");
  const [, setIsProcess] = useState("false");
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        // await get_ReimportReportDetails(getSessionId, getUserId, userDate);

        await getReportingList(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        // await get_ReimportReportDetails(getSessionId, getUserId, getdate);

        await getReportingList(getSessionId, getUserId, getdate);
      }
    };
    checkAuth();
  }, [get_date]);

  useEffect(() => {
    reimportInfo.details &&
      reimportInfo?.details?.forEach((item, index) => {
        let totalCount = index + 1;
        return setCount(totalCount);
      });
  }, [reimportInfo]);

  const getReportingList = (sessionKey, userId, reportDate) => {
    getReportingDateList(
      "rptdatemst",
      sessionKey,
      userId,
      reportDate,
      "LIST",
      "",
      "",
      ""
    ).then((response) => {
      if (response) {
        setReportingDate(response.data.KCSRPT);
      }
    });
  };

  const getReportingDateDeatils = (index) => {
    setGet_reportingDate(reportingDate.details[index]["Reporting Date"]);
    getFiletyperptdate(
      "filetyperptdate",
      sessionKey,
      userId,
      reportDate,
      reportingDate.details[index]["Reporting Date"]
    ).then((response) => {
      if (response) {
        setGetFileType(response.data.KCSRPT);
      }
    });
  };

  const getfileDeatils = (index) => {
    setFile(getFileType.details[index].ImpType);
  };


  /**
   * The function `getReimportReportDetails` retrieves a reimport report using a session key, user ID, and
   * report date, and then sets the reimport information and table heading based on the response.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current user
   * session. It is used for authentication and authorization purposes.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the reimport
   * details are being fetched.
   * @param reportDate - The `reportDate` parameter is a date that specifies the date of the report. It
   * is used as a parameter in the `getReimportReport` function to retrieve a reimport report for a
   * specific date.
   */

  const getReimportReportDetails = () => {
    setIsProcess(true);
    setLoader(true)
    getReimportReport(
      "auditrpt",
      sessionKey,
      userId,
      reportDate,
      get_repotingDate || reportDate,
      file || "DP"
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
        setLoader(false)
        NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg);
      } else {
        setLoader(false)
        setReimportInfo(response.data && response?.data?.KCSRPT);
        let tableHeading = response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : []
        setHeading(tableHeading);
      }
    });
  };

  const cleartReimportReportDetails = () => {
    setGet_reportingDate("");
    setFile("");
    setReimportInfo([])
    setHeading("")
    setCount("")
  };

  const get_ReimportReportDetails = (sessionKey, userId, reportDate) => {
    setIsProcess(false);
    setLoader(true)
    getReimportReport(
      "auditrpt",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      "DP"
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
        setLoader(false)
        NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg);
      } else {
        setLoader(false)
        setReimportInfo(response.data && response?.data?.KCSRPT);
        let tableHeading = response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : []
        setHeading(tableHeading);
      }
    });
  };

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header"> {Constants.misReimportreport} </h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header align-items-baseline">
            <div className="content-header-left flex-column align-items-start">
              <h1>
                {Constants.reporting_date} : {reportDate}
              </h1>
              <div className="custom-row gx-5 custom-body">
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <TypeIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {get_repotingDate || reportDate}
                      </button>
                      <ul className="dropdown-menu ">
                        {reportingDate?.details &&
                          reportingDate?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => getReportingDateDeatils(index)}
                              >
                                {ele["Reporting Date"]}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {file || "Type"}
                      </button>
                      <ul className="dropdown-menu ">
                        {getFileType?.details &&
                          getFileType?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => getfileDeatils(index)}
                              >
                                {ele?.ImpType}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-header-right process_btn">
              <div className="flex-row custom-row">
                <GernerateCSVFile
                  generateCSV={reimportInfo}
                  reportName={"ReimportReport"}
                />
                <BackBtn />
              </div>
              <div className="d-flex gap-2">
                <button className="backBtn" onClick={getReimportReportDetails}>
                  {Constants.processTest}
                </button>
                <button
                  className="backBtn"
                  onClick={cleartReimportReportDetails}
                >
                  {Constants.clearText}
                </button>
              </div>
            </div>
          </div>
          {
            loader === false ?
              <div className="content-table reporting-table">
                <ResolvedApi
                  get_response={reimportInfo}
                  heading={heading}
                  navigateToReportDetails={""}
                />
              </div>
              : <div className="spinner-loader">
                <div class="spinner-border d-flex m-auto" role="status"></div>
              </div>
          }
          <h5 className="mt-3">
            <b>Summary:</b> <span>No of Entries {count}</span>{" "}
          </h5>
        </div>
        <UserDetailsFooter/>
        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default ReimportReport;
