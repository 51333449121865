import React, { useState, useEffect } from "react";
import { Modal, FloatingLabel, Form } from "react-bootstrap";
import "./modal.css";
import * as Constants from "../../../constants/stringConstants";
import { getDashboardExceptionCount, getExceptionIgnore } from "../../../Api";
import { NotificationManager } from "react-notifications";

const ExceptionOverlay = (props) => {

    const [sessionKey, setSessionKey] = useState("");
    const [userId, setUserId] = useState("");
    const [reportDate, setReporteDate] = useState("");
    const [comment, setComment] = useState("");

    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getdate = await localStorage.getItem("reporting_date");
            setReporteDate(getdate);
        };
        checkAuth();
    }, []);

    const handleChange = (e) => {
        setComment(e.target.value);
    };

    console.log('>>>>>>>>>>>', props.getignore)

    const ignoreException = () => {
        getExceptionIgnore(
            "Exception Show",
            sessionKey,
            userId,
            reportDate,
            reportDate,
            props.dashboardType,
            props.gettype,
            "",
            comment,
            props.getvalues,
            props.getignore === "true" ? "update" : ""
        ).then((response) => {
            if (response.data.KCSRPT.details[0].ErrorMsg) {
                NotificationManager.error(response.data.KCSRPT.details[0].ErrorMsg)
                props.onHide()
                getDashboardExceptionCount(
                    "Exception Show",
                    sessionKey,
                    userId,
                    reportDate,
                    reportDate,
                    props.dashboardType,
                    props.gettype,
                    "",
                    props.getignore === "true" ? "ignore" : ""
                ).then(response => {
                    if (response) {
                        props.ignoredException(response.data.KCSRPT)
                    }
                })
            } else {
                props.onHide()
            }
        });
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="ignoreException-overlay"
        >
            <Modal.Body>
                <h2>{Constants.ignoreOverlayText}</h2>
                <p>{Constants.reasonBtnText}</p>
                <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Type your reason here..."
                >
                    <Form.Control
                        as="textarea"
                        placeholder="Type your reason here..."
                        className="modalTextArea"
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <div className="btn-row">
                    <button className="cancelBtn modalBtn" onClick={props.onHide}>
                        {Constants.cancelBtnText}
                    </button>
                    <button
                        className="ignoreBtn modalBtn"
                        onClick={() => ignoreException()}
                    >
                        {Constants.ignoreBtnText}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ExceptionOverlay;
