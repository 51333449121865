import React from "react";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import LayoutBtn from "../../components/LayoutBtn/LayoutBtn";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import { ReactComponent as MISIcon } from "../../assets/images/Activity_one.svg";
import { ReactComponent as ReportIcon } from "../../assets/images/Calendar.svg";

const MIS = () => {
  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header"> {Constants.misTitle} </h1>
          <Header />
        </div>
        <div>
          <LayoutBtn
            Icon={<InfoIcon />}
            title="Trend Analysis Report"
            path="trend-analysis-report"
          />
          <LayoutBtn Icon={<MISIcon />} title="MIS Report" />
          <LayoutBtn
            Icon={<ReportIcon />}
            title="QoQ Comparison Report"
            path=""
          />
          <LayoutBtn Icon={<ReportIcon />} title="Reimport Report" path="" />
        </div>
      </div>
    </MainLayout>
  );
};

export default MIS;
