import React from 'react'
import MainLayout from '../../components/Layout/MainLayout'

const Master = () => {
  return (
    <MainLayout>
           <div>Master</div>
    </MainLayout>
  )
}

export default Master