import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { get_AS_level1_parameters } from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const ReportDetails = () => {
  const navigate = useNavigate();
  const [, setSessionKey] = useState("");
  const [, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [getReports, setGetReports] = useState([]);
  const [heading, setHeading] = useState();
  const [reportDate, setReporteDate] = useState("");
  const location = useLocation();
  const selectedReportData = location.state ? location.state : "";
  const [getCount, setGetCount] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [content,] = useState("false");
  const [, setFinancialYear] = useState("")
  const [get_date, setGet_date] = useState("");
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      let getFinancialYear = localStorage.getItem("Financial_year")
      setFinancialYear(getFinancialYear)

      if (userDate) {
        setReporteDate(userDate);
        await getLevel1paramaters(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        await getLevel1paramaters(getSessionId, getUserId, getdate);
      }

    };
    checkAuth();
  }, [get_date]);





  useEffect(() => {
    let count =
      (getReports?.details &&
        getReports?.details?.map((ele) => Number(ele.TotalAmt))) ||
      [];
    // This will create an array with the TotalAmt property of each object converted to a number

    let Total_Amount = count.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setGetCount(Total_Amount);
  }, [getReports]);



  const getLevel1paramaters = (sessionKey, userId, reportDate) => {
    if (selectedReportData) {
      setLoader(true)
      get_AS_level1_parameters(
        "as18rptlevel1",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        "AS",
        "AS MAIN REPORT",
        selectedReportData?.reportingHead && selectedReportData.reportingHead,
        "",
        selectedReportData?.reportType && selectedReportData.reportType,
        selectedReportData?.reportingAmt && selectedReportData.reportingAmt,
        selectedReportData?.reportingYear && selectedReportData.reportingYear
      )
        .then((res) => {
          if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
            setLoader(false)
            NotificationManager.error(res?.data?.KCSRPT?.details[0].ErrorMsg);
          } else if (res?.data?.KCSRPT?.details[0].REM) {
            setLoader(false)
            NotificationManager.error(res?.data?.KCSRPT?.details[0].REM);
          } else {
            setLoader(false)
            setGetReports(res.data && res?.data?.KCSRPT);
            let tableHeading = Object.keys(res?.data?.KCSRPT?.details[0]);
            setHeading(tableHeading);
          }
        })
        .catch((err) => {
          setLoader(false)
          handleError(err);
        });
    }
  };

  /**
   * navigateTranscationDetails :: Redirect to Single Transcation Details
   */

  const navigateTranscationDetails = (rowIndex, columnName) => {
    const clickedRow = getReports.details[rowIndex];
    const clickedValue = clickedRow[columnName];

    if (
      columnName === "Variance"
    ) {
      // No action needed for these conditions
    } else if (clickedValue === "0.00000") {
      // No action needed for these conditions
    } else if (!isNaN(+clickedValue) === true) {
      navigate("/dashboard/AS/single-report-details", {
        state: {
          getDetails: clickedRow,
          colvalue: clickedValue,
          colname: columnName,
        },
      });
    }
  };



  const closeLogoutModal = () => {
    setModalShow(false);
  };


  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header"> {Constants.asreportlevel1} </h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left">
              <h1>
                {Constants.reportingDateTime}
                {reportDate}
              </h1>
            </div>
            <div className="content-header-right ">

              <h3 className='total-amt'>{Constants.totalAmt}{getCount.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}</h3>

              <GernerateCSVFile generateCSV={getReports} reportName={"AS18Level1"} />

              <button className="back-btn" onClick={() => navigate(-1)}>
                {Constants.backBtnText}
              </button>
            </div>
          </div>
          {
            loader === false ?
              <div className="content-table main-report-table">

                <ResolvedApi
                  get_response={getReports}
                  heading={heading}
                  navigateToReportDetails={navigateTranscationDetails}
                />

              </div>
              : <div className="spinner-loader">
                <div class="spinner-border d-flex m-auto" role="status"></div>
              </div>
          }

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="main-overlay"
            show={modalShow}
          >
            <Modal.Body>
              <div className="overlay-content">
                {/* <p>{Constants.logoutText}</p> */}
                <img
                  className="main-report-img"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5OX4SWB9IYae_lMyOo51cnCrsFJddaQJwFnL6L-Q&s"
                  alt=""
                  srcset=""
                />
                {content === true ? (
                  <h4 className="modal-main-report">
                    {" "}
                    {Constants.maxpopupText}
                  </h4>
                ) : (
                  <h4 className="modal-main-report">
                    {Constants.mainpopupText}
                  </h4>
                )}
              </div>

              <button onClick={closeLogoutModal} className="closeBtn">
                {Constants.cancelBtnText}
              </button>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </MainLayout>
  );
};

export default ReportDetails;
