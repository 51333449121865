import "./As.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as ScanIcon } from "../../assets/images/Scan.svg";
import { ReactComponent as TypeIcon } from "../../assets/images/Category.svg";
import {
  getASFileSubtype,
  getASFiletype,
  getASMultipleof,
  getASparameters,
  getReportingDateList,
  get_significant,
} from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const MainReport = () => {
  const navigate = useNavigate();
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [getASReports, setGetASReports] = useState([]);
  const [reportDate, setReporteDate] = useState("");
  const [heading, setHeading] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [content, setContent] = useState("false");
  const [reportingDate, setReportingDate] = useState([]);
  const [getFileType, setGetFileType] = useState([]);
  const [getFileSubType, setGetFileSubType] = useState([]);
  const [getMultipleof, setGetMultipleof] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedfile, setSelectedfile] = useState("");
  const [selectedSubfile, setSelectedSubfile] = useState("");
  const [selectedMultiples, setSelectedMultiples] = useState("");
  const [summary, setSummary] = useState([]);
  const [file_type, setFile_type] = useState("false");
  const [sub_type, setSub_type] = useState("false");
  const [get_date, setGet_date] = useState("")
  const [loader, setLoader] = useState(true)

  /* The `useEffect` hook is used to perform side effects in functional components. In this case, the
  `useEffect` hook is used to retrieve session key, user ID, and branch code from the local storage
  and set them as state variables (`sessionKey`, `userId`, `branchCode`). */

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        await get_All_AS_parameters(getSessionId, getUserId, userDate);

        await getReportingList(getSessionId, getUserId, userDate);

        await getReportingMultipleOf(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        await get_All_AS_parameters(getSessionId, getUserId, getdate);

        await getReportingList(getSessionId, getUserId, getdate);

        await getReportingMultipleOf(getSessionId, getUserId, getdate);
      }
    };
    checkAuth();
  }, [get_date]);

  const closeLogoutModal = () => {
    setModalShow(false);
  };

  const getReportingList = (sessionKey, userId, reportDate) => {
    getReportingDateList(
      "rptdatemst",
      sessionKey,
      userId,
      reportDate,
      "LIST",
      "",
      "",
      ""
    ).then((response) => {
      if (response) {
        setReportingDate(response.data.KCSRPT);
      }
    });
  };

  const getSelectedDate = (index) => {
    setSelectedDate(reportingDate.details[index]["Reporting Date"]);
    getReportingDateDeatils(reportingDate.details[index]["Reporting Date"]);
  };

  const getReportingMultipleOf = (sessionKey, userId, reportDate) => {
    getASMultipleof(
      "multipleoff",
      sessionKey,
      userId,
      reportDate,
      reportDate
    ).then((response) => {
      if (response) {
        setGetMultipleof(response.data.KCSRPT);
      }
    });
  };

  const getSelectedMultipes = (index) => {
    setSelectedMultiples(getMultipleof.details[index].Pcode1);
  };

  const getReportingDateDeatils = (selectedDate) => {
    getASFiletype(
      "reportingtype",
      sessionKey,
      userId,
      reportDate,
      selectedDate
    ).then((response) => {
      if (response) {
        setGetFileType(response.data.KCSRPT);
      }
    });
  };

  const getSelectedFile = (index) => {
    setFile_type(true);
    setSub_type(false);
    setSelectedfile(getFileType?.details[index]?.Pname);
    getReportingFiletype(getFileType?.details[index]?.Pname);
  };

  const getReportingFiletype = (file) => {
    getASFileSubtype(
      "reportingsubtype",
      sessionKey,
      userId,
      reportDate,
      file
    ).then((response) => {
      if (response) {
        setGetFileSubType(response.data.KCSRPT);
      }
    });
  };

  const getSelectedSubFile = (index) => {
    setSub_type(true);
    setFile_type(false);
    setSelectedSubfile(getFileSubType.details[index].pcode1);
  };

  const [, setIsProcess] = useState("false");

  /**
   * The function `getAllASparameters` retrieves all parameters for a given session key and user ID from
   * an AS (Application Server) and handles any errors that occur.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents a user's session
   * or login session. It is used to authenticate and authorize the user's access to certain resources or
   * functionalities within the system.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the AS
   * parameters are being retrieved.
   */

  const getAllASparameters = () => {
    setIsProcess(true);
    setLoader(true)
    getASparameters(
      "AS",
      sessionKey,
      userId,
      reportDate,
      selectedDate || reportDate,
      selectedfile || "AS",
      selectedSubfile || "AS MAIN REPORT",
      selectedMultiples || "1"
    )
      .then((response) => {
        if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
          setLoader(false)
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0]?.ErrorMsg
          );
        } else {
          setLoader(false)
          setGetASReports(response?.data?.KCSRPT);
          setSummary(response?.data?.KCSRPT.summary);
          let tableHeading = response?.data?.KCSRPT?.details[0]
            ? Object.keys(response?.data?.KCSRPT?.details[0])
            : []
          setHeading(tableHeading);
        }
      })
      .catch((err) => {
        setLoader(false)
        handleError(err);
      });
  };

  const get_All_AS_parameters = (sessionKey, userId, reportDate) => {
    setIsProcess(false);
    setLoader(true)
    getASparameters(
      "AS",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      "AS",
      "AS MAIN REPORT",
      "1"
    )
      .then((response) => {
        if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
          setLoader(false)
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0]?.ErrorMsg
          );
        } else {
          setLoader(false)
          setGetASReports(response?.data?.KCSRPT);
          setSummary(response?.data?.KCSRPT.summary);
          let tableHeading = response?.data?.KCSRPT?.details[0]
            ? Object.keys(response?.data?.KCSRPT?.details[0])
            : []
          setHeading(tableHeading);
        }
      })
      .catch((err) => {
        setLoader(false)
        handleError(err);
      });
  };

  /**
   * The function `getall_Significant_parameters` retrieves significant parameters for a report and
   * navigates to a specific dashboard page with the response data.
   */

  const getall_Significant_parameters = () => {
    get_significant(
      "auditrpt",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      "AS",
      "",
      "1000"
    ).then((response) => {
      if (
        response?.data?.KCSRPT &&
        response?.data?.KCSRPT?.details[0]?.ErrorMsg
      ) {
        NotificationManager.error(response?.data?.KCSRPT?.details[0]?.ErrorMsg);
      } else {
        navigate("/dashboard/AS/significant-report", {
          state: response.data && response?.data,
        });
      }
    });
  };

  /**
   * The function `navigateToReportDetails` makes an API call to retrieve report details and then
   * navigates to the "/dashboard/AS/report-details" page if the response is successful.
   */

  const navigateToReportDetails = (rowIndex, columnName) => {
    const clickedRow = getASReports.details[rowIndex];
    const clickedValue = clickedRow[columnName];

    if (clickedRow[columnName].includes("MAX") === true) {
      setContent(true);
      openModal();
    } else if (
      columnName === "AS18 Reporting Head" &&
      columnName !== "Financial Year"
    ) {
      openModal();
    } else if (columnName === "Financial Year") {
      // No action needed for these conditions
    } else if (clickedRow[columnName] === "") {
      // No action needed for these conditions
    } else {
      navigate("/dashboard/AS/report-details", {
        state: {
          reportingHead: clickedRow["AS18 Reporting Head"],
          reportingYear: clickedRow["Financial Year"],
          reportType: columnName,
          reportingAmt: clickedValue,
        },
      });
    }
  };

  const openModal = () => {
    setModalShow(!modalShow);
  };

  let buttonText = "Select Report Sub Type"; // Default value

  if (file_type === true && sub_type === false) {
    buttonText = "File Sub type";
  } else if (sub_type === true && file_type === false) {
    buttonText = selectedSubfile;
  }

  const formatIndianNumber = (number) => {
    return Number(number).toLocaleString('en-IN', {
      maximumFractionDigits: 2 // Adjust this as needed
    });
  };

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header">{Constants.reportText}</h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header align-items-baseline">
            <div className="content-header-left flex-column align-items-start">
              <h1>
                {Constants.reportingDateTime}
                {reportDate}
              </h1>
              <div className="custom-row gx-5 custom-body">
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <TypeIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedDate || "Reporting Date"}
                      </button>
                      <ul className="dropdown-menu ">
                        {reportingDate?.details &&
                          reportingDate?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => getSelectedDate(index)}
                              >
                                {ele["Reporting Date"]}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedfile || "Select Report Type"}
                      </button>
                      <ul className="dropdown-menu ">
                        {getFileType?.details &&
                          getFileType?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => getSelectedFile(index)}
                              >
                                {ele.Pname}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div className="input-group">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {buttonText}
                      </button>
                      <ul className="dropdown-menu">
                        {getFileSubType?.details &&
                          getFileSubType?.details?.map((ele, index) => (
                            <li
                              key={ele[index]}
                              className="btn btn-secondary"
                              onClick={() => getSelectedSubFile(index)}
                            >
                              {ele.pcode1}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        style={{
                          width:"160px"
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedMultiples || "Multiple Of"}
                      </button>
                      <ul className="dropdown-menu ">
                        {getMultipleof?.details &&
                          getMultipleof?.details?.map((ele, index) => {
                            return (
                              <li
                                key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => getSelectedMultipes(index)}
                              >
                                {ele.Pname}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-header-right process_btn">
              <div className="flex-row custom-row">
                <GernerateCSVFile generateCSV={getASReports} reportName={"AS18MainReport"} />
                <button className="back-btn" onClick={() => navigate(-1)}>
                  {Constants.backBtnText}
                </button>
              </div>
              <button className="backBtn" onClick={getAllASparameters}>
                {Constants.processTest}
              </button>
            </div>
          </div>
          <h4 className="ms_report_msg">{Constants.datapreparation}</h4>
          {
            loader === false ?
              <div className="content-table main-report-table ">
                <ResolvedApi
                  get_response={getASReports || null}
                  heading={heading}
                  navigateToReportDetails={navigateToReportDetails}
                />

                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="main-overlay"
                  show={modalShow}
                >
                  <Modal.Body>
                    <div className="overlay-content">
                      {/* <p>{Constants.logoutText}</p> */}
                      <img
                        className="main-report-img"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5OX4SWB9IYae_lMyOo51cnCrsFJddaQJwFnL6L-Q&s"
                        alt=""
                        srcset=""
                      />
                      {content === true ? (
                        <h4 className="modal-main-report">
                          {" "}
                          {Constants.maxpopupText}
                        </h4>
                      ) : (
                        <h4 className="modal-main-report">
                          {Constants.mainpopupText}
                        </h4>
                      )}
                    </div>

                    <button onClick={closeLogoutModal} className="closeBtn">
                      {Constants.cancelBtnText}
                    </button>
                  </Modal.Body>
                </Modal>
              </div>
              : <div className="spinner-loader">
                <div class="spinner-border d-flex m-auto" role="status"></div>
              </div>
          }
          <div className={summary === undefined ? "mt-5" : "d-flex mt-5"}>
            <p className="summary_data">Amount as per Summary Data - </p>
            <div className="summary_text">
              {summary?.map((summaryItem) => (
                <div key={summaryItem.ReportName}>
                  <p className="summary_data">
                    {summaryItem.ReportName}: {formatIndianNumber(summaryItem.ReportAmount)}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-success"
              onClick={getall_Significant_parameters}
            >
              Significant
            </button>
          </div>
        </div>
        <UserDetailsFooter/>
      </div>
    </MainLayout>
    
  );
};

export default MainReport;
