import "./dashboard.css";
import React, { useState, useEffect, useRef } from "react";
import { Table, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import CorrectIcon from "../../assets/images/correct.png";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/More.svg";
import { ReactComponent as SortIcon } from "../../assets/images/sort.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/Search.svg";
import { ReactComponent as Filtercon } from "../../assets/images/filter.svg";
import { ReactComponent as DropDownIcon } from "../../assets/images/dropdown.svg";
import { ReactComponent as TypeIcon } from "../../assets/images/Category-fill.svg";
import { ReactComponent as ProcessIcon } from "../../assets/images/Star.svg";
import { ReactComponent as StandardIcon } from "../../assets/images/Bookmark-fill.svg";
import ActionOverlay from "../../components/Overlay/ServiceActionModal/ActionOverlay";
import CountInformation from "../../components/Overlay/CountInformation/CountInformation";
import {
  getDashboard,
  getUploadFiles,
} from "../../Api";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import { handleError } from "../../Services/ErrorService";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const Standard = () => {
  const [countModalShow, setCountModalShow] = useState(false);
  const [actionOverlay, setActionOverlay] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [showsorting, setShowSorting] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [showfilter, setShowFilter] = useState(false);
  const [serviceMenu, setServiceMenu] = useState(false);
  const navigate = useNavigate();
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [processDashboard, setProcessDashboard] = useState([]);
  const [heading, setHeading] = useState();
  const [reportDate, setReporteDate] = useState("");
  const location = useLocation();
  const selectedReportData = location.state ? location.state : "";
  const [getCount, setGetCount] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [content] = useState("false");
  const [, setFinancialYear] = useState("");
  const [getprocessName, setGetprocessName] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [logFiles, setLogFiles] = useState([]);
  const [process_name, setProcess_name] = useState("");

  /**
   * handleCountModal :: Handle/Toggle state for Count Model
   */
  const handleCountModal = () => {
    setCountModalShow(true);
  };

  /**
   * navigateToTotalCount :: Redirect to total count page
   */
  const navigateToTotalCount = () => {
    navigate("total-count");
  };

  /**
   * navigateToExceptionCount :: Redirect to exception count page
   */
  const navigateToExceptionCount = () => {
    navigate("exception-count");
  };

  /**
   * handleViewLog :: Redirect to View log page
   */
  const handleViewLog = (index) => {
    navigate("/dashboard/viewlog", {
      state: {
        logFiles: processDashboard,
        getprocessName: index
      },
    });
  };

  /**
   * showActionOverlay ::  Show Action overlay popup
   * @param {*} id
   */
  const showActionOverlay = (id) => {
    setActionOverlay(true);
    setActionId(id);
  };

  /**
   * hideActionOverlay :: Hide Acction Overlay popup
   */
  const hideActionOverlay = () => {
    setActionOverlay(false);
    setActionId(null);
  };

  /**
   * navigateToProcess :: Redirect to Process page
   */
  const navigateToProcess = () => {
    navigate("/dashboard/process");
  };

  /**
   * navigateToStandard :: Redirect to Standard Page
   */
  const navigateToStandard = () => {
    navigate("/dashboard/standard");
  };

  /**
   * toggleServiceMenu :: Toggle Service Menu
   */
  const toggleServiceMenu = () => {
    setServiceMenu(!serviceMenu);
  };

  /**
   * After 5 seconds hide action popup
   */
  setTimeout(hideActionOverlay, 5000);

  const popupRef = useRef(null);
  const filterRef = useRef(null);

  const options = [
    { label: "Authorised Successfully", value: "success" },
    { label: "Import Failed with Error", value: "failed" },
    { label: "Import Sucess, Exp Pending", value: "Exp_Pending" },
    { label: "Import Sucess, Aut Pending", value: "Auth_Pending" },
  ];

  const checkboxes = options.map((option) => (
    <Form.Check
      key={option.value}
      type="checkbox"
      label={option.label}
      value={option.value}
      checked={checkboxValues.includes(option.value)}
      onChange={(e) => {
        const isChecked = e.target.checked;
        const value = e.target.value;
        setCheckboxValues((prevValues) =>
          isChecked
            ? [...prevValues, value]
            : prevValues.filter((v) => v !== value)
        );
      }}
    />
  ));

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowSorting(false);
      } else if (
        filterRef.current &&
        !filterRef.current.contains(event.target)
      ) {
        setShowFilter(false);
      } else {
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let getdate = await localStorage.getItem("reporting_date");
      setReporteDate(getdate);

      let getFinancialYear = localStorage.getItem("Financial_year");
      setFinancialYear(getFinancialYear);

      await getDashboardparamaters(
        getSessionId,
        getUserId,
        getdate,
        getFinancialYear
      );
    };
    checkAuth();
  }, []);

  const getDashboardparamaters = (
    sessionKey,
    userId,
    reportDate,
    financialYear
  ) => {
    getDashboard(
      "processlist",
      sessionKey,
      userId,
      reportDate,
      financialYear,
      reportDate,
      "standard",
      ""
    )
      .then((res) => {
        if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
          NotificationManager.error(res?.data?.KCSRPT?.details[0].ErrorMsg);
        } else {
          setProcessDashboard(res.data && res?.data?.KCSRPT);
          let tableHeading = Object.keys(res?.data?.KCSRPT?.details[0]);
          setHeading(tableHeading);
          setGetprocessName(res?.data?.KCSRPT?.details[0].IMPType);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(() => {
    console.log("Selected file:", selectedFile);
  }, [selectedFile]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const openModal = (rowIndex) => {
    setModalShow(!modalShow);
    setProcess_name(processDashboard.details[rowIndex]);
  };

  const getImportFile = () => {
    setModalShow(false);
    if (selectedFile) {
      getUploadFiles(
        sessionKey,
        userId,
        reportDate,
        branchCode,
        process_name?.IMPType ? process_name?.IMPType : "",
        "STANDARD",
        reportDate,
        "DURGA",
        "119.162.10.01",
        selectedFile
      )
        .then((response) => {
          if (response.data) {
            const logfilename = response.data;
            const cleanedJsonString = logfilename.replace(/\\/g, "");
            setModalShow(false);
            // Parse the JSON string into a JavaScript object
            const jsonObject = JSON.parse(cleanedJsonString);
            setLogFiles(jsonObject.KCSRPT.details[0].logfilename);
            setGetprocessName(jsonObject.KCSRPT.details[0].logfilepath);
            console.log(
              "Log Filename:",
              jsonObject.KCSRPT.details[0].logfilename
            );
          } else {
            console.log("No log filename found in response.");
          }
        })
        .catch((err) => {
          handleError(err);
        });
    }
  };

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header">{Constants.welcomeText}</h1>
          <Header />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left">
              <h1>
                {Constants.reportingDateTime}{" "}{reportDate}
                <span> {Constants.quarterText}</span>{" "}
              </h1>
              <div className="service-menu_row">
                <div className="dashbaord-menu" onClick={toggleServiceMenu}>
                  <TypeIcon />
                  <p>{Constants.masterText}</p>
                  <DropDownIcon />
                </div>
                {serviceMenu && (
                  <>
                    <div className="dashbaord-menu" onClick={navigateToProcess}>
                      <ProcessIcon />
                      <p>{Constants.processTitle}</p>
                      <DropDownIcon />
                    </div>
                    <div
                      className="dashbaord-menu"
                      onClick={navigateToStandard}
                    >
                      <StandardIcon />
                      <p>{Constants.standardText}</p>
                      <DropDownIcon />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="content-header-right">
              <h4 className="textDecoration">{Constants.importText}</h4>
              <h4 className="iconRow">
                {" "}
                <SearchIcon /> {Constants.searchText}{" "}
              </h4>
              <h4 className="iconRow position-relative">
                {" "}
                <>
                  {" "}
                  <SortIcon onClick={() => setShowSorting(true)} />{" "}
                  {Constants.sortText}{" "}
                </>{" "}
                {showsorting && (
                  <div className="sort-box" ref={popupRef}>
                    <p> {Constants.sortByText} </p>
                    <div className="radio-group">
                      <label>
                        {" "}
                        <input
                          type="radio"
                          value="total_count"
                          name="count"
                        />{" "}
                        {Constants.totalCountText}{" "}
                      </label>
                      <label>
                        {" "}
                        <input
                          type="radio"
                          value="exception_count"
                          name="count"
                        />{" "}
                        {Constants.exceptionCountText}{" "}
                      </label>
                    </div>
                    <button
                      className="apply-btn"
                      onClick={() => setShowSorting(false)}
                    >
                      {Constants.applyBtnText}
                    </button>
                  </div>
                )}{" "}
              </h4>
              <h4 className="iconRow position-relative">
                {" "}
                <Filtercon onClick={() => setShowFilter(true)} />{" "}
                {showfilter && (
                  <div className="sort-box filter-box" ref={filterRef}>
                    <p> {Constants.filterText} </p>
                    <Form.Group controlId="formCheckboxGroup">
                      {checkboxes}
                    </Form.Group>
                    <button
                      className="apply-btn"
                      onClick={() => setShowFilter(false)}
                    >
                      {Constants.applyBtnText}
                    </button>
                  </div>
                )}{" "}
              </h4>
            </div>
          </div>

          <div className="content-table table-container">
            <table className="table">
              <thead className="dashboard_content">
                <tr>
                  <th>{Constants.infoText}</th>
                  <th className="textLeft">{Constants.processText}</th>
                  <th>{Constants.actionText}</th>
                  <th>{Constants.totalCountText}</th>
                  <th>{Constants.exceptionCountText}</th>
                  <th>{Constants.remark}</th>
                  <th>{Constants.dataPrepared}</th>
                  <th>{Constants.successText}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {processDashboard.details &&
                  processDashboard?.details?.map((title, rowIndex) => (
                    <tr key={title[rowIndex]}>
                      <td className="view" onClick={handleCountModal}>
                        <InfoIcon />
                      </td>
                      <td className="textLeft"> {title.ImpTypeDesc}</td>

                      <td>
                        <button
                          className="importBtn"
                          onClick={() => openModal(rowIndex)}
                        >
                          {" "}
                          {title["Next Action Required"]}
                        </button>
                      </td>
                      <td onClick={navigateToTotalCount} className="log-link exception_count">
                        {title["Total Count"]}
                      </td>
                      <td
                        className="log-link exception_count"
                        onClick={navigateToExceptionCount}
                      >
                        {title["Exception Count"]}
                      </td>
                      <td>
                        {" "}
                        <p
                          className={
                            title.Remark ==
                              "Authorised Successfully"
                              ? "successRemark remark"
                              :
                              title.Remark == "Import Successfully and Pending for authorisation"
                                ? "pendingRemark remark"
                                : title.Remark == "Import failed with Error. Please check Log file"
                                  ? "failedRemark remark"
                                  : title.Remark === "" ? "" : "remark"
                          }
                        >
                          {title.Remark}
                        </p>
                      </td>
                      <td className="isDataPrepared">
                        {" "}
                        {title ? (
                          <img
                            src={CorrectIcon}
                            width="24px"
                            height="24px"
                            alt="success"
                          />
                        ) : (
                          "--"
                        )}
                      </td>
                      <td className="log-link" onClick={() => handleViewLog(rowIndex)}>
                        {title["Last Success Log File"]}
                      </td>
                      <td
                        className="view position-relative"
                        onClick={() => showActionOverlay(rowIndex)}
                      >
                        <MoreIcon />{" "}
                        {actionId === rowIndex && actionOverlay && (
                          <ActionOverlay
                            logFiles={processDashboard}
                            getprocessName={rowIndex}
                          />
                        )}{" "}
                      </td>
                    </tr>
                  ))}

              </tbody>
            </table>
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="main-overlay"
              show={modalShow}
            >
              <Modal.Body className="modal_body">
                <div className="overlay-content ">
                  {/* <p>{Constants.logoutText}</p> */}
                  <h4 className="modal-main-report">
                    {" "}
                    {Constants.uploadfile} :
                  </h4>
                  <input
                    id="fileInput"
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                    // style={{ display: "none" }}
                    ref={fileInputRef} // Make sure this line is present
                  />
                </div>

                <button onClick={getImportFile} className="closeBtn">
                  {Constants.modaltext}
                </button>
              </Modal.Body>
            </Modal>
          </div>
        </div>

        <UserDetailsFooter/>

        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
        <CountInformation
          show={countModalShow}
          onHide={() => setCountModalShow(false)}
        />
      </div>
    </MainLayout>
  );
};

export default Standard;
