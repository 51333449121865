import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { getDashboard, getFileTypeDescription, getFile_Type } from "../../Api";
import { NotificationManager } from "react-notifications";
import { handleError } from "../../Services/ErrorService";
import { ReactComponent as FolderIcon } from "../../assets/images/Folder.svg";
import { useNavigate } from "react-router-dom";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const FileChange = () => {
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [heading] = useState("");
  const [filetype, setFileType] = useState("");
  const [description, setDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [sheetName, setSheetName] = useState("");
  const [dashbaord, setDashboard] = useState([]);
  const [, setFinancialYear] = useState("");
  const [get_date, setGet_date] = useState("");
  const [fileImpType, setFileImpType] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      let getFinancialYear = localStorage.getItem("Financial_year");
      setFinancialYear(getFinancialYear);

      if (userDate) {
        setReporteDate(userDate);
        await getDashboardparamaters(
          getSessionId,
          getUserId,
          userDate,
          getFinancialYear
        );
      } else {
        setReporteDate(getdate);
        await getDashboardparamaters(
          getSessionId,
          getUserId,
          getdate,
          getFinancialYear
        );
      }
    };
    checkAuth();
  }, [heading, filetype, get_date]);



/**
 * The getFileType function retrieves file type details based on the selected value and updates the
 * description, file name, and sheet name accordingly.
 * @param e - The parameter `e` is an event object that is passed to the `getFileType` function. It is
 * typically used in event handlers to access information about the event that occurred, such as the
 * target element that triggered the event. In this case, `e.target.value` is used to get the
 */

  const getFileType = (e) => {
    setFileImpType(dashbaord?.details[e.target.value].IMPType)
    setFileType(e.target.value);
    getFile_Type(
      "getfiletypedtls",
      sessionKey,
      userId,
      reportDate,
      dashbaord?.details[e.target.value].IMPType
    ).then((response) => {
      if (response) {
        setDescription(response?.data?.KCSRPT?.details[0]?.IMPTypeDesc);
        setFileName(response?.data?.KCSRPT?.details[0]?.FileName);
        setSheetName(response?.data?.KCSRPT?.details[0]?.Sheet);
      }
    });
  };

  const getChangeDescription = (e) => {
    const newValue = e.target.value;
    setDescription(newValue);
  };

  const getChangeFilename = (e) => {
    setFileName(e.target.value);
  };

  const getChangeSheetname = (e) => {
    setSheetName(e.target.value);
  };

  const getDashboardparamaters = (
    sessionKey,
    userId,
    reportDate,
    financialYear
  ) => {
    getDashboard(
      "processlist",
      sessionKey,
      userId,
      reportDate,
      financialYear,
      reportDate,
      "",
      ""
    )
      .then((res) => {
        if (res?.data?.KCSRPT?.details[0]?.ErrorMsg) {
          NotificationManager.error(res?.data?.KCSRPT?.details[0]?.ErrorMsg);
        } else {
          setDashboard(res.data && res?.data?.KCSRPT);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };


  
/**
 * The function `getSavedInputFiles` retrieves saved input files based on certain parameters and
 * displays an error message if there is any.
 */

  const getSavedInputFiles = () => {
    getFileTypeDescription(
      "auditrpt",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      fileImpType,
      description,
      fileName,
      sheetName
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0]?.ErrorMsg) {
        NotificationManager.error(response?.data?.KCSRPT?.details[0]?.ErrorMsg);
      }
    });
  };

  const getClearSavedFiles = () => {
    setFileType("");
    setFileName("");
    setDescription("");
    setSheetName("");
  };

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header"> {Constants.fileChangeTitle} </h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left">
              <h1>
                {Constants.reportingDateTime}
                {reportDate}
              </h1>
            </div>
            <div className="content-header-right ">
              {/* <button className="exportBtn">
                {" "}
                {Constants.exportBtnText} <FolderIcon />{" "}
              </button> */}
              <button className="back-btn" onClick={() => navigate(-1)}>
                {Constants.backBtnText}
              </button>
            </div>
          </div>
          <Form className="fileChange-form">
            <Form.Group className="mb-4 form-row">
              <Form.Label>File Type : </Form.Label>
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={getFileType}
                value={filetype}
              >
                <option value="">{Constants.fileTypeDefaultOption}</option>
                {dashbaord.details &&
                  dashbaord?.details?.map((item, index) => {
                    return (
                      <>
                        <option value={index}>{item.ImpTypeDesc}</option>
                      </>
                    );
                  })}
              </select>
            </Form.Group>

            <Form.Group className="mb-4 form-row ">
              <Form.Label>{Constants.descLabel} </Form.Label>
              <Form.Control
                type="text"
                className="form-lable"
                placeholder="File Description"
                onChange={getChangeDescription}
                value={description}
              />
            </Form.Group>
            <Form.Group className="mb-4 form-row">
              <Form.Label>{Constants.fileLabel}</Form.Label>
              <Form.Control
                type="text"
                placeholder="File Name"
                onChange={getChangeFilename}
                value={fileName}
              />
            </Form.Group>
            <Form.Group className="mb-4 form-row">
              <Form.Label>{Constants.sheetLabel}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Sheet Name"
                onChange={getChangeSheetname}
                value={sheetName}
              />
            </Form.Group>

            <Button className="clear-btn" onClick={getClearSavedFiles}>
              {Constants.clearBtnTxt}
            </Button>
            <Button className="save-btn" onClick={getSavedInputFiles}>
              {Constants.saveBtnTxt}
            </Button>
          </Form>
        </div>
        
        <UserDetailsFooter/>

        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default FileChange;
