import "./freeze.css";
import React, { useState, useEffect } from "react";
import { Modal, FloatingLabel, Form } from "react-bootstrap";
import * as Constants from "../../../constants/stringConstants";
import { ReactComponent as FreezeIcon } from "../../../assets/images/unfreese.svg";
import { getFreezeReport } from "../../../Api";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";

const ReaseonForUnfreeze = ({
    isFrozen,
    setIsFrozen,
    title,
    onHide,
    show,
    date
}) => {
    const [sessionKey, setSessionKey] = useState("");
    const [userId, setUserId] = useState("");
    const [, setBranchCode] = useState("");
    const [, setFinancialYear] = useState("");
    const [reportDate, setReporteDate] = useState("");
    const [comment, setComment] = useState("");
    const navigate = useNavigate()



    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getBranch = await localStorage.getItem("branch_code");
            setBranchCode(getBranch);

            let userDate = await localStorage.getItem("date_key");
            let getdate = await localStorage.getItem("reporting_date");

            if (userDate) {
                setReporteDate(userDate)
            } else {
                setReporteDate(getdate)
            }

            let getFinancialYear = localStorage.getItem("Financial_year");
            setFinancialYear(getFinancialYear);
        };
        checkAuth();
    }, [isFrozen, reportDate]);

    const handleChange = (e) => {
        setComment(e.target.value);
    };

    const getUnfrozenData = () => {
        if (isFrozen === false) {
            getFreezeReport(
                'freeze',
                sessionKey,
                userId,
                date,
                date,
                'UnFreeze',
                comment
            ).then((response) => {
                if (response) {
                    NotificationManager.success(
                        response?.data?.KCSRPT?.details[0]?.ErrorMsg
                    );

                    const isFreezeSuccessful = response?.data?.KCSRPT?.details[0]?.ErrorMsg ===
                        `Report UnFreeze successfully for reporting date : ${reportDate}`
                        ? true
                        : false

                    if (isFreezeSuccessful) { setIsFrozen(true) }

                    navigate('/dashboard', { state: { freezed: isFreezeSuccessful } });

                }
            });
        }
    };




    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="freeze-overlay"
        >
            <Modal.Body>
                <div className="text-center text-icon">
                    <FreezeIcon />
                </div>

                <h2 className="pb-3 reasonTitle">
                    {Constants.reasonprocessText} {title}
                </h2>
                <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Type your reason here..."
                >
                    <Form.Control
                        as="textarea"
                        placeholder="Type your reason here..."
                        className="modalTextArea"
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <div className="btn-row">
                    <button
                        className="ignoreBtn modalBtn"
                        onClick={() => getUnfrozenData(onHide())}
                    >
                        {Constants.okBtnText}
                    </button>
                    <button className="cancelBtn  modalBtn border-0" onClick={onHide}>
                        {Constants.cancelBtnText}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ReaseonForUnfreeze;
