//Login.js
export const loginTitle = "Related Party Transactions"
export const branchLabelText = "Branch Code"
export const branchError = "* Branch Code cannot be blank. Please add the relevant Branch Code."
export const loginId = "Login ID"
export const loginIdError = "* Please enter the login Id."
export const passText = "Password"
export const pswError = "* Please enter the passward."
export const missingFieldError = "Please fill all the details."
export const loginBtnText = "Log In"
export const acceptText = "I Accept"
export const confidentialityText = "Confidentiality Conditions"
export const termsErrorText = "* Please accept terms and condtions."

//ReportingDate.js
export const reporting_date = "Reporting Date"
export const backBtnText = "Back"
export const exportBtnText = "Export to CSV"
export const enteredBy = "Entered By "
export const enteredOn = "Entered On"
export const status = "Status"
export const authorisedBy = "Authorised By"
export const authorisedOn = "Authorised On"
export const authorise = "Authorise"
export const deleteAuth = "Delete"
export const cancel = "Close"


//ActionOverlay Modal
export const reasonText = "Reason to"
export const okBtnText = "OK"
export const cancelBtnText = "Cancel"

// Main Report 

export const maxpopupText = "there should not be content click."
export const mainpopupText = "Content click is not allowed"

export const datapreparation = "Ensure data preparation done before displaying report"


// Dashboard

export const uploadfile = "Upload File"

export const modaltext = "OK"
export const modalBackText = "Back"

//Header Component
export const freezeText = "Freeze Quarter"
export const unfreezeText = "UnFreeze Quarter"
export const profileText = "Mr. Aditya Sir"

//Footer
export const footerText = "ⓒ Kalpataru Computer Services Private Limited"


//Dashboard - Service Board 
export const welcomeText = "Welcome to Services Board  👋"
export const reportingDateTime = "Reporting Date : "
export const quarterText = "( FY22 Q4 )"
export const importText = "Import All"
export const searchText = "Search"
export const sortText = "Sort"
export const infoText = "Info"
export const processText = "Process Name"
export const actionText = "Action"
export const totalCountText = "Total Count"
export const accountReceivableText = "Account Receivable Summary"
export const totalcountContentText = "Content Click"
export const exceptionCountText = "Exception Count"
export const remark = "Remark"
export const dataPrepared = "Data Prepared"
export const successText = "Success Log"
export const masterText = "Master"
export const sortByText = "Sort By"
export const applyBtnText = "Apply"
export const reports = "Reports"
export const filterText = "Filters"
export const processTitle = "Process"
export const standardText = "Standard"
export const ignoreException = "Marked as Ignore Exception"
export const exceptionView = "Exception View"

//Dashboard - Exception Count
export const exceptionTitle = "Payment Dump Exceptions"
export const updateExceptionText = "Updated Exceptions"
export const ignoreText = "Ignore"
export const verifyText = "Get Verify & Update"

//Dashboard - Total Count
export const summaryText = "Last Updated File Summary :"
export const rpEntryText = "No of RP Entries : 1"
export const rpRecordText = "Total No of Records Imported : 44"
export const exceptionSummaryText = "Exception Summary :"
export const originExceptText = "Original Exception : 41"
export const currentExceptText = "Current Exception  : 01"
export const updateExceptText = "Updated  Exception  : 00"
export const ignoreExceptText = "Ignored  Exception  : 00"
export const cureText = "Auto Cured : 00 "
export const systemIgnoreText = "System Ignored : 00"

//Dashboard -  View Log
export const downloadBtnTxt = "Download"
export const closeBtnTxt = "Close"
export const noteText = "Note :"
export const noteOneSubText = "==> There are some Extra Columns in Payment Dump PAYMENT DUMP ( Final Amount, RP, Transaction as per GL, Transaction based on CRS, Final Txn, Month, F21, Txn master )"
export const noteTwoSubText = "=-> There are some Extra Sheets in file (new case payment dunp,Payment dump,Sheet1,Sheet2)"
export const noteThreeSubText = "Total Record(s) processed : 44"
export const noteFourSubText = "<== Payment Dump process completed successfully at 13-Jan-2023 18:43:53 (Success)"
export const viewaLabelDate = "Business Date"
export const viewRunDate = "Run Date / Time"
export const viewModule = "Module Name"
export const viewProcess = "Process"
export const viewProcessBy = "Process Called by"
export const viewUserId = "User ID/ User Name "
export const viewEmail = "Email ID "
export const viewLogFile = "Log File Name"
export const viewIP = "System IP Address"
export const viewComponent = "Component Name"
export const viewVersion = "Component Version"
export const viewaLabelDateData = " 11 Jan 2023"
export const viewRunDateData = "13 Jan 2023, 10:59:23"
export const viewModuleData = "Related Party"
export const viewProcessData = "Import Process"
export const viewProcessByData = "Related Process"
export const viewUserIdData = "Aditya Sir"
export const viewEmailData = "( Not Available )"
export const viewLogFileData = "Payment Dump 56366.log"
export const viewIPData = "192.168.1.08"
export const viewComponentData = "KCSREL"
export const viewVersionData = "11.0.0.0.6"
export const viewFileName = "File Name"
export const viewFileSize = "File Size"
export const viewFileTimestamps = "File Timestamp"
export const viewStatus = "Import Status"
export const viewBatch = "Batch No"
export const viewFileNameData = "File Name"
export const viewFileSizeData = "File Size"
export const viewFileTimestampsData = "File Timestamp"
export const viewStatusData = "Import Status"
export const viewBatchData = "Batch No"
export const viewDetails = "Process:  Payment Dump process started at 13 Jan 2023, 10:59:23"

//As Page
export const asTitleText = "AS Full Form Display Here ( AS )"
export const reportText = "AS Main Report"
export const asreportlevel1 = "AS Report Level 1"
export const asreportlevel2 = "AS Report Level 2"
export const totalAmtText = "Total : ₹ 1,00,000,000.00"
export const significantText = "Significant Transaction Summary"
export const processTest = "Process"
export const clearText = "Clear"

// Board page
export const boardreportText = "AS Board Note Report"
export const boardreportlevel = "AS Board Note Report Level 1"
export const boardreportlevel2 = "AS Board Note Report Level 2"


// Intermidiate page
export const intermidiatereportText = "AS Intermediate Report"
export const intermidiatereportlevel = "AS Intermediate Report Level 1"

//Not In AS
export const notASreportText = "Not in AS"
export const notSebireportText = "Not in SEBI"
export const notNonereportText = "None Report"

//MIS Page
export const misTitle = "Management Information Systems ( MIS )"
export const misreport = "MIS Report"
export const misReimportreport = "Reimport Report"
export const misQoqreport = "QoQ Comparison"
export const trendAnlysisTxt = "Trend Analysis Report"


//Others Page
export const othersTitle = "Others"

//SEBI Page
export const sebireportText = "SEBI Report"
export const sebiIntermidiatereport = "SEBI Intermediate Report"
export const sebiIntermidiateSubreport = "SEBI Sub-Intermediate Report"
export const sebilevelreport = "SEBI Report Level 1"
export const sebilevelreport2 = "SEBI Report Level 2"
export const totalAmt = "Total : ₹"
export const SebiTitle = "Securities and Exchange Board of India ( SEBI )"


//Settings - File change
export const fileChangeTitle = "File Type Description Change"
export const fileTypeDefaultOption = "Select File Type"
export const clearBtnTxt = "Clear"
export const saveBtnTxt = "Save"
export const descLabel = "Description : "
export const fileLabel = "File Name :"
export const sheetLabel = "Sheet Name :"

export const settingsTitle = "Settings"

export const parameterTitle = "Parameter"


//Log out
export const logoutText = "Are you sure you want to log out ?"
export const logoutBtnTxt = "Log Out"


//Overlay's Text 
export const quickViewText = "Quick View"
export const downloadText = "Download"
export const ignoreBtnText = "Ignore"
export const getVerifyText = "Get Verify & Update"
export const reasonBtnText = "Reason :"
export const ignoreOverlayText = "Ignore Exception"
export const countOverlayText = "Count Information"
export const countOverlayinfoText = "No Information Found"
export const downloadSuccessText = "File Downloaded Successfully"
export const freezeQuarterText = "Do you want to Unfreeze Quarter"
export const yes = "Yes"
export const no = "No"
export const reasonFreezeText = "Reason to Unfreeze"
export const reasonprocessText = "Reason to Cancellation"
export const updateButtonText = "Update"

//CountInformation OVerlay Text
export const countTabText = "Payment Dump"
export const countTabTextOne = "Reimbursement"
export const countTabTextTwo = "Provision"
export const countTabTextThree = "Details"
export const tabRecordText = "Record Count"
export const tabOriginalText = "Original Exception Count"
export const tabIgnoreText = "Ignored Exception Count"
export const tabCurrentText = "Current Exception Count"
export const tabUpdatedText = "Updated Exception Count"
export const tabAutoText = "Auto Exception Count"
export const tabSysIgnoreText = "System Ignored Exception Count"
export const tabImportByText = "Imported By"
export const tabImportOnText = "Imported On"
export const tabImportFromText = "Imported Form"
export const tabImportFormText = "Imported File Name"


//Table Texts
export const Cl = "Cl"
export const ProcessName = "Process Name"
export const Action = "Action"
export const TotalCount = "Total Count"
export const ExceptionCount = "Exception Count"
export const Remark = "Remark"
export const DataPre = "DataPre"
export const SuccessLog = "Success Log"
export const Trasaction = "Trasaction Type"
export const RelatedParty = "Related Party Name"
export const PriorPeriod = "Prior Period"
export const Jan = "Jan 2023"
export const Dec = "Dec 2023"
export const Nov = "Nov 2023"
export const Oct = "Oct 2023"
export const Sept = "Sept 2023"
export const Aug = "Aug 2023"
export const July = "July 2023"
export const Jun = "Jun 2023"
export const Apr = "Apr 2023"
export const May = "May 2023"
export const Feb = "Feb 2023"
export const March = "March 2023"
export const selectText = "Select"
export const CalcText = "Calc Date"
export const GIDate = "GI Date"
export const CRNNo = "CRN No"
export const GICode = "GI Code"
export const GIDesc = "GI Desc"
export const ITPan = "IT Pan"
export const RPName = "RP Name"
export const Amount = "Amount"
export const VendorCD = "Vendor CD"
export const VendorNM = "Vendor NM"
export const SebiMainH = "Sebi Main H"
export const SebiSubHe = "Sebi Sub He"
export const Prevamt = "Prevamt"
export const RPType = "RP Type"
export const FinancialYear = "Financial Year"
export const CMS = "CMS"
export const Derivative = "Derivative"
export const Dividend = "Dividend"
export const ASMainHead = "AS Main Head"
export const AS = "AS"
export const ASReporting = "AS Reporting"
export const Associate = "Associate"
export const Promoter = "Promoter"
export const Relative = "Relative"
export const TotalAmount = "Total Amount"
export const RPID = "RP ID"
export const AS18 = "AS 18 Y/N"
export const ASSubHead = "AS Sub Head"
export const Transaction = "Transaction"
export const TransactionDate = "Transaction Date"
export const RelatedPar = "Related Par..."
export const ReportingM = "Reporting M..."
export const ReportingS = "Reporting S..."
export const SEBISubHeading = "SEBI Sub Heading"
export const NatureofTransaction = "Nature of Transaction"
export const ApprovedAmount = "Approved Amount"
export const Total = "Total"
export const Utilization = "Utilization"

// Footer User Details Text 

export const userText = "User - "
export const branchName = "Branch Name - "
export const branchCode = "Branch Code - "