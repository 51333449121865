import "./freeze.css";
import React, { useState, useEffect } from "react";
import { Modal, FloatingLabel, Form } from "react-bootstrap";
import * as Constants from "../../../constants/stringConstants";
import { ReactComponent as FreezeIcon } from "../../../assets/images/unfreese.svg";
import { cancleprocessAuthorizeFiles, getDashboard } from "../../../Api";
import { NotificationManager } from "react-notifications";
import { handleError } from "../../../Services/ErrorService";

const ReaseonOverlay = ({
  getAction,
  getImptype,
  setProcessDashboard,
  setHeading,
  title,
  onHide,
  show,
}) => {
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [financialYear, setFinancialYear] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let getdate = await localStorage.getItem("reporting_date");
      setReporteDate(getdate);

      let getFinancialYear = localStorage.getItem("Financial_year");
      setFinancialYear(getFinancialYear);
    };
    checkAuth();
  }, []);

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  /**
   * The function `getCancledAuthourization` cancels the authorization process for a file and updates the
   * dashboard accordingly.
   * @param cancle - A function that will be called to cancel the authorization process.
   */

  const getCancledAuthourization = (cancle) => {
    if (
      getAction === "Authorised Successfully" ||
      getAction === "Cancel Successfully and Pending for authorisation"
    ) {
      cancleprocessAuthorizeFiles(
        "basefilecanc",
        sessionKey,
        userId,
        reportDate,
        reportDate,
        getImptype,
        "",
        getAction === "Authorised Successfully" ? "CANCEL" : "CANCELAUTH",
        comment
      ).then((response) => {
        if (response) {
          NotificationManager.error(
            response?.data?.KCSRPT?.details[0].ErrorMsg
          );
          getDashboard(
            "processlist",
            sessionKey,
            userId,
            reportDate,
            financialYear,
            reportDate,
            "process",
            getImptype
          )
            .then((res) => {
              if (res) {
                getDashboard(
                  "processlist",
                  sessionKey,
                  userId,
                  reportDate,
                  financialYear,
                  reportDate,
                  "process",
                  ""
                )
                  .then((res) => {
                    if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
                      NotificationManager.error(
                        res?.data?.KCSRPT?.details[0].ErrorMsg
                      );
                    } else {
                      setProcessDashboard(res.data && res?.data?.KCSRPT);
                      let tableHeading = Object.keys(
                        res?.data?.KCSRPT?.details[0]
                      );
                      setHeading(tableHeading);
                      cancle();
                    }
                  })
                  .catch((err) => {
                    handleError(err);
                  });
              }
            })
            .catch((err) => {
              handleError(err);
            });
        }
      });
    }
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="freeze-overlay"
    >
      <Modal.Body>
        <div className="text-center text-icon">
          <FreezeIcon />
        </div>

        <h2 className="pb-3 reasonTitle">
          {Constants.reasonprocessText} {title}
        </h2>
        <FloatingLabel
          controlId="floatingTextarea2"
          label="Type your reason here..."
        >
          <Form.Control
            as="textarea"
            placeholder="Type your reason here..."
            className="modalTextArea"
            onChange={handleChange}
          />
        </FloatingLabel>
        <div className="btn-row">
          <button
            className="ignoreBtn modalBtn"
            onClick={() => getCancledAuthourization(onHide())}
          >
            {Constants.okBtnText}
          </button>
          <button className="cancelBtn  modalBtn border-0" onClick={onHide}>
            {Constants.cancelBtnText}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReaseonOverlay;
