import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { NotificationManager } from "react-notifications";
import {
  getFiletyperptdate,
  getReimbursementReport,
  getReportingDateList,
} from "../../Api";
import { ReactComponent as TypeIcon } from "../../assets/images/Category.svg";
import { ReactComponent as ScanIcon } from "../../assets/images/Scan.svg";
import ResolvedApi from "../../components/api/ResolvedApi";
import { BackBtn } from "../../components/Buttons/CustomButton";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";
import UserDetailsFooter from "../../components/Footer/UserDetailsFooter";

const MISReport = () => {
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [reimbursementInfo, setReimbursementInfo] = useState([]);
  const [heading, setHeading] = useState("");
  const [reportingDate, setReportingDate] = useState([]);
  const [, setGetFileType] = useState([]);
  const [file, setFile] = useState("");
  const [get_repotingDate, setGet_reportingDate] = useState("");
  const [get_date, setGet_date] = useState("");
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        await get_ReimbursementDetails(getSessionId, getUserId, userDate);

        await getReportingList(getSessionId, getUserId, getdate);
      } else {
        setReporteDate(getdate);
        await get_ReimbursementDetails(getSessionId, getUserId, getdate);

        await getReportingList(getSessionId, getUserId, getdate);
      }

    };
    checkAuth();
  }, [get_date]);


  const getReportingList = (sessionKey, userId, reportDate) => {
    getReportingDateList(
      "rptdatemst",
      sessionKey,
      userId,
      reportDate,
      "LIST",
      "",
      "",
      ""
    ).then((response) => {
      if (response) {
        setReportingDate(response.data.KCSRPT);
      }
    });
  };


  const getReportingDateDeatils = (index) => {
    setGet_reportingDate(reportingDate?.details[index]["Reporting Date"]);
    getFiletyperptdate(
      "filetyperptdate",
      sessionKey,
      userId,
      reportDate,
      reportingDate?.details[index]["Reporting Date"]
    ).then((response) => {
      if (response) {
        setGetFileType(response?.data?.KCSRPT);
      }
    });
  };

  const getfileDeatils = (index) => {
    setFile(index);
  };

  const [, setIsProcess] = useState("false");

  /**
   * The function `getReimbursementDetails` retrieves reimbursement report details based on the session
   * key, user ID, and report date, and updates the reimbursement information and table heading
   * accordingly.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current user
   * session. It is used for authentication and authorization purposes.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the
   * reimbursement details are being fetched.
   * @param reportDate - The `reportDate` parameter is a date that specifies the date for which the
   * reimbursement report is requested.
   */

  const getReimbursementDetails = () => {
    setIsProcess(true);
    setLoader(true)
    getReimbursementReport(
      "auditrpt",
      sessionKey,
      userId,
      reportDate,
      get_repotingDate || reportDate,
      file || "REIMBURSEMENT MIS"
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
        setLoader(false)
        NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg);
      } else {
        setLoader(false)
        setReimbursementInfo(
          response.data &&
          response?.data?.KCSRPT?.details?.map((ele) => {
            return ele;
          })
        );
        let tableHeading = response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : [];
        setHeading(tableHeading);
      }
    });
  };


  // To Clear the reimbursement details

  const clearReimbursementDetails = () => {
    setGet_reportingDate("")
    setFile("")
  }


  /**
   * The function `get_ReimbursementDetails` retrieves reimbursement details based on the session key,
   * user ID, and report date provided.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current
   * session of the user. It is used for authentication and authorization purposes.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom you want to
   * retrieve reimbursement details.
   * @param reportDate - The `reportDate` parameter is the date for which you want to retrieve the
   * reimbursement details.
   */

  const get_ReimbursementDetails = (sessionKey, userId, reportDate) => {
    setIsProcess(false);
    setLoader(true)
    getReimbursementReport(
      "auditrpt",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      "REIMBURSEMENT MIS"
    ).then((response) => {
      if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
        setLoader(false)
        NotificationManager.error(response?.data?.KCSRPT?.details[0].ErrorMsg);
      } else {
        setLoader(false)
        // setReimbursementInfo(
        //   response.data &&
        //   response?.data?.KCSRPT?.details?.map((ele) => {
        //     return ele;
        //   })
        // );
        setReimbursementInfo(response?.data?.KCSRPT)
        let tableHeading = response?.data?.KCSRPT?.details[0]
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : []
        setHeading(tableHeading);
      }
    });
  };

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header"> {Constants.misreport} </h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header align-items-baseline">
            <div className="content-header-left flex-column align-items-start">
              <h1>
                {Constants.reporting_date} : {reportDate}
              </h1>
              <div className="custom-row gx-5 custom-body">
                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <TypeIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {get_repotingDate || reportDate}
                      </button>
                      <ul className="dropdown-menu ">
                        {reportingDate?.details &&
                          reportingDate?.details?.map((ele, index) => {
                            return (
                              <li key={ele[index]}
                                className=" btn btn-secondary"
                                onClick={() => getReportingDateDeatils(index)}
                              >
                                {ele["Reporting Date"]}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="flex-row custom-row">
                  <div className="flex-row custom-row custom-row-wrapper">
                    <ScanIcon />
                    <div className="input-group ">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle "
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {file || "REIMBURSEMENT MIS"}
                      </button>
                      <ul className="dropdown-menu ">
                        <li
                          className=" btn btn-secondary"
                          onClick={() => getfileDeatils("REIMBURSEMENT MIS")}
                        >
                          {"REIMBURSEMENT MIS"}
                        </li>
                        <li
                          className=" btn btn-secondary"
                          onClick={() => getfileDeatils("RENT MIS")}
                        >
                          {"RENT MIS"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-header-right process_btn">
              <div className="flex-row custom-row">
                <GernerateCSVFile
                  generateCSV={reimbursementInfo}
                  reportName={"MISReport"}
                />
                <BackBtn />
              </div>
              <div className="d-flex gap-2">
                <button className="backBtn" onClick={getReimbursementDetails}>
                  {Constants.processTest}
                </button>
                <button className="backBtn" onClick={clearReimbursementDetails}>
                  {Constants.clearText}
                </button>
              </div>
            </div>
          </div>
          {
            loader === false ?
              <div className="content-table reporting-table">
                <ResolvedApi
                  get_response={reimbursementInfo}
                  heading={heading}
                  navigateToReportDetails={""}
                />
              </div>
              : <div className="spinner-loader">
                <div class="spinner-border d-flex m-auto" role="status"></div>
              </div>
          }
        </div>

        <UserDetailsFooter/>

        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default MISReport;
