import "./App.css";
import React, { useState, useEffect } from "react";
import AS from "./pages/AS/AS";
import MIS from "./pages/MIS/MIS";
import SEBI from "./pages/SEBI/SEBI";
import Login from "./pages/Login/Login";
import Others from "./pages/Others/Others";
import Logout from "./pages/Logout/Logout";
import BoardNote from "./pages/AS/BoardNote";
import Master from "./pages/Dashboard/Master";
import MISReport from "./pages/MIS/MISReport";
import MainReport from "./pages/AS/MainReport";
import ViewLog from "./pages/Dashboard/ViewLog";
import Process from "./pages/Dashboard/Process";
import Settings from "./pages/Settings/Settings";
import Standard from "./pages/Dashboard/Standard";
import Dashboard from "./pages/Dashboard/Dashboard";
import ReportDetails from "./pages/AS/ReportDetails";
import { PrivateRoute } from "./routes/PrivateRoute";
import QaQComparison from "./pages/MIS/QaQComparison";
import FileChange from "./pages/Settings/FileChange";
import TotalCount from "./pages/Dashboard/TotalCount";
import ReimportReport from "./pages/MIS/ReimportReport";
import SEBIMainReport from "./pages/SEBI/SEBIMainReport";
import ReportingDate from "./pages/Settings/ReportingDate";
import SEBIInterReports from "./pages/SEBI/SEBIInterReports";
import ExpectionCount from "./pages/Dashboard/ExpectionCount";
import IntermediateReport from "./pages/AS/IntermediateReport";
import SingleReportDetails from "./pages/AS/SingleReportDetails";
import TrendAnalysisReport from "./pages/MIS/TrendAnalysisReport";
import IntermediateSubPage from "./pages/SEBI/IntermediateSubPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import SignificantReport from "./pages/AS/SignificantReport";
import BoardNoteReport from "./pages/AS/BoardNoteReport";
import IntermidiateLevelReport from "./pages/AS/IntermidiateLevelReport";
import SebiReportDetails from "./pages/SEBI/SebiReportDetails";
import SEBILevel2Report from "./pages/SEBI/SEBILevel2Report";
import "react-notifications/lib/notifications.css";
import NotASReport from "./pages/Others/NotASReport";
import NotASLevel1Report from "./pages/Others/NotASLevel1Report";
import NotInSEBI from "./pages/Others/NotInSEBI";
import NotInLevelSebi from "./pages/Others/NotInLevelSebi";
import NoneReport from "./pages/Others/NoneReport";
import NoneLevel1Report from "./pages/Others/NoneLevel1Report";
import BoardNoteLevel2 from "./pages/AS/BoardNoteLevel2";
import TotalcountContent from "./pages/Dashboard/TotalcountContent";
import IgnoredException from "./pages/Dashboard/IgnoredException";
import UpdateException from "./pages/Dashboard/UpdateException";
import PaymentReceivable from "./pages/Dashboard/PaymentReceivable";

export const createNotification = (type) => {
  return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success("Success message", "Title here");
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        );
        break;
      case "error":
        NotificationManager.error(
          "Error message",
          "Click me!",
          null,
          5000,
          () => { },
          {
            className: "custom-notification", // Add a custom CSS class
          }
        );
        break;
    }
  };
};

function App() {


  const [sessionKey, setSessionKey] = useState("");
  const [isAuth, setAuth] = useState(false);

  useEffect(() => {

    let getSessionId = localStorage.getItem("session_key");
    setSessionKey(getSessionId);
    getSessionId ? setAuth(true) : setAuth(false);

  }, [sessionKey, isAuth]);

  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <Router>

      {isOnline ? (
        <>
          <Routes>
            <>
              {sessionKey ? (
                <Route path="/" element={<Dashboard />} />
              ) : (
                <Route path="/" element={<Login />} />
              )}

              <Route
                exact
                path="/dashboard"
                element={<PrivateRoute auth={isAuth} />}
              >
                <Route path="/dashboard" element={<Dashboard />} />
                <Route exact path="master" element={<Master />} />
                <Route exact path="process" element={<Process />} />
                <Route exact path="standard" element={<Standard />} />
                <Route
                  exact
                  path="/dashboard/total-count"
                  element={<TotalCount />}
                />
                <Route
                  exact
                  path="/dashboard/ignore-total-count"
                  element={<IgnoredException />}
                />
                <Route
                  exact
                  path="/dashboard/update-count-exception"
                  element={<UpdateException />}
                />
                <Route
                  exact
                  path="/dashboard/payment-receivable"
                  element={<PaymentReceivable />}
                />
                <Route
                  exact
                  path="/dashboard/total-count-content"
                  element={<TotalcountContent />}
                />
                <Route
                  exact
                  path="/dashboard/exception-count"
                  element={<ExpectionCount />}
                />
                <Route exact path="AS" element={<AS />} />
                <Route exact path="AS/main-report" element={<MainReport />} />
                <Route
                  exact
                  path="AS/significant-report"
                  element={<SignificantReport />}
                />
                <Route exact path="AS/board-note" element={<BoardNote />} />
                <Route
                  exact
                  path="AS/intermediate-report"
                  element={<IntermediateReport />}
                />
                <Route exact path="AS/report-details" element={<ReportDetails />} />
                <Route
                  exact
                  path="AS/board-report-details"
                  element={<BoardNoteReport />}
                />
                <Route
                  exact
                  path="AS/board-report-level2"
                  element={<BoardNoteLevel2 />}
                />
                <Route
                  exact
                  path="AS/single-report-details"
                  element={<SingleReportDetails />}
                />
                <Route
                  exact
                  path="AS/intermidiate-report"
                  element={<IntermidiateLevelReport />}
                />
                <Route exact path="SEBI" element={<SEBI />} />
                <Route exact path="SEBI/main-report" element={<SEBIMainReport />} />
                <Route
                  exact
                  path="SEBI/level2-report"
                  element={<SEBILevel2Report />}
                />
                <Route
                  exact
                  path="SEBI/sebi-report"
                  element={<SebiReportDetails />}
                />
                <Route
                  exact
                  path="SEBI/intermediate-report"
                  element={<SEBIInterReports />}
                />
                <Route
                  exact
                  path="SEBI/intermediate-subreport"
                  element={<IntermediateSubPage />}
                />
                <Route exact path="Others" element={<Others />} />
                <Route
                  exact
                  path="Others/not-as-report"
                  element={<NotASReport />}
                />
                <Route
                  exact
                  path="Others/not-as-level"
                  element={<NotASLevel1Report />}
                />
                <Route exact path="Others/not-in-sebi" element={<NotInSEBI />} />
                <Route
                  exact
                  path="Others/not-level-sebi"
                  element={<NotInLevelSebi />}
                />
                <Route exact path="Others/none-report" element={<NoneReport />} />
                <Route
                  exact
                  path="Others/none-level-report"
                  element={<NoneLevel1Report />}
                />
                <Route exact path="MIS" element={<MIS />} />
                <Route
                  exact
                  path="MIS/trend-analysis-report"
                  element={<TrendAnalysisReport />}
                />
                <Route exact path="MIS/QaQComparison" element={<QaQComparison />} />
                <Route
                  exact
                  path="MIS/reimport-import"
                  element={<ReimportReport />}
                />
                <Route exact path="MIS/mis-import" element={<MISReport />} />
                <Route exact path="Settings" element={<Settings />} />
                <Route
                  exact
                  path="Settings/reporting-date"
                  element={<ReportingDate />}
                />
                <Route exact path="Settings/file-change" element={<FileChange />} />
                <Route exact path="viewlog" element={<ViewLog />} />
              </Route>
              <Route path="/logout" element={<Logout />} />
            </>
          </Routes>
          <NotificationContainer />
        </>
      ) : (
        <h4>Disconnected : There is no Internet Connection</h4>
      )}

    </Router>
  );
}

export default App;
