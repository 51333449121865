import "./action.css";
import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import DownloadOverlay from "../DownloadModal/DownloadOverlay";
import * as Constants from "../../../constants/stringConstants";
import { ReactComponent as DownloadIcon } from "../../../assets/images/download.svg";
import { ReactComponent as QuickViewIcon } from "../../../assets/images/quck-view.svg";
import { getDownloadFiles } from "../../../Api";
import { handleError } from "../../../Services/ErrorService";
import { NotificationManager } from "react-notifications";

const ActionOverlay = ({ getprocessName, logFiles }) => {
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [sessionKey, setSessionKey] = useState("");
    const [userId, setUserId] = useState("");
    const [reportDate, setReporteDate] = useState("");
    const [, setBranchCode] = useState("");
    const [, setGetMessage] = useState(false)
    const [downLoadPath, ] = useState()


    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getBranch = await localStorage.getItem("branch_code");
            setBranchCode(getBranch);

            let getdate = await localStorage.getItem("reporting_date");
            setReporteDate(getdate);


        };
        checkAuth();
    }, []);

    const getDownloadedFiles = () => {
        if((logFiles.details[getprocessName]["Total Count"] !== "0" || logFiles.details[getprocessName]["Exception Count"] != "0") && logFiles.details[getprocessName]["File Download"] === "Download"){
            getDownloadFiles(
                "downloadfile",
                sessionKey,
                userId,
                reportDate,
                reportDate,
                logFiles.details[getprocessName].IMPType
            )
                .then((response) => {
                    setIsDownloaded(true);
                    setGetMessage(true);
    
                    const blob = new Blob([response.data], {
                        type: "application/vnd.ms-excel",
                    });
    
                    // Create a URL for the blob
                    const url = window.URL.createObjectURL(blob);
    
                    // Create an <a> element to trigger the download
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${logFiles.details[getprocessName].ImpTypeDesc}.xls`; // You can specify the desired file name
    
                    // Trigger a click event to start the download
                    a.click();
    
                    // Release the URL object
                    window.URL.revokeObjectURL(url);
                })
                .catch((err) => {
                    setIsDownloaded(false);
                    setGetMessage(false);
                    handleError(err);
                });
        } else {
            NotificationManager.error("No Records Available");
        }
    };



    return (
        <>
            <ListGroup className="action-overlay">
                <ListGroup.Item>
                    {" "}
                    <QuickViewIcon /> <p>{Constants.quickViewText}</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={() => getDownloadedFiles()}>
                    {" "}

                    <DownloadIcon /> <p>{Constants.downloadText}</p>
                </ListGroup.Item>
            </ListGroup>
            {
                isDownloaded === true ?
                    <DownloadOverlay
                        show={isDownloaded}
                        isDownloaded={isDownloaded}
                        downLoadPath={downLoadPath}
                        onHide={() => setIsDownloaded(false)}
                    /> : ""
            }
            {/* <h1>{downLoadPath}</h1> */}
        </>
    );
};

export default ActionOverlay;
