import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { BackBtn } from "../../components/Buttons/CustomButton";
import { updateException } from "../../Api";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const UpdateException = () => {
  const location = useLocation();
  const [, setSessionKey] = useState("");
  const [, setUserId] = useState("");
  const [, setReporteDate] = useState("");
  const [update_exception, setUpdate_exception] = useState([]);
  const [heading, setHeading] = useState([]);

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        await updateTotalcountException(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        await updateTotalcountException(getSessionId, getUserId, getdate);
      }
    };
    checkAuth();
  }, []);

  /* The code block is assigning a value to the variable `getActions` based on the value of
    `location.state.action`. */

  let getActions = "";

  if (location?.state?.action === "Re-Import" || "Import") {
    getActions = "Pending";
  } else if (location?.state?.action === "Authorisation") {
    getActions = "Authorise";
  } else {
    getActions = "Cancel";
  }

  /**
   * The function `updateTotalcountException` updates the exception data for a given session key, user
   * ID, and report date.
   * @param sessionKey - The sessionKey parameter is a unique identifier for the current session. It is
   * used to authenticate and authorize the user's access to certain resources or actions.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the exception
   * is being updated.
   * @param reportDate - The `reportDate` parameter is the date for which the exception report is being
   * updated.
   */

  const updateTotalcountException = (sessionKey, userId, reportDate) => {
    updateException(
      "summarydata",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      location.state && location?.state?.type,
      location.state && location?.state?.imptype,
      "",
      getActions,
      "ALL",
      "",
      "YES"
    ).then((response) => {
      if (response) {
        setUpdate_exception(response?.data?.KCSRPT || null);
        let tableHeading = response?.data?.KCSRPT?.details
          ? Object.keys(response?.data?.KCSRPT?.details[0])
          : [];

        setHeading(tableHeading);
      }
    });
  };

  return (
    <MainLayout>
      <div className="total-count-layoutWrapper">
        <div className="content-body">
          <div className="content-header pb-4">
            <div className="content-header-left">
              <h1>{Constants.updateExceptionText}</h1>
            </div>
            <div className="content-header-right">
              <GernerateCSVFile
                generateCSV={update_exception}
                reportName={"updateException"}
              />
              <BackBtn />
            </div>
          </div>
          <div className="content-table main-report-table ">
            <div className="table-container">
              <table className="table">
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                  <tr>
                    {heading
                      ? heading.map((title, columnIndex) => {
                          if (columnIndex === 0) {
                            return null;
                          }
                          if (columnIndex === 1) {
                            return (
                              <th
                                key={title[columnIndex]}
                                className="heading_font"
                              >
                                select
                              </th>
                            );
                          }
                          return (
                            <th key={title} className="heading_font">
                              {title}
                            </th>
                          );
                        })
                      : null}
                  </tr>
                </thead>

                <tbody>
                  {update_exception?.details &&
                    update_exception.details.map((row, rowIndex) => (
                      <tr key={row[rowIndex]}>
                        {Object.keys(row).map((columnName, columnIndex) => {
                          if (columnIndex === 0) {
                            return null;
                          }

                          const value = row[columnName];
                          let formattedValue = value;
                          if (columnIndex === 1) {
                            return (
                              <td key={columnName}>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="value1"
                                    // onChange={() => {
                                    //     getSelectedOptions(row.idcol);
                                    // }}
                                    // checked={checkedValues.includes(row.idcol)}
                                  />
                                </div>
                              </td>
                            );
                          }

                          return (
                            <>
                              <td
                                className={`${
                                  !isNaN(row[columnName])
                                    ? "report_alignment main_report"
                                    : "dashed"
                                }`}
                                key={columnName}
                              >
                                <b>{formattedValue}</b>
                              </td>
                            </>
                          );
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default UpdateException;
