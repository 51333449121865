import React from 'react'
import Header from '../../components/Header/Header'
import MainLayout from '../../components/Layout/MainLayout'
import LayoutBtn from '../../components/LayoutBtn/LayoutBtn'
import * as Constants from '../../constants/stringConstants';
import { ReactComponent as ReportIcon } from '../../assets/images/Document.svg'
import { ReactComponent as InfoIcon } from '../../assets/images/Activity_one.svg'


const SEBI = () => {
    return (
        <MainLayout>
            <div className='layoutWrapper'>
                <div className='header-wrapper'>
                    <h1 className='header'> {Constants.SebiTitle} </h1>
                    <Header />
                </div>
                <div>
                    <LayoutBtn Icon={<InfoIcon />} title="Main Report" path="main-report" />
                    <LayoutBtn Icon={<ReportIcon />} title="Intermediate Report" path="intermediate-report"  />
                </div>     
                
             </div>
        </MainLayout>

    )
}

export default SEBI