import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { ReactComponent as FolderIcon } from "../../assets/images/Folder.svg";
import { getSebiLevel2params } from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";
import { formatDateToYYYYMM } from "../../utils";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const SEBILevel2Report = () => {
    const navigate = useNavigate();
    const [, setSessionKey] = useState("");
    const [, setUserId] = useState("");
    const [, setBranchCode] = useState("");
    const [getSebiReports, setGetSebiReports] = useState([]);
    const [heading, setHeading] = useState();
    const [reportDate, setReporteDate] = useState("");
    const location = useLocation();
    const [getCount, setGetCount] = useState("");
    const [, setFinancialYear] = useState("");
    const [, setFinancialMonth] = useState("");
    const [get_date, setGet_date] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [loader, setLoader] = useState(true)
    const selectedReportData =
        location?.state && location.state ? location.state : "";


    /* The `const datePattern = /^\d{4}\d{2}$/;` is a regular expression pattern that is used to
      validate a date string. */

    const datePattern = /^\d{4}\d{2}$/;

    /* The code is assigning a value to the `date` variable. It checks if the
      `selectedReportData.reportType` matches the `datePattern` regular expression. If it does, it
      formats the `selectedReportData.reportType` using the `formatDateToYYYYMM` function. If it doesn't
      match the pattern, it assigns the `selectedReportData.reportType` as is to the `date` variable. */

    const date =
        datePattern.test(formatDateToYYYYMM(selectedReportData.reportType)) === true
            ? formatDateToYYYYMM(selectedReportData.reportType)
            : selectedReportData.reportType;

    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getBranch = await localStorage.getItem("branch_code");
            setBranchCode(getBranch);

            let userDate = await localStorage.getItem("date_key");
            let getdate = await localStorage.getItem("reporting_date");

            let getFinancialYear = await localStorage.getItem("Financial_year");
            setFinancialYear(getFinancialYear);

            let getFinancialMonth = await localStorage.getItem("Financial_month");
            setFinancialMonth(getFinancialMonth);

            if (userDate) {
                setReporteDate(userDate);
                const dateObj = new Date(userDate);
                // Extract the year from the Date object
                const year = dateObj.getFullYear();
                await getLevel1paramaters(
                    getSessionId,
                    getUserId,
                    userDate,
                    year
                );
            } else {
                setReporteDate(getdate);
                const dateObj = new Date(getdate);
                // Extract the year from the Date object
                const year = dateObj.getFullYear();
                await getLevel1paramaters(
                    getSessionId,
                    getUserId,
                    getdate,
                    year
                );
            }
        };
        checkAuth();
    }, [get_date]);

    // Get Total Count

    useEffect(() => {
        let count =
            (getSebiReports?.details &&
                getSebiReports?.details?.map((ele) =>
                    Number(ele["Transaction Amount"])
                )) ||
            [];
        // This will create an array with the TotalAmt property of each object converted to a number

        let Total_Amount = count.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        setGetCount(Total_Amount);
    }, [getSebiReports]);

    /**
     * The function `getLevel1paramaters` retrieves level 1 parameters using a session key, user ID, and
     * selected report data, and then sets the retrieved reports.
     * @param sessionKey - The sessionKey parameter is a unique identifier that is used to authenticate and
     * authorize a user's session. It is typically generated when a user logs in or authenticates with a
     * system or application.
     * @param userId - The `userId` parameter is the unique identifier of the user for whom the level 1
     * parameters are being fetched.
     */

    const getLevel1paramaters = (
        sessionKey,
        userId,
        reportDate,
        year,
    ) => {
        if (selectedReportData) {
            setLoader(true)
            getSebiLevel2params(
                "as18rptlevel2",
                sessionKey,
                userId,
                reportDate,
                reportDate,
                "SEBI",
                "SEBI MAIN REPORT",
                date,
                selectedReportData?.reportingHead &&
                selectedReportData?.reportingHead?.RPTName,
                selectedReportData?.reportingHead &&
                selectedReportData?.reportingHead?.SEBIMainHead,
                selectedReportData?.reportingHead &&
                selectedReportData?.reportingHead?.SEBISubHead,
                "PrevAmt",
                selectedReportData?.reportingAmt,
                "Mar" + " " + year
            )
                .then((res) => {
                    if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
                        setErrorMessage(true);
                        setLoader(false)
                        NotificationManager.error(res?.data?.KCSRPT?.details[0].ErrorMsg);
                    } else if (res?.data?.KCSRPT?.details[0].REM) {
                        setLoader(false)
                        setErrorMessage(true);
                        NotificationManager.error(res?.data?.KCSRPT?.details[0].REM);
                    } else {
                        setErrorMessage(false);
                        setLoader(false)
                        setGetSebiReports(res.data && res?.data?.KCSRPT);
                        let tableHeading = Object.keys(res?.data?.KCSRPT?.details[0]);
                        setHeading(tableHeading);
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    handleError(err);
                });
        }
    };

    return (
        <MainLayout>
            <div className="layoutWrapper">
                <div className="header-wrapper">
                    <h1 className="header"> {Constants.sebilevelreport2} </h1>
                    <Header setGet_date={setGet_date} get_date={get_date} />
                </div>
                <div className="content-body">
                    <div className="content-header">
                        <div className="content-header-left">
                            <h1>
                                {Constants.reportingDateTime}
                                {reportDate}
                            </h1>
                        </div>
                        <div className="content-header-right ">
                            {errorMessage ? (
                                <h3 className="err_msg">
                                    <b>No Data Found</b>{" "}
                                </h3>
                            ) : (
                                <h3 className="total-amt">
                                    {Constants.totalAmt}
                                    {getCount.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </h3>
                            )}
                            <GernerateCSVFile
                                generateCSV={getSebiReports}
                                reportName={"SEBILevel2Report"}
                            />
                            <button className="back-btn" onClick={() => navigate(-1)}>
                                {Constants.backBtnText}
                            </button>
                        </div>
                    </div>
                    {
                        loader === false ?
                            <div className="content-table main-report-table">
                                <ResolvedApi
                                    get_response={getSebiReports}
                                    heading={heading}
                                    navigateToReportDetails={""}
                                />
                            </div>
                            : <div className="spinner-loader">
                                <div class="spinner-border d-flex m-auto" role="status"></div>
                            </div>
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default SEBILevel2Report;
