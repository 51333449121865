import React, { useState, useEffect } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import FreezeOverlay from "../Overlay/FreezeModal/FreezeOverlay";
import { useNavigate } from "react-router-dom";

const CustomDropdown = ({ title, options, isModal }) => {
  const [showFreezeOverlay, setShowFreezeOverlay] = useState(false);
  const [data, setData] = useState("");
  const [sessionKey, setSessionKey] = useState("");
  const [userId, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [heading, setHeading] = useState("");
  const navigate = useNavigate()


  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let getdate = await localStorage.getItem("reporting_date");
      setReporteDate(getdate);

    };
    checkAuth();
  }, []);

  const freezeQuarter = (value) => {
    setData(value);
    setShowFreezeOverlay(true);
  
  };

  return (
    <>
      <DropdownButton className="quarterBtn" title={title}>
        {options?.map((option, index) => (
          <Dropdown.Item
            key={index}
            href=""
            onClick={() => freezeQuarter(option)}
          >
            {option}
          </Dropdown.Item>
        ))}
      </DropdownButton>

      {isModal && (
        <FreezeOverlay
          show={showFreezeOverlay}
          onHide={() => setShowFreezeOverlay(false)}
          title={data}
        />
      )}
      {/* <div className="content-table reporting-table">
        <ResolvedApi
          get_response={reimbursementInfo}
          heading={heading}
          navigateToReportDetails={""}
        />
      </div> */}
    </>
  );
};

export default CustomDropdown;
