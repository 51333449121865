import "./As.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const SignificantReport = () => {
  const navigate = useNavigate();
  const [, setSessionKey] = useState("");
  const [, setUserId] = useState("");
  const [, setBranchCode] = useState("");
  const [getsignificantReports, setGetsignificantReports] = useState([]);
  const [reportDate, setReporteDate] = useState("");
  const location = useLocation();
  const [get_date, setGet_date] = useState("");

  /* The `useEffect` hook is used to perform side effects in functional components. In this case, the
    `useEffect` hook is used to retrieve session key, user ID, and branch code from the local storage
    and set them as state variables (`sessionKey`, `userId`, `branchCode`). */

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
      } else {
        setReporteDate(getdate);
      }

      setGetsignificantReports(location.state && location?.state);
    };
    checkAuth();
  }, [get_date]);

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header">{Constants.significantText}</h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left">
              <h1>
                {Constants.reportingDateTime}
                {reportDate}
              </h1>
            </div>
            <div className="content-header-right">
              <GernerateCSVFile
                generateCSV={getsignificantReports?.KCSRPT}
                reportName={"AS18SignificantReport"}
              />
              <button className="back-btn" onClick={() => navigate(-1)}>
                {Constants.backBtnText}
              </button>
            </div>
          </div>
          <div className="content-table main-report-table">
            <ResolvedApi
              get_response={getsignificantReports?.KCSRPT || null}
              heading={
                getsignificantReports?.KCSRPT?.details[0]
                  ? Object.keys(getsignificantReports?.KCSRPT.details[0])
                  : []
              }
              navigateToReportDetails={""}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SignificantReport;
