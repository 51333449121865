import "./As.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { get_AS_level2_parameters } from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { NotificationManager } from "react-notifications";
import { formatDateToYYYYMM } from "../../utils";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const SingleReportDetails = () => {
    const navigate = useNavigate();
    const [, setSessionKey] = useState("");
    const [, setUserId] = useState("");
    const [, setBranchCode] = useState("");
    const location = useLocation();
    const [heading, setHeading] = useState();
    const [getReportingDetails, setGetReportingDetails] = useState([]);
    const [reportDate, setReporteDate] = useState("");
    const [, setFinancialYear] = useState("");
    const [, setFinancialMonth] = useState("");
    const [errorMessage, setErrorMessage] = useState(false)
    const getLevel2Deatils =
        location?.state && location.state ? location.state : "";
    const [getCount, setGetCount] = useState("");
    const [get_date, setGet_date] = useState("");
    const [loader, setLoader] = useState(true)

    /* The `const datePattern = /^\d{4}\d{2}$/;` is a regular expression pattern that matches a specific
    date format. */

    const datePattern = /^\d{4}\d{2}$/;

    /* The code is checking if the `colname` property of the `getLevel2Deatils` object matches a
    specific date pattern. If it does, it formats the date using the `formatDateToYYYYMM` function.
    If it doesn't match the pattern, it assigns the `colname` value as it is. The resulting value is
    stored in the `date` variable. */


    const date =
        datePattern.test(formatDateToYYYYMM(getLevel2Deatils.colname)) === true
            ? formatDateToYYYYMM(getLevel2Deatils.colname)
            : getLevel2Deatils.colname;



    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getBranch = await localStorage.getItem("branch_code");
            setBranchCode(getBranch);

            let userDate = await localStorage.getItem("date_key");
            let getdate = await localStorage.getItem("reporting_date");

            let getFinancialYear = await localStorage.getItem("Financial_year");
            setFinancialYear(getFinancialYear);

            let getFinancialMonth = await localStorage.getItem("Financial_month");
            setFinancialMonth(getFinancialMonth);

            if (userDate) {
                setReporteDate(userDate);
                const dateObj = new Date(userDate);
                // Extract the year from the Date object
                const year = dateObj.getFullYear();

                await getSingleReports(
                    getSessionId,
                    getUserId,
                    userDate,
                    year
                );
            } else {
                setReporteDate(getdate);
                const dateObj = new Date(getdate);
                // Extract the year from the Date object
                const year = dateObj.getFullYear();

                await getSingleReports(
                    getSessionId,
                    getUserId,
                    getdate,
                    year
                );
            }
        };
        checkAuth();
    }, [get_date]);


    //  Get Total Count

    useEffect(() => {
        let count =
            (getReportingDetails?.details &&
                getReportingDetails.details.map((ele) => Number(ele["Transaction Amount"]))) ||
            [];
        // This will create an array with the TotalAmt property of each object converted to a number

        let Total_Amount = count.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        setGetCount(Total_Amount);
    }, [getReportingDetails]);


    /**
     * The function `getSingleReports` retrieves single reports using session key and user ID, and handles
     * the response by displaying an error message if there is one, or setting the reporting details and
     * table heading if successful.
     * @param sessionKey - The sessionKey parameter is a unique identifier that represents the current
     * session or user session in the application. It is used to authenticate and authorize the user's
     * access to certain resources or data.
     * @param userId - The `userId` parameter is used to identify the user for whom the single reports are
     * being fetched.
     */

    const getSingleReports = (
        sessionKey,
        userId,
        reportDate,
        finanicialYear
    ) => {
        if (getLevel2Deatils) {
            setLoader(true)
            get_AS_level2_parameters(
                "as18rptlevel2",
                sessionKey,
                userId,
                reportDate,
                reportDate,
                "AS",
                "AS MAIN REPORT",
                date,
                getLevel2Deatils?.getDetails && getLevel2Deatils?.getDetails.RPTName,
                getLevel2Deatils?.getDetails &&
                getLevel2Deatils?.getDetails.AS18MainHead,
                getLevel2Deatils?.getDetails && getLevel2Deatils?.getDetails.AS18,
                getLevel2Deatils?.colname && getLevel2Deatils.colname,
                getLevel2Deatils?.colvalue && getLevel2Deatils.colvalue,
                "Mar" + " " + finanicialYear
            )
                .then((response) => {
                    if (response?.data?.KCSRPT?.details[0].ErrorMsg) {
                        setLoader(false)
                        setErrorMessage(true)
                        NotificationManager.error(
                            response?.data?.KCSRPT?.details[0].ErrorMsg
                        );
                    } else {
                        setLoader(false)
                        setGetReportingDetails(response.data && response?.data?.KCSRPT);
                        let tableHeading = Object.keys(
                            response?.data && response?.data?.KCSRPT?.details[0]
                        );
                        setHeading(tableHeading);
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    handleError(err);
                });
        }
    };

    return (
        <MainLayout>
            <div className="layoutWrapper">
                <div className="header-wrapper">
                    <h1 className="header">{Constants.asreportlevel2}</h1>
                    <Header setGet_date={setGet_date} get_date={get_date} />
                </div>
                <div className="content-body">
                    <div className="content-header">
                        <div className="content-header-left">
                            <h1>
                                {Constants.reportingDateTime}
                                {reportDate}
                            </h1>
                        </div>
                        <div className="content-header-right">

                            <h3 className='total-amt'>{Constants.totalAmt}{getCount.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}</h3>

                            <GernerateCSVFile
                                generateCSV={getReportingDetails}
                                reportName={"AS18Level2Report"}
                            />
                            <button className="back-btn" onClick={() => navigate(-1)}>
                                {Constants.backBtnText}
                            </button>
                        </div>
                    </div>
                    {
                        loader === false ?
                            <div className="content-table main-report-table">
                                <ResolvedApi
                                    get_response={getReportingDetails}
                                    heading={heading}
                                    navigateToReportDetails={""}
                                />
                            </div>
                            : <div className="spinner-loader">
                                <div class="spinner-border d-flex m-auto" role="status"></div>
                            </div>
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default SingleReportDetails;
