import './sidebar.css';
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as AS } from "../../assets/images/Graph.svg";
import { ReactComponent as SEBI } from "../../assets/images/Work.svg";
import { ReactComponent as MIS } from "../../assets/images/Activity.svg";
import { ReactComponent as Others } from "../../assets/images/Square.svg";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { ReactComponent as Settings } from "../../assets/images/Setting.svg";
import { ReactComponent as KCSLogo } from '../../assets/images/dashboard.svg';
import { ReactComponent as ServiceBoard } from "../../assets/images/Category.svg";
import FooterLinks from '../FooterLinks/FooterLinks';



export const Sidebar = ({ isExpanded, isCollapseMenu }) => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  let location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1286) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);

  const menuItems = [
    {
      text: "Service Board",
      path: '/dashboard',
      icon: <ServiceBoard />,
      items: [
        {
          text: "Master",
          path: "/dashboard",
        },
        {
          text: "Process",
          path: "/dashboard/process",
        },
        {
          text: "Standard",
          path: "/dashboard/standard",
        },
      ],
    },
    {
      text: "AS",
      path: '/dashboard/AS',
      icon: <AS />,
      items: [
        {
          text: "Main Report",
          path: "/dashboard/AS/main-report",
        },
        {
          text: "Board Note",
          path: "/dashboard/AS/board-note",
        },
        {
          text: "Intermediate Report",
          path: "/dashboard/AS/intermediate-report",
        },

      ],
    },
    {
      text: "SEBI",
      path: '/dashboard/SEBI',
      icon: <SEBI />,
      items: [
        {
          text: "Main Report",
          path: "/dashboard/SEBI/main-report",
        },
        {
          text: "Intermediate Report",
          path: "/dashboard/SEBI/intermediate-report",
        },

      ],
    },
    {
      text: "Others",
      path: '/dashboard/Others',
      icon: <Others />,
      items: [
        {
          text: "Not in AS",
          path: "/dashboard/Others/not-as-report",
        }, {
          text: "Not in SEBI",
          path: "/dashboard/Others/not-in-sebi",
        }, {
          text: "None",
          path: "/dashboard/Others/none-report",
        }
      ]
    },
    {
      text: "MIS",
      path: '/dashboard/MIS',
      icon: <MIS />,
      items: [
        {
          text: "Trend Analysis Report",
          path: "/dashboard/MIS/trend-analysis-report",
        },
        {
          text: "MIS Report",
          path: "/dashboard/MIS/mis-import",
        },
        {
          text: "QoQ Comparison",
          path: "/dashboard/MIS/QaQComparison",
        },
        {
          text: "Reimport Report",
          path: "/dashboard/MIS/reimport-import",
        }
      ]
    },
    {
      text: "Settings",
      path: '/dashboard/Settings',
      icon: <Settings />,
      items: [
        {
          text: "Reporting Date",
          path: "/dashboard/Settings/reporting-date",
        },
        {
          text: "Input File Name Change",
          path: "/dashboard/Settings/file-change",
        }
      ]
    },

  ];


  return (
    <div
      className={
        isExpanded
          ? "side-nav-container"
          : "side-nav-container side-nav-container-NX"
      }
    >
      <div className="nav-upper">
        <div className="nav-heading">
          {isExpanded && (
            <div className="nav-brand">
              <Link to="/dashboard"><KCSLogo /></Link>

            </div>
          )}

          <div onClick={isCollapseMenu} className="menuIcon">
            {
              isExpanded ? <FaLongArrowAltLeft /> : <FaLongArrowAltRight />
            }

          </div>


        </div>

        {
          !isMobile ? isExpanded ? <div className="nav-menu">

            {menuItems.map(({ text, icon, path, items }, index) => {
              const isActive = location?.pathname === path || selectedItem === index;
              const isShowDropdown = selectedItem === index || items?.some(({ path: subPath }) => location?.pathname === subPath); // Update 'isExpanded' based on whether its subitem is active or not
              const hasActiveSubItem = items?.some(({ path: subPath }) => location?.pathname === subPath);

              return (
                <div key={index} className="accordion">
                  <div
                    className="accordion__header"
                    onClick={() => {
                      if (isShowDropdown && items && items.length > 0) {
                        return;
                      }
                      setSelectedItem(isShowDropdown ? null : index);
                    }}
                  >

                    <Link
                      className={isActive || isShowDropdown || hasActiveSubItem ? 'menu-item active' : 'menu-item'}
                      onClick={() => {
                        setSelectedItem(index);
                      }}
                    >
                      {icon}
                      <div className="nav-menu-row">
                        <p>{text}</p>
                      </div>
                    </Link>
                  </div>
                  {items && items.length > 0 && isShowDropdown && (
                    <div className={`accordion__body ${isShowDropdown && isActive ? 'active' : ''}`}>
                      {items.map(({ text: subText, path: subPath }, subIndex) => (
                        <Link
                          key={subIndex}
                          className={location?.pathname === subPath ? 'menu-item__subitem active' : 'menu-item__subitem'}
                          to={subPath}
                          onClick={(e) => {
                            setSelectedItem(index);
                          }}
                        >
                          {subText}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}



            <hr />
            <FooterLinks />
          </div> :

            <div className="nav-menu">
              {menuItems.map(({ text, icon, path }, index) => {
                const isActive = location?.pathname === path;

                return (
                  <div key={index} className="accordion">
                    <div
                      className="accordion_header"
                    >
                      <Link
                        className={isActive ? 'menu-item active' : 'menu-item'}
                        to={path}
                      >
                        {icon}
                        <div className="nav-menu-row">
                          <p>{text}</p>
                        </div>
                      </Link>
                    </div>


                  </div>
                );
              })}
              <hr />
              <FooterLinks />
            </div> :
            <div className="nav-menu">
              {menuItems.map(({ text, icon, path, items }, index) => {
                const isActive = location?.pathname === path || selectedItem === index;
                const isShowDropdown = selectedItem === index || items?.some(({ path: subPath }) => location?.pathname === subPath);
                const hasActiveSubItem = items?.some(({ path: subPath }) => location?.pathname === subPath);

                return (
                  <div key={index} className="accordion">
                    <div
                      className="accordion__header collapse-mainMenu"
                      onClick={() => {
                        setSelectedItem(index);
                      }}
                    >
                      <Link
                        className={isActive || isShowDropdown || hasActiveSubItem ? 'menu-item active' : 'menu-item'}

                        to={path}

                      >
                        {icon}
                        <div className="nav-menu-row">
                          <p>{text}</p>
                        </div>
                      </Link>
                    </div>

                    {items && items.length > 0 && isShowDropdown && showSubMenu && (
                      <div className="collapsed-submenu">
                        {items.map(({ text: subText, path: subPath }, subIndex) => (
                          <Link
                            key={subIndex}
                            className={location?.pathname === subPath ? 'menu-item__subitem active' : 'menu-item__subitem'}
                            to={subPath}
                            onClick={(e) => {
                              setSelectedItem(index);
                            }}
                          >
                            {subText}
                          </Link>
                        ))}
                      </div>
                    )}

                  </div>
                );
              })}
              <hr />
              <FooterLinks />
            </div>
        }
      </div>
    </div>

  )
}
