import "./viewLog.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import { ReactComponent as SortIcon } from "../../assets/images/sort.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/Search.svg";
import { ReactComponent as Filtercon } from "../../assets/images/filter.svg";
import { ReactComponent as CategoryLight } from "../../assets/images/CategoryLight.svg";
import DownloadOverlay from "../../components/Overlay/DownloadModal/DownloadOverlay";
import CountInformation from "../../components/Overlay/CountInformation/CountInformation";
import { getLogFiles } from "../../Api";
import { handleError } from "../../Services/ErrorService";
import { BackBtn } from "../../components/Buttons/CustomButton";

const ViewLog = () => {
  const [countModalShow, setCountModalShow] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [, setActionOverlay] = useState(false);
  const [, setActionId] = useState(null);
  const [showsorting, setShowSorting] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [showfilter, setShowFilter] = useState(false);
  const location = useLocation();
  const [, setSessionKey] = useState("");
  const [, setUserId] = useState("");
  const [reportDate, setReporteDate] = useState("");
  const [, setBranchCode] = useState("");
  const [downLoadPath, setDownLoadPath] = useState();
  const [get_date, setGet_date] = useState("")
  const getLogFilesPath = location.state && location?.state;

  useEffect(() => {
    const checkAuth = async () => {
      let getSessionId = await localStorage.getItem("session_key");
      setSessionKey(getSessionId);

      let getUserId = await localStorage.getItem("user_Id");
      setUserId(getUserId);

      let getBranch = await localStorage.getItem("branch_code");
      setBranchCode(getBranch);

      let userDate = await localStorage.getItem("date_key");
      let getdate = await localStorage.getItem("reporting_date");

      if (userDate) {
        setReporteDate(userDate);
        await getViewLogContent(getSessionId, getUserId, userDate);
      } else {
        setReporteDate(getdate);
        await getViewLogContent(getSessionId, getUserId, getdate);
      }

    };
    checkAuth();
  }, [get_date]);

  /**
   * The function `getViewLogContent` retrieves log files based on the session key, user ID, and report
   * date, and sets the download path for the log files.
   * @param sessionKey - The sessionKey parameter is a unique identifier that represents a user's session
   * or login session. It is used to authenticate and authorize the user's actions and access to
   * resources within the system.
   * @param userId - The `userId` parameter is the unique identifier of the user for whom the log files
   * are being retrieved.
   * @param reportDate - The `reportDate` parameter is a date that specifies the date of the report. It
   * is used in the `getLogFiles` function to retrieve log files for that specific date.
   */

  const getViewLogContent = (sessionKey, userId, reportDate) => {
    getLogFiles(
      "basefilecanc",
      sessionKey,
      userId,
      reportDate,
      reportDate,
      getLogFilesPath?.logFiles?.details[getLogFilesPath?.getprocessName]
        ?.IMPType,
      ""
    )
      .then((response) => {
        if (response) {
          const logfilename = response.data;
          setDownLoadPath(logfilename);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  /**
   * hideActionOverlay :: Hide Acction Overlay popup
   */
  const hideActionOverlay = () => {
    setActionOverlay(false);
    setActionId(null);
  };

  /**
   * After 5 seconds hide action popup
   */
  setTimeout(hideActionOverlay, 5000);

  const options = [
    { label: "Authorized Successfully", value: "success" },
    { label: "Import Failed with Error", value: "failed" },
    { label: "Import Sucess, Exp Pending", value: "Exp_Pending" },
    { label: "Import Sucess, Aut Pending", value: "Auth_Pending" },
  ];

  const checkboxes = options.map((option) => (
    <Form.Check
      key={option.value}
      type="checkbox"
      label={option.label}
      value={option.value}
      checked={checkboxValues.includes(option.value)}
      onChange={(e) => {
        const isChecked = e.target.checked;
        const value = e.target.value;
        setCheckboxValues((prevValues) =>
          isChecked
            ? [...prevValues, value]
            : prevValues.filter((v) => v !== value)
        );
      }}
    />
  ));

  return (
    <MainLayout>
      <div className="layoutWrapper">
        <div className="header-wrapper">
          <h1 className="header">{Constants.welcomeText}</h1>
          <Header setGet_date={setGet_date} get_date={get_date} />
        </div>
        <div className="content-body">
          <div className="content-header">
            <div className="content-header-left">
              <h1>
                {" "}
                {Constants.reportingDateTime}
                {reportDate}{" "}
              </h1>

              <div className="dropDownContainer custom-row">
                <CategoryLight />
                <CustomDropdown
                  className="viewLogDropDown"
                  title="Master"
                  options={["FY22 Q3", "FY22 Q2"]}
                />
              </div>
            </div>
            <div className="content-header-right">
              {/* <h4 className="iconRow">
                <SearchIcon /> {Constants.searchText}
              </h4>
              <h4 className="iconRow position-relative">
                {" "}
                <>
                  {" "}
                  <SortIcon onClick={() => setShowSorting(true)} />{" "}
                  {Constants.sortText}{" "}
                </>{" "}
                {showsorting && (
                  <div className="sort-box">
                    <p> {Constants.sortByText} </p>
                    <div className="radio-group">
                      <label>
                        {" "}
                        <input
                          type="radio"
                          value="total_count"
                          name="count"
                        />{" "}
                        {Constants.totalCountText}{" "}
                      </label>
                      <label>
                        {" "}
                        <input
                          type="radio"
                          value="exception_count"
                          name="count"
                        />{" "}
                        {Constants.exceptionCountText}{" "}
                      </label>
                    </div>
                    <button
                      className="apply-btn"
                      onClick={() => setShowSorting(false)}
                    >
                      {Constants.applyBtnText}
                    </button>
                  </div>
                )}{" "}
              </h4>
              <h4 className="iconRow position-relative">
                {" "}
                <Filtercon onClick={() => setShowFilter(true)} />{" "}
                {showfilter && (
                  <div className="sort-box filter-box">
                    <p> {Constants.filterText} </p>
                    <Form.Group controlId="formCheckboxGroup">
                      {checkboxes}
                    </Form.Group>
                    <button
                      className="apply-btn"
                      onClick={() => setShowFilter(false)}
                    >
                      {Constants.applyBtnText}
                    </button>
                  </div>
                )}{" "}
              </h4> */}
              <BackBtn />
            </div>
          </div>
          <div className="log-report mt-3">
            <pre>{downLoadPath}</pre>
          </div>
        </div>
        <div className="content-footer">
          <p>{Constants.footerText}</p>
        </div>

        <DownloadOverlay
          show={isDownloaded}
          onHide={() => setIsDownloaded(false)}
        />
        <CountInformation
          show={countModalShow}
          onHide={() => setCountModalShow(false)}
        />
      </div>
    </MainLayout>
  );
};

export default ViewLog;
