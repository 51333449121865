import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatDateToDDMMMYYYY } from "../../utils";
import MainLayout from "../../components/Layout/MainLayout";
import { Form } from "react-bootstrap";
import { BackBtn } from "../../components/Buttons/CustomButton";
import * as Constants from "../../constants/stringConstants";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const TotalcountContent = () => {
    const location = useLocation();
    const [getContent, setGetContent] = useState([]);
    const [heading, setHeading] = useState([]);

    useEffect(() => {
        setGetContent((location.state && location?.state) || null);
        setHeading(
            location?.state?.details[0] ? Object.keys(location?.state?.details[0]) : []
        );
    }, [getContent]);

    return (
        <MainLayout>
            <div className="total-count-layoutWrapper content-click">
                <div className="content-body">
                    <div className="content-header pb-4">
                        <div className="content-header-left">
                            <h1>{Constants.totalcountContentText}</h1>
                            <Form>
                                {["radio"].map((type) => (
                                    <div key={`inline-${type}`} className="">
                                        <Form.Check
                                            inline
                                            label="Pending "
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="Authorised"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                        />
                                        <Form.Check
                                            inline
                                            label="Cancelled Pending"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-3`}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </div>
                        <div className="content-header-right">
                            <BackBtn />
                        </div>
                    </div>
                    <div className="filter-row">
                        <div className="content-header-left"></div>
                        <GernerateCSVFile
                            generateCSV={getContent}
                            reportName={"ContentClick"}
                        />
                    </div>

                    <div className="content-table main-report-table ">
                        <div className="table-container">
                            <table className="table">
                                <thead
                                    style={{
                                        position: "sticky",
                                        top: 0,
                                        background: "white",
                                        zIndex: 1,
                                    }}
                                >
                                    <tr>
                                        {heading
                                            ? heading.map((title) => {
                                                return (
                                                    <th key={title} className="heading_font">
                                                        {title}
                                                    </th>
                                                );
                                            })
                                            : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {getContent?.details &&
                                        getContent?.details.map((row, rowIndex) => (
                                            <tr key={row[rowIndex]}>
                                                {Object.keys(row).map((columnName) => {
                                                    const value = row[columnName];
                                                    let formattedValue = value;

                                                    // Check if the value is a valid date and not a number
                                                    const dateValue = new Date(value);
                                                    if (
                                                        !isNaN(dateValue.getDate()) &&
                                                        isNaN(Number(value))
                                                    ) {
                                                        // Format the date as you desire
                                                        const options = {
                                                            year: "numeric",
                                                            month: "short",
                                                            day: "numeric",
                                                        };

                                                        formattedValue = formatDateToDDMMMYYYY(
                                                            dateValue.toLocaleDateString("en-US", options)
                                                        );
                                                    } else if (
                                                        columnName === "Financial Year" ||
                                                        columnName === "FinYear" ||
                                                        columnName === "Financial Year" ||
                                                        columnName === "Imported On"
                                                    ) {
                                                        // If not a date and matches specific column names, format as needed
                                                        formattedValue = value || "-";
                                                    } else if (
                                                        (columnName === "AcctNo" || columnName === "CustId") &&
                                                        !isNaN(parseFloat(value))
                                                    ) {
                                                        // Remove .00 if it's a whole number
                                                        formattedValue =
                                                            parseFloat(value) % 1 === 0
                                                                ? parseFloat(value).toFixed(0)
                                                                : value;
                                                    } else {
                                                        // Handle other columns, e.g., numeric formatting
                                                        const numericValue = parseFloat(value);
                                                        if (!isNaN(numericValue)) {
                                                            formattedValue = numericValue.toLocaleString(
                                                                "en-US",
                                                                {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            );
                                                        }
                                                    }

                                                    // Conditionally apply a class for left alignment if it's a date
                                                    const isDate =
                                                        !isNaN(dateValue.getDate()) && isNaN(Number(value));
                                                    const tdClassName = ` ${isDate ? "left_align" : ""}`;

                                                    return (
                                                        <td
                                                            className={`${!isNaN(row[columnName])
                                                                ? "report_alignment main_report"
                                                                : "dashed"
                                                                }${tdClassName}`}
                                                            key={columnName}
                                                        >
                                                            <b>{formattedValue}</b>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="summary-row">
                        {getContent?.summary &&
                            getContent?.summary?.map((summary, i) => {
                                return (
                                    <div className="summary-data" key={summary[i]}>
                                        { }
                                        <p style={{ fontWeight: "bold" }}>
                                            {summary?.exception ? summary?.exception : "-"}
                                        </p>
                                        <p>{summary?.summary ? summary?.summary : "-"} </p>
                                        {summary?.lnkIgnoreExcep === "YES" ? (
                                            <button
                                                className="backBtn mt-3"

                                            >
                                                {Constants.ignoreException}
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                        {summary?.exception_view === "YES" ? (
                                            <button>{Constants.exceptionView}</button>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="content-footer">
                    <p>{Constants.footerText}</p>
                </div>
            </div>
        </MainLayout>
    );
};

export default TotalcountContent;
