import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import MainLayout from "../../components/Layout/MainLayout";
import * as Constants from "../../constants/stringConstants";
import { getSebiLevel1Intermidiate } from "../../Api";
import { NotificationManager } from "react-notifications";
import { handleError } from "../../Services/ErrorService";
import { formatDateToYYYYMM } from "../../utils";
import ResolvedApi from "../../components/api/ResolvedApi";
import GernerateCSVFile from "../generateCsv/GernerateCSVFile";

const IntermediateSubPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [, setSessionKey] = useState("");
    const [, setUserId] = useState("");
    const [, setBranchCode] = useState("");
    const [heading, setHeading] = useState();
    const [reportDate, setReporteDate] = useState("");
    const [sebiSubReport, setSebiSubReport] = useState([]);
    const [, setFinancialYear] = useState("");
    const [, setFinancialMonth] = useState("");
    const [getCount, setGetCount] = useState("");
    const [get_date, setGet_date] = useState("");
    const [loader, setLoader] = useState(true)
    const getSebiIntermidiate =
        location?.state && location.state ? location.state : "";


    /* The `const datePattern = /^\d{4}\d{2}$/;` is a regular expression pattern that is used to
      validate a date string. */

    const datePattern = /^\d{4}\d{2}$/;

    /* The code is checking if the `as18head` property of the `getSebiIntermidiate` object matches the
      `datePattern` regular expression. If it does, it formats the date using the `formatDateToYYYYMM`
      function. If it doesn't match the pattern, it assigns the `as18head` value as it is. The
      resulting value is stored in the `date` variable. */

    const date =
        datePattern.test(formatDateToYYYYMM(getSebiIntermidiate.as18head)) === true
            ? formatDateToYYYYMM(getSebiIntermidiate.as18head)
            : getSebiIntermidiate.as18head;

    useEffect(() => {
        const checkAuth = async () => {
            let getSessionId = await localStorage.getItem("session_key");
            setSessionKey(getSessionId);

            let getUserId = await localStorage.getItem("user_Id");
            setUserId(getUserId);

            let getBranch = await localStorage.getItem("branch_code");
            setBranchCode(getBranch);

            let userDate = await localStorage.getItem("date_key");
            let getdate = await localStorage.getItem("reporting_date");

            let getFinancialYear = await localStorage.getItem("Financial_year");
            setFinancialYear(getFinancialYear);

            let getFinancialMonth = await localStorage.getItem("Financial_month");
            setFinancialMonth(getFinancialMonth);

            if (userDate) {
                setReporteDate(userDate);
                await getsebiLevel1paramaters(getSessionId, getUserId, userDate);
            } else {
                setReporteDate(getdate);
                await getsebiLevel1paramaters(getSessionId, getUserId, getdate);
            }


        };
        checkAuth();
    }, [get_date]);

    // Get Total Count

    useEffect(() => {
        let count =
            (sebiSubReport?.details &&
                sebiSubReport.details.map((ele) =>
                    Number(ele["Transaction Amount"])
                )) ||
            [];
        // This will create an array with the TotalAmt property of each object converted to a number

        let Total_Amount = count.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        setGetCount(Total_Amount);
    }, [sebiSubReport]);



    /**
     * The function `getsebiLevel1paramaters` retrieves intermediate level 1 parameters for a SEBI report
     * using the provided session key, user ID, and report date.
     * @param sessionKey - The sessionKey is a unique identifier that represents the current session or
     * user session in the application. It is used to authenticate and authorize the user's actions and
     * access to resources.
     * @param userId - The `userId` parameter is used to identify the user for whom the report is being
     * generated. It is likely a unique identifier assigned to each user in the system.
     * @param reportDate - The `reportDate` parameter is a date value that represents the date of the
     * report. It is used as an input for the `getSebiLevel1Intermidiate` function to fetch the
     * intermediate report for a specific date.
     */

    const getsebiLevel1paramaters = (sessionKey, userId, reportDate) => {
        setLoader(true)
        if (getSebiIntermidiate) {
            getSebiLevel1Intermidiate(
                "sebi intermediate rptlevel1",
                sessionKey,
                userId,
                reportDate,
                reportDate,
                "SEBI",
                "SEBI INTERMEDIARY REPORT",
                "1",
                date,
                getSebiIntermidiate?.reportDetails &&
                getSebiIntermidiate.reportDetails.SEBIMainHead,
                getSebiIntermidiate?.reportDetails &&
                getSebiIntermidiate?.reportDetails?.SEBISubHead,
                getSebiIntermidiate?.reportDetails &&
                getSebiIntermidiate?.reportDetails?.RPTName,
                getSebiIntermidiate?.colvalue && getSebiIntermidiate.colvalue
            )
                .then((res) => {
                    if (res?.data?.KCSRPT?.details[0].ErrorMsg) {
                        setLoader(false)
                        NotificationManager.error(res?.data?.KCSRPT?.details[0].ErrorMsg);
                    } else if (res?.data?.KCSRPT?.details[0].REM) {
                        setLoader(false)
                        NotificationManager.error(res?.data?.KCSRPT?.details[0].REM);
                    } else {
                        setLoader(false)
                        setSebiSubReport(res?.data?.KCSRPT);
                        let tableHeading = Object.keys(res?.data?.KCSRPT?.details[0]);
                        setHeading(tableHeading);
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    handleError(err);
                });
        }
    };

    return (
        <MainLayout>
            <div className="layoutWrapper">
                <div className="header-wrapper">
                    <h1 className="header">{Constants.sebiIntermidiateSubreport}</h1>
                    <Header setGet_date={setGet_date} get_date={get_date} />
                </div>
                <div className="content-body">
                    <div className="content-header">
                        <div className="content-header-left">
                            <h1>
                                {Constants.reportingDateTime}
                                {reportDate}
                            </h1>
                        </div>
                        <div className="content-header-right">
                            <h3 className="total-amt">
                                {Constants.totalAmt}
                                {getCount.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </h3>
                            <GernerateCSVFile
                                generateCSV={sebiSubReport}
                                reportName={"SEBILevelReport"}
                            />
                            <button className="back-btn" onClick={() => navigate(-1)}>
                                {Constants.backBtnText}
                            </button>
                        </div>
                    </div>
                    {
                        loader === false ?
                            <div className="content-table main-report-table">
                                <ResolvedApi
                                    get_response={sebiSubReport}
                                    heading={heading}
                                    navigateToReportDetails={""}
                                />
                            </div>
                            : <div className="spinner-loader">
                                <div class="spinner-border d-flex m-auto" role="status"></div>
                            </div>
                    }
                </div>

                <div className="content-footer">
                    <p>{Constants.footerText}</p>
                </div>
            </div>
        </MainLayout>
    );
};

export default IntermediateSubPage;
